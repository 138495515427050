import { useEffect, useState, useCallback } from "react";
import { auth, firestore } from "../services/firebase";
import { addDoc, getDocs, collection, query, where } from "@firebase/firestore";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { UserType } from "../models/@type.user";
const MINUTE_MS = 60000;

/**
 * Custom React hook for managing authentication state and operations.
 * This hook handles user login, registration, and logout functionalities
 * using Firebase authentication and Firestore for user data storage.
 *
 * @returns {Object} - An object containing the current user data, loading state, and logOut function.
 */
export function useAuth() {
    // Firebase authentication state hook.
    const [user, loading, error] = useAuthState(auth);
    // Firebase sign-out hook.
    const [signOut] = useSignOut(auth);
 
    // State to manage the loading state of user data.
    const [userLoading, setUserLoading] = useState<boolean>(true);
    // State to store the current authenticated user data.
    const [currentUser, setCurrentUser] = useState<UserType | null>(null);

    // State to store the authenticated user token for API use.
    const [token, setToken] = useState<string | null>(null);
 
    /**
     * useEffect hook to initialize user data when the authentication state changes.
     * If the user is authenticated, it fetches the user data or registers a new user if not found.
     * Sets the user loading state to false after initialization.
     */
    useEffect(() => {
        if (loading) return;

        const initializeUser = async () => {
            try {
                if (user) {
                    await logInOrRegister(user.uid);
                    const idToken = await user.getIdToken();
                    setToken(idToken);
                    refreshTokenInterval();
                }
            } catch (err) {
                console.error("Error initializing user:", err);
            } finally {
                setUserLoading(false);
            }
        };

        initializeUser();
    }, [loading, user]);

    /**
     * useEffect hook to catch any Firebase Authentication Error
     */
    useEffect(() => {
        if(error)
            console.error("Error on Authentication:", error);
    }, [error])

    /**
     * Function Interval to refresh the user token every minute
     */
    
    const refreshTokenInterval = () => {
        const interval = setInterval(async () => {
            if(user) {
                const idToken = await user.getIdToken();
                setToken(idToken);
            }
        }, MINUTE_MS);
        
          return () => clearInterval(interval);
    }

    /**
     * Function to log in or register a new user.
     * It first tries to fetch the user data. If not found, it registers a new user.
     *
     * @param {string} uid - The UID of the authenticated user.
     */
    const logInOrRegister = async (uid: string) => {

        try {
            let userData = await fetchUserData(uid);
            console.log(userData)
            if (!userData) {
                userData = await registerNewUser();
            }
            setCurrentUser(userData);
        } catch (err) {
            console.error("Error logging in or registering user:", err);
        }
    };

    /**
     * Function to log out the current user.
     * It clears the current user data and signs out the user from Firebase authentication.
     */
    const logOut = async () => {
        try {
            await signOut();
            setCurrentUser(null);
        } catch (err) {
            console.error("Error logging out:", err);
        }
    };


    /**
     * Function to register a new user in the Firestore database.
     * It adds the new user document to the 'users' collection and fetches the user data.
     *
     * @returns {Promise<UserType | null>} - The newly registered user data or null if registration fails.
     */
    const registerNewUser = async () => {
        if (!user) return null;

        try {
            await addDoc(collection(firestore, "users"), {
                uid: user.uid,
                name: user.displayName || "",
                authProvider: user.displayName? "google": "email",
                email: user.email,
            });

            return await fetchUserData(user.uid);
        } catch (err) {
            console.error("Error registering new user:", err);
            return null;
        }
    };

    /**
     * Function to fetch user data from the Firestore database.
     * It queries the 'users' collection for a document with the specified UID.
     *
     * @param {string} uid - The UID of the user to fetch.
     * @returns {Promise<UserType | null>} - The user data or null if not found.
     */
    const fetchUserData = async (uid: string) => {
        try {
            const userQuery = query(collection(firestore, "users"), where("uid", "==", uid));
            const userDocs = await getDocs(userQuery);

            if (userDocs.empty) return null;

            const userData = { id: userDocs.docs[0].id, ...userDocs.docs[0].data() } as UserType;
            return userData;
        } catch (err) {
            console.error("Error fetching user data:", err);
            return null;
        }
    };

    // Return the current user data, loading state, and logOut function.
    return { currentUser, userLoading, token, logOut };
}