import { useContext, useEffect, useMemo, useState } from "react";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { MenuBarComponent } from "../../components/MenuBar";
import { Box, Button, Container, Grid, Group, LoadingOverlay, Paper, Table, TableData, Text, Title } from "@mantine/core";
import { DatePickerInput } from '@mantine/dates';
import { useFetch } from '@mantine/hooks';

import styles from "./reports.module.scss";
import { IconCalendar } from "@tabler/icons-react";
import { useAnalytics } from "../../hooks/useAnalytics";
import { BarChart } from '@mantine/charts';
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { flowSchema } from "../../schemas/flowSchema";


const API_URL = import.meta.env.VITE_API_URL;

type BarChartComponentProps = {
    metrics: string[],
    dimension: string,
    title: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
}

function BarChartComponent({metrics, dimension, title, startDate, endDate}:BarChartComponentProps) {
    const { data, loading, error, refetch } = useAnalytics({ metrics, dimensions: [dimension], startDate, endDate });


    useEffect(() => {
        refetch()
      },[])


    const formattedData = useMemo(():Record<string, any>[] => {
        if(data && data.length > 0 && data[0][dimension] && typeof data[0][dimension] === "object") {
            return data.map((d) => { return {...d, [dimension]: d[dimension].value}}).sort((a,b) => a[dimension] < b[dimension]?-1:(a[dimension] > b[dimension]?1:0))
        }
        else {
            return data as Record<string, any>[];
        }
    }, [data]);

    return (
        <Box pos="relative" mt="md">
            <Paper p="md" radius="md" shadow="xs" withBorder>
            <Group justify="space-between">
                <Title size="xs" c="dimmed">
                    {title}
                </Title>
            </Group>
            {data && data.length > 0 && (<Group align="flex-end" gap="xs" mt={25}>
                <BarChart
                h={300}
                data={formattedData}
                dataKey={dimension}
                series={metrics.map((metric, index) => {return {name: metric, color:`blue.${9-index*2}`}})}
                withLegend
                />
            </Group>)}
            <LoadingOverlay visible={loading || (!error && data?.length == 0)}  />
            </Paper>
        </Box>
    )

}

type StatTableComponentProps = {
    metrics: string[],
    dimension: string,
    title: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    mutate?:{
        [key:string]: (str: string) => string
    }
}

function StatTableComponent({metrics, dimension, title, startDate, endDate, mutate}:StatTableComponentProps) {
    const { data, loading, error, refetch } = useAnalytics({ metrics, dimensions: [dimension], startDate, endDate });

    useEffect(() => {
        refetch()
      },[])

    const formattedData = useMemo(() => {
        if(data && data.length > 0 && data[0][dimension] && typeof data[0][dimension] === "object" ) {
            return {
                head: [dimension, ...metrics],
                body: data.map((d) => { return {...d, [dimension]: d[dimension].value}}).sort((a,b) => a[dimension] < b[dimension]?-1:(a[dimension] > b[dimension]?1:0)) || []
            }
        }
        else {
            return  {
                head: [dimension, ...metrics],
                body: data || []
            }
        }
    }, [data]);

    return (
        <Box pos="relative" mt="md">
            <Paper p="md" radius="md" shadow="xs" withBorder>
            <Group justify="space-between">
                <Title size="xs" c="dimmed">
                    {title}
                </Title>
            </Group>
            {data && data.length > 0 && (<Group align="flex-end" gap="xs" mt={25}>
                <Table>
                <Table.Thead>
                    <Table.Tr>
                        {formattedData.head.map((h, index) => {
                            return <Table.Th key={index}>{h}</Table.Th>
                        })}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {formattedData.body.map((element, index) => {
                        return (
                            <Table.Tr key={index}>
                                 {formattedData.head.map((h, index) => {
                                    let str = element[h];
                                    if(mutate && mutate[h]) {
                                        str = mutate[h](str);
                                    }
                                    return <Table.Td key={index}>{str}</Table.Td>
                                })}
                            </Table.Tr>
                        )
                    })}
                </Table.Tbody>
                </Table>
            </Group>)}
            <LoadingOverlay visible={loading || (!error && data?.length == 0)}  />
            </Paper>
        </Box>
    )

}
type StatComponentProps = {
    metric: string,
    dimensions?: string[],
    title: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
}


function StatComponent({metric, dimensions, title, startDate, endDate}:StatComponentProps) {
     const { data, loading, error, refetch } = useAnalytics({ metrics: [metric], dimensions, startDate, endDate });

     useEffect(() => {
        refetch()
      },[])

      return (
        <Box pos="relative" mt="md">
            <Paper p="md" radius="md" shadow="xs" withBorder>
            <Group justify="space-between">
                <Title size="xs" c="dimmed">
                    {title}
                </Title>
            </Group>

            {data && data.length > 0 && (<Group align="flex-end" gap="xs" mt={25}>
                <Text fz={38} fw="bold">{data[0][metric]}</Text>
            </Group>)}
            <LoadingOverlay visible={loading || (!error && data?.length == 0)} />
            </Paper>
        </Box>
      )
}

type StatComparisonComponentProps = {
    metrics: string[],
    dimensions?: string[],
    title: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    comparison: "percentage" | "integer" | "decimal"
}
function StatComparisonComponent({metrics, dimensions,  title, description, startDate, endDate, comparison}:StatComparisonComponentProps) {
    const { data, loading, error, refetch} = useAnalytics({ metrics, dimensions, startDate, endDate });

      const getComparison = (number1:number,number2:number):string => {
        if(number2 == 0) {
            "Cant divide by 0";
        }
        if(comparison == "percentage") {
            const result = Math.round(100*number1/number2);
            return result.toString() + "%"
        }
        else if(comparison == "integer") {
             const result = Math.round(number1/number2);
            return result.toString();
        }
        else if(comparison == "decimal") {
            const result = Math.round(number1*100/number2)/100;
            return result.toString();
        }
        return "";
      }

      useEffect(() => {
        refetch()
      },[])

      return (
        <Box pos="relative" mt="md">
            <Paper withBorder  p="md" radius="md" shadow="xs">
            <Group justify="space-between">
                <Title size="xs" c="dimmed">
                    {title}
                </Title>
            </Group>
            {data && data.length > 0 && (
            <Group align="flex-end" gap="xs" mt={25}>
                <Text fz={38} fw="bold">{getComparison(data[0][metrics[0]],data[0][metrics[1]])}</Text>
            </Group>
            )}
            <LoadingOverlay visible={loading || (!error && data?.length == 0)}  />
            </Paper>
        </Box>
      )
}

type ReportCardType = {
    type: "stat" | "stat_comparison" | "bar_chart" | "table" | "title",
    title: string,
    description?: string,
    size?: number | {base?:number, md?:number, lg?:number}
    metrics?: string | string[],
    dimensions?: string | string[],
    comparison?: "percentage" | "integer" | "decimal",
    mutate?:{
        [key:string]: (str: string) => string
    }
}

export function DashboardPage() {
    const title = "Dashboard";
    const cards:ReportCardType[] = [{
        type: "stat",
        title: "Web Sessions",
        metrics: "web_sessions",
        size: 6
    },
    {
        type: "stat",
        title: "Chat Sessions",
        metrics: "chat_active_sessions",
        size: 6
    }, {
        type: "stat",
        title: "Chat User Interactions",
        metrics: "chat_user_interactions",
        size: 6
    }, 
    {
        type: "stat_comparison",
        title: "Interactions per Active Session",
        metrics: ["chat_user_interactions", "chat_active_sessions"],
        comparison: "integer",
        size: 6
    },
    {
        type: "bar_chart",
        title: "Chat Active Sessions",
        metrics: ["chat_sessions", "chat_active_sessions"],
        dimensions: "day",
        size: 12
    }
    ]

    return <ReportGridComponent title={title} cards={cards} />
} 

export function ConversationReportPage() {
    const title = "Report Conversations";
    const cards:ReportCardType[] = [{
        type: "stat",
        title: "Sessions Chat Widget Shown",
        metrics: "chat_sessions",
        size: 4
    },
    {
        type: "stat",
        title: "Chat Sessions",
        metrics: "chat_active_sessions",
        size: 4
    }, {
        type: "stat",
        title: "Chat User Interactions",
        metrics: "chat_user_interactions",
        size: 4
    }, {
        type: "stat_comparison",
        title: "Usage %",
        metrics: ["chat_active_sessions", "chat_sessions"],
        comparison: "percentage",
        size: 4
    },
    {
        type: "stat_comparison",
        title: "Interactions per Active Session",
        metrics: ["chat_user_interactions", "chat_active_sessions"],
        comparison: "integer",
        size: 4
    },
    {
        type: "stat",
        title: "Triggered S. Conv.",
        metrics: "chat_flow_trigger_count",
        size: 4
    },
    {
        type: "stat_comparison",
        title: "S. Conv. per Active Session",
        metrics:["chat_flow_trigger_count", "chat_active_sessions"],
        comparison: "decimal",
        size: 4
    },
    {
        type: "stat",
        title: "AI Messages",
        metrics: "chat_ai_count",
        size: 4
    },
    {
        type: "stat_comparison",
        title: "AI Message per Active Session",
        metrics:["chat_ai_count", "chat_active_sessions"],
        comparison: "decimal",
        size: 4
    },
    {
        type: "stat_comparison",
        title: "AI Message per AI Active Session",
        metrics:["chat_ai_count", "chat_ai_unique_sessions"],
        comparison: "decimal",
        size: 4
    }
    ]

    return <ReportGridComponent title={title} cards={cards} />
} 

export function ScriptedConversationReportPage() {
    const title = "Report - Scripted Conversations";
    const { documents: flows, fetchDocuments } = useFirestoreCollection('flows', flowSchema);
    const {selected } = useContext(ConsoleContext) as ContextType
    const cards:ReportCardType[] = [
        {
        type: "stat",
        title: "Triggered S. Conv.",
        metrics: "chat_flow_trigger_count",
        size: 6
    },
    {
        type: "stat_comparison",
        title: "S. Conv. per Active Session",
        metrics:["chat_flow_trigger_count", "chat_active_sessions"],
        comparison: "decimal",
        size: 6
    },  {
        type: "table",
        title: "Table",
        metrics: ["chat_active_sessions", "chat_flow_trigger_count"],
        dimensions: "flow",
        size: 12,
        mutate: {
            flow: (str) => {
                const flow = flows.find((f) => f.id == str);
                if(flow)
                    return flow.name;
                else
                    return str
            }
        }
    }
    ]
    useEffect(()=> {
        fetchDocuments(1000);
    },[])
    useEffect(()=> {
        fetchDocuments(1000);
    },[selected])

    return <ReportGridComponent title={title} cards={cards} />
} 

type ReportGridComponentProps = {
    title: string,
    cards: ReportCardType[]
}

export function ReportGridComponent({title, cards}:ReportGridComponentProps) {
    const today = new Date();
    const [startDate, setStartDate] = useState<Date | null>(new Date((new Date).setMonth(today.getMonth() -1)));
    const [endDate, setEndDate] = useState<Date | null>(today);

    return (
        <>
        <MenuBarComponent title={title}>
            <Paper shadow="xs">
                <Group px={"sm"} py={2}>
                    <IconCalendar size={15}/>
                    <DatePickerInput
                        className={styles.dateInput}
                        variant="unstyled"
                        placeholder="Pick date"
                        value={startDate}
                        onChange={setStartDate}
                    />
                    <Text>-</Text>
                    <DatePickerInput
                        className={styles.dateInput}
                        variant="unstyled"
                        placeholder="Pick date"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </Group>
            </Paper>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <Grid grow>
                    {cards.map((card, index) => {
                        if(card.type == "stat") {
                            return <Grid.Col key={index} span={card.size || 12}><StatComponent key={index} title={card.title} metric={card.metrics as string} startDate={startDate || undefined} endDate={endDate || undefined}/></Grid.Col>
                        }
                        else if (card.type === "stat_comparison") {
                            return <Grid.Col key={index} span={card.size || 12}><StatComparisonComponent key={index} title={card.title} metrics={card.metrics as string[]} comparison={card.comparison || "percentage"} startDate={startDate || undefined} endDate={endDate || undefined}/></Grid.Col>
                        }
                        else if (card.type === "table") {
                            return <Grid.Col key={index} span={card.size || 12}><StatTableComponent key={index} title={card.title} metrics={card.metrics as string[]} dimension={card.dimensions as string} mutate={card.mutate} startDate={startDate || undefined} endDate={endDate || undefined}/></Grid.Col>
                        }
                        else if(card.type === "bar_chart") {
                            return <Grid.Col key={index} span={card.size || 12}><BarChartComponent key={index} title={card.title} metrics={card.metrics as string[]} dimension={card.dimensions as string} startDate={startDate || undefined} endDate={endDate || undefined}/></Grid.Col>
                        }
                    })}
                </Grid>
            </Container>
        </>
    )


}