import styles from './knowledge.module.scss';

import { useContext, useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom";
import { getOrgKnowledgeById, updateOrgKnowledge } from "../../../services/database";
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext";

import { Group, ActionIcon, Button, Container, Flex, Select } from "@mantine/core";
import { notifications } from '@mantine/notifications';
import { IconX } from "@tabler/icons-react";

import { MenuBarComponent } from "../../../components/MenuBar";
import { RichTextInput } from '../../../components/RichTextInput';
import _ from 'lodash';
import { KnowledgeType } from '../../../models/@type.knowledge';

export function KnowledgePage() {
    const [ data, setData ] = useState<KnowledgeType | null>(null)
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ changed, setChanged ] = useState<boolean>(false)
    const params = useParams()

    useEffect(() => {
        getData(params.id);
    }, []);

    async function getData(id:string) {
        const _data = await getOrgKnowledgeById(id, selected?.id);
        if(_data)
            setData(_data as KnowledgeType);
        else {
            // TODO: MANAGE ERROR
        }
    }

    async function onSave() {
        setChanged(false)
        const _res = await updateOrgKnowledge(data as KnowledgeType, selected?.id || "");
        if(_res) {
            notifications.show({
                title: 'Update Successful',
                message: `${data?.name} was updated successfully.`
              });
        }
        else {
            setChanged(true)
            notifications.show({
                color: "red",
                title: 'Error Occured',
                message: `${data?.name} was not updated.`
              })
        }
    }

    //TODO ADD LOADING
    return (
        <Flex direction={"column"} h={"calc(100vh - 2*var(--mantine-spacing-sm))"} justify={"start"} >
            <MenuBarComponent title={data?.name || ""}>
                <Group>
                    <Select checkIconPosition='right' value={data?.status || "draft"} data={[{label: "Draft", value: "draft"}, {label: "Published", value: "published"}]} onChange={(value) => {setData({...data as KnowledgeType, status: (value as "draft" | "published" | "archived") || "draft"}); setChanged(true);}} />
                    <Button disabled={!changed} onClick={() => onSave()}>Save</Button>
                    <ActionIcon component={Link} to="/knowledge"  variant={"light"} size={"lg"} aria-label="Settings"><IconX  stroke={1.5} size={20} style={{ width: '70%', height: '70%' }}></IconX></ActionIcon>
                </Group>
            </MenuBarComponent>
            <Container fluid mt={"xl"} w={"100%"} className={styles.contentInputWrapper}>
                {data && (
                <RichTextInput
                    className={styles.contentInput}
                    id={data?.id}
                    value={data?.content} 
                    onChange={(value) => {
                        const _data = _.cloneDeep(data);
                        _data.content = value;
                        setChanged(true);
                        setData(_data)}
                    }/>
                )}
            </Container>
        </Flex>
    )

}