import { storage } from './firebase'
import { ref, uploadBytes, getDownloadURL  } from "firebase/storage";


export async function uploadFile(clientId:string, namespace:string, file:File) {
    try {
        const _name = file.name;
        const _ref = ref(storage, `clients/${clientId}/${namespace}/${_name}`);
        const _upload = await uploadBytes(_ref, file);
        const _result = await getDownloadURL(_upload.ref)
        return _result;
    }
    catch (err) {
        return ""
    }
}