import { useContext, useEffect, useState } from "react";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { MenuBarComponent } from "../../components/MenuBar";
import { Anchor, Badge, Button, Container, Flex, Group, Loader, Paper, ScrollArea, Table, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";

import styles from "./reports.module.scss";
import { getOrgForms } from "../../services/database";
import { FlowType } from "../../models/@type.flow";
import { Link } from "react-router-dom";

export function FormsReportPage() {
    const {selected} = useContext(ConsoleContext) as ContextType;

    const [data, setData] = useState<FlowType[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        getData()
    },[])

    async function getData() {
        const _data = await getOrgForms(selected?.id || "");
        setData(_data);
        setLoaded(true);
    }

    return (
        <>
            <MenuBarComponent title={"All Forms"}>
                <Group>
                    
                </Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
            <Group py={"lg"} className={styles.filterContainer}></Group>
                <ScrollArea>
                    <Paper className={styles.tableWrapper}>
                        <Table classNames={{table: styles.table}}>
                            <Table.Thead bg={"gray.0"}>
                                <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Form Name</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}># Requests</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Last Submission</Text></Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            {loaded && (
                                <Table.Tbody>
                                    {data.map((val, index) => {
                                        return (
                                            <Table.Tr key={index}>
                                                <Table.Td ><Flex mih={40} align={"center"}><Anchor component={Link} to={`${val.id}`} size={"sm"} fw={"bold"} pl={"sm"}>{val.name}</Anchor></Flex></Table.Td>
                                                <Table.Td ><Badge>Conversational Form</Badge></Table.Td>
                                                <Table.Td mih={40} ><Text size={"sm"} c="dimmed"></Text></Table.Td>
                                                <Table.Td mih={40} ><Text size={"sm"} c="dimmed">{(new Date(Date.now())).toLocaleDateString("en-UK")}</Text></Table.Td>
                                            </Table.Tr>
                                        )
                                    })}
                                    <Table.Tr>
                                                <Table.Td ><Flex mih={40} align={"center"}><Anchor component={Link} to={`ambassadors`} size={"sm"} fw={"bold"} pl={"sm"}>Ambassadors</Anchor></Flex></Table.Td>
                                                <Table.Td ><Badge>Widget Form</Badge></Table.Td>
                                                <Table.Td mih={40} ><Text size={"sm"} c="dimmed"></Text></Table.Td>
                                                <Table.Td mih={40} ><Text size={"sm"} c="dimmed">{(new Date(Date.now())).toLocaleDateString("en-UK")}</Text></Table.Td>
                                            </Table.Tr>
                                </Table.Tbody>
                            )}
                        </Table>
                        {!loaded && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                    </Paper>
                </ScrollArea>
            </Container>
        </>
    )
}