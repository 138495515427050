
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useFirestoreCollection } from '../../hooks/useFirestoreCollection';
import { LeadSchema } from '../../models/Lead';
import { Button, Container, Divider, Group, Stack, Table, Title } from '@mantine/core';
import { FieldSchema } from '../../models/Fields';

type LeadPageProps = {
    leadId?:string,
    onClose():void
}

export function LeadPage({leadId, onClose}:LeadPageProps) {
    const id = leadId || useParams().clientId;
    const { singleDoc, singleDocLoading, fetchDocument } = useFirestoreCollection('leads', LeadSchema);
    const { documents: fields, loading, fetchDocuments } = useFirestoreCollection('attributes', FieldSchema);

    useEffect(() => {
        if(!id)
            return;
        fetchDocuments(1000);
        fetchDocument(id)
    }, [])

    return (
        <>
        {!singleDocLoading && !loading && 
        <Container pos={"relative"}>
            <Group pos={"sticky"} py={"md"} w={"100%"} justify={"space-between"}>
                <Title size={"h3"} order={1}>{singleDoc?.firstName} {singleDoc?.lastName} {!singleDoc?.firstName && !singleDoc?.lastName && singleDoc?.email}</Title>
                <Button size={"sm"} variant="light" onClick={onClose}>Close</Button>
            </Group>
            <Divider/>
            <Stack mt={"md"}>
                <Title size={"h4"} order={2}>
                    Propeties
                </Title>
                <Table withTableBorder withColumnBorders>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Value</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                    <Table.Tbody>
                        {fields.sort((a,_) =>  {
                            if(["email", "firstName", "lastName", "phoneNumber"].includes(a.id || ""))
                                return -1
                            else return 0;
                        }).map((f) => {
                            return (
                            <Table.Tr key={f.id}>
                                <Table.Td>{f.label}</Table.Td>
                                <Table.Td>{singleDoc? singleDoc[f.id || ""]: "N/A"}</Table.Td>
                            </Table.Tr>)
                        })}
                    </Table.Tbody>
                </Table>
            </Stack>
        </Container>
        }
        </>
    )
}