import crypto from 'crypto';

const INTEGRATIONS = [
    {
        name: "Microsoft Dynamics",
        category: "CRM",
        integrationId: "ms_dynamics",
        image: "",
        description: "",
        type: "leadAdd",
        params: [
            {
                name: "clientId",
                label: "CLIENT ID",
                type: "string"
            },
            {
                name: "directoryId",
                label: "DIRECTORY ID",
                type: "string"
            },
            {
                name: "secretId",
                label: "SECRET ID",
                type: "string"
            },
            {
                name: "tenantId",
                label: "TENANT ID",
                type: "string"
            },
            {
                name: "fieldMapping",
                label: "FIELDS",
                type: "field_mapping"
            }
        ]
    },
    {
        name: "Microsoft Dynamics",
        category: "CRM",
        integrationId: "ms_dynamics",
        image: "",
        description: "",
        type: "leadUpdate",
        params: [
            {
                name: "clientId",
                label: "CLIENT ID",
                type: "string"
            },
            {
                name: "directoryId",
                label: "DIRECTORY ID",
                type: "string"
            },
            {
                name: "secretId",
                label: "SECRET ID",
                type: "string"
            },
            {
                name: "tenantId",
                label: "TENANT ID",
                type: "string"
            },
            {
                name: "fieldMapping",
                label: "FIELDS",
                type: "field_mapping"
            }
        ]
    },
    {
        name: "HUBSPOT VIA FORM",
        category: "CRM",
        integrationId: "hubspot_form",
        image: "",
        description: "",
        type: "leadAdd",
        params: [
            {
                name: "portalId",
                label: "PORTAL ID",
                type: "string"
            },
            {
                name: "formId",
                label: "FORM ID",
                type: "string"
            },
            {
                name: "fieldMapping",
                label: "FIELDS",
                type: "field_mapping"
            }
        ]
    },
    {
        name: "WEBHOOK API",
        category: "GENERAL",
        integrationId: "API",
        image: "",
        description: "",
        type: "leadAdd",
        params: [
            {
                name: "key",
                label: "KEY",
                type: "generated-string",
                status: "only-read"
            }
        ],
        onCreate: () => {
            return {
                key: crypto.randomBytes(length).toString('hex')
            }
        }
    }
]

export default INTEGRATIONS;
