import { Flex, Loader } from "@mantine/core";

export function Loading() {

    return(
        <Flex
            mih={"100vh"}
            miw={"100%"}
            justify={"center"}
            align={"center"}
            bg={"gray.1"}
        >
             <Loader color="blue.5" type="dots" />
        </Flex>
    )
}