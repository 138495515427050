import { useContext, useEffect, useMemo, useState } from "react"
import { Notification, Flex, Text, Group, Title, ActionIcon, Select, Button, Box, ScrollArea, Paper, Stack, TextInput, ComboboxItem, OptionsFilter, Fieldset, Combobox, Transition } from "@mantine/core"
import { IconTrash, IconX } from "@tabler/icons-react";
import { CampusType } from "../../models/@type.data"
import _ from "lodash";
import { ImageFieldComponent } from "../../components/ImageField";



import styles from './data.module.scss';
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { useGeoLocation } from "../../hooks/useGeoLocation";
import { useEdges } from "reactflow";

type CampusComponentProps = {
    campus: CampusType,
    onSave(campus:CampusType):void,
    onDelete(campus:CampusType):void,
    onClose():void
}

type CityType = {
    label:string, 
    value:string, 
    stateCode:string, 
    countryCode:string, 
    name:string
}

const ERROR_MESSAGES = {
    NAME: "The campus name cannot be empty.",
    LOCATION: "The campus is missing a location."
}

export function CampusComponent({campus, onSave, onDelete, onClose}:CampusComponentProps) {
    const { getAllCountries, getCitiesOfCountry, getStateByCodeAndCountry, loading } = useGeoLocation();
    const COUNTRIES = getAllCountries();
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ data, setData ] = useState<CampusType>(_.cloneDeep(campus));
    const [ locationLoading, setLocationLoading ] = useState<boolean>(true);
    const [cities, setCities] = useState<CityType[]>([])
    
    const [ change, setChange ] = useState<boolean>(false);
    const [ deletePrompt, setDeletePrompt ] = useState<boolean>(false);

    const [ error, setError ] = useState<string | null>(null);

    useEffect(() => {
        if(!loading)
            init()
    },[loading])

    useEffect(() => {
        init();
    }, [data])

    function onCampusSave() {
        if (!data.name) {
            setError(ERROR_MESSAGES.NAME)
            return;
        }
        else if(!data.location.city) {
            setError(ERROR_MESSAGES.LOCATION)
            return;
        }
        setError(null)
        onSave(data);
    }

    async function init() {
        const _cities = await getCities(data.location.countryCode);
        setLocationLoading(false)
        setCities(_cities)
    }

    async function getCities(countryCode:string):Promise<CityType[]> {  

        const _cities = await Promise.all((await getCitiesOfCountry(countryCode) || []).map(async (value) => {
            const states = await getStateByCodeAndCountry(value.stateCode, countryCode)
            return {
                stateCode: value.stateCode, 
                countryCode: value.countryCode, 
                name: value.name, 
                label: `${value.name}, ${states?.name}`, 
                value: `${value.countryCode}_${value.stateCode}_${value.name.toLocaleLowerCase().replace(/ /g, "_")}`} as CityType}))
        return _cities
    }

    function onCountryChange(value:string|null, option:ComboboxItem) {
        setLocationLoading(true);
        if(value) {
            console.log(value);
            const _location = {
                country: option.label,
                countryCode: value,
                stateCode:  "",
                state:  "",
                city: ""
            }

            setData({name: data.name, location: _location});
            setChange(true);
        }

    }

    async function onCityChange(value:string|null, option:ComboboxItem) {
        if(value) {

            const _location = {
                country: data.location.country,
                countryCode: data.location.countryCode,
                stateCode: (option as CityType).stateCode,
                state: (await getStateByCodeAndCountry((option as CityType).stateCode, data.location.countryCode))?.name || "",
                city: (option as CityType).name
            }

            setData({name: data.name, location: _location});
            setChange(true);
        }

    }

    return (
        <Flex key={campus?.id || "new_campus"} px={"lg"} py={0} direction={"column"} w={"100%"} className={styles.dataWrapper}>
            {deletePrompt && (
                <Stack style={{zIndex: 11}} bg={"white"} align="center" justify="center" pos="absolute" top={0} w={"100%"} bottom={0} left={0}>
                    <Text fw={"bold"}>Are you sure?</Text>
                    <Button onClick={() => {onDelete(campus); setDeletePrompt(false);}} w={150} color={"red"} leftSection={<IconTrash size={18}/>}>Yes Delete</Button>
                    <Button onClick={() => {setDeletePrompt(false);}} w={150} color={"gray"} variant="light" leftSection={<IconX size={18}/>}>Cancel</Button>
                </Stack>
            )}
            <Group pb={"sm"} justify={"space-between"} className={styles.dataHeader}>
                <Title maw={250} lineClamp={1} order={4} c={"gray.8"}>{data.name}</Title>
                <Group> 
                    {data.id && <ActionIcon onClick={() => {setDeletePrompt(true)}} variant="transparent" c={"red"}><IconTrash size={18}/></ActionIcon>}
                    <Button disabled={!change} size="sm" variant={"filled"} onClick={onCampusSave}>Save</Button>
                    <Button size={"sm"} variant="light" onClick={onClose}>Close{change?" without Saving":""}</Button>
                </Group>
            </Group>
            <Box style={{ flexGrow: 1, overflow: 'auto' }}>
                <ScrollArea  pt={"md"}>
                    <Transition
                            mounted={error?true:false}
                            transition="scale"
                            duration={200}
                            timingFunction="ease">
                            {(styles) => 
                                <Notification mb={"lg"} withBorder style={styles} icon={<IconX size={20}/>} color="red" title={"Error"} onClose={() => { setError(null)}}>
                                    {error}
                                </Notification>
                            }
                    </Transition>
                    <Paper bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                        <Title mb={"xs"} c={"gray.8"} order={5}>Basic Information</Title>
                        <Stack > 
                            <TextInput
                                classNames={{
                                    label: styles.dataInputLabel,
                                    input: styles.input
                                }}
                                label="Name*"
                                size="md"
                                placeholder="Campus Name"
                                value={data.name}
                                onChange={(e) => {setData({...data, name: e.target.value}); setChange(true)}}
                                />
                            <div>
                                <label className={styles.dataInputLabel}>Cover Image</label>
                                <ImageFieldComponent 
                                    id={`${campus?.id}-overview`}
                                    value={data.cover || ""}
                                    onChange={(value:string | undefined) => {setData({...data, cover:value})}}
                                    namespace="campus"
                                    clientId={selected?.id || ""}
                                    placeholder="Cover Image"
                                    />
                            </div>
                                <Fieldset legend={"Location*"} className={styles.dataFieldSet}>
                                    {locationLoading? (<></>):(
                                    <Stack>
                                        <Select 
                                            classNames={{
                                                label: styles.dataInputLabel,
                                                input: styles.input
                                            }}
                                            label="Country"
                                            size="md"
                                            value={data.location.countryCode}
                                            withCheckIcon={true}
                                            checkIconPosition="right"
                                            data={COUNTRIES.map((value)=> {return {label: value.name, value: value.isoCode}})}
                                            searchable
                                            onChange={onCountryChange}
                                        />
                                        <Select 
                                            classNames={{
                                                label: styles.dataInputLabel,
                                                input: styles.input
                                            }}
                                            disabled={!data.location.countryCode}
                                            label="City"
                                            size="md"
                                            placeholder="Type to search a city of the selected country.."
                                            value={data.location.city? `${data.location.countryCode}_${data.location.stateCode}_${data.location.city.toLocaleLowerCase().replace(/ /g, "_")}`:null}
                                            withCheckIcon={true}
                                            checkIconPosition="right"
                                            data={cities}
                                            limit={35}
                                            searchable
                                            onChange={onCityChange}
                                        />
                                    </Stack>
                                    )}
                            </Fieldset>             
                        </Stack>
                    </Paper>
                </ScrollArea>
            </Box>
        </Flex>
    )
}