import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconChevronRight, IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";

export type StartTriggerActionType = {
    flow: {
        id: string | null,
        label: string
    },
}


export const StartFlowActionSchema = [
    {
        target: "flow",
        label: "Flow to Trigger",
        type: "select",
        optionType: "flow",
    }
]

export const StartFlowActionDefault = {
    flow: {
        id: null,
        label: "No Flow Selected"
    }
}

type ActionNodeProps = {
    id: string,
    action: {[key:string]: string | boolean | number | Object | null | [] } | Object,
    isConnectable: boolean,
    edges: any
}

export function StartFlowActionNode({id, action, isConnectable, edges}: ActionNodeProps) {
    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Start Flow</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
                <Paper  shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                    <Group justify={"space-between"} wrap="nowrap">
                        <Text>{(action as StartTriggerActionType).flow.label}</Text>
                        <IconChevronRight size={20}/>
                    </Group>
                </Paper>
            </Stack>

        </>
    )

}

