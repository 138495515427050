import styles from './dynamic.table.module.css'

import { Button, Group, Loader, Paper, Table, Text } from "@mantine/core"
import { PaginatedData } from "../services/database"
import { useEffect, useState } from 'react'



type DynamicTableComponentProps = {
    dataFn(position?:number, directionAfter?:boolean, limit?:number, filters?:any): Promise<PaginatedData>,
    headers: {label: string, value: string}[],
    search: string[],
    filters: string[]
}

const LIMIT = 25;
export function DynamicTableComponent({dataFn, headers, search, filters}:DynamicTableComponentProps) {

    const [ estimatedPage, setEstimatedPage ] = useState<number>(1);
    
    const [ paginatedData, setPaginatedData ] = useState<PaginatedData | null>(null);
    const [ loading, setLoading ] = useState<boolean>(true);

    useEffect(() =>{
        setEstimatedPage(1);
        getData();
        
    }, [])

   function nextPage() {
        if(Math.ceil((paginatedData?.totalCount || 0 )/LIMIT)  == estimatedPage)
            return;
        
        getData(paginatedData?.data[(paginatedData?.data.length || 0)-1]?.createdAt || 100000000000, true )
        setEstimatedPage((prev) => prev +1);
   }
   function previousPage() {
        if(1 == estimatedPage)
            return;
        
        getData(paginatedData?.data[0]?.createdAt || 0, false )
        setEstimatedPage((prev) => prev -1);
    }

    async function getData(position=0, directionAfter=true, limit=LIMIT, filters?:any) {
        setLoading(true);
        if(!position) {
            if(directionAfter) {
                position = 0;
            }
            else 
                position = 1000000000000000;
        }
        const _data = await dataFn(position, directionAfter, limit, filters)
        setPaginatedData(_data)
        setLoading(false);
    }

    return (
        <>
            <Paper className={styles.tableWrapper}>
                <Table classNames={{table: styles.table}}>
                    <Table.Thead bg={"gray.0"}>
                        <Table.Tr>
                            {headers.map((header) => {
                                return (
                                    <Table.Th key={header.value} pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>{header.label}</Text></Table.Th>
                                )
                            })}
                        </Table.Tr>
                    </Table.Thead>
                    {!loading && (
                        <Table.Tbody>
                            {paginatedData?.data.map((val, index) => {
                                return (
                                    <Table.Tr key={val.id || index}>
                                        {headers.map((header) => {
                                            return (
                                                <Table.Td key={`${val.id || index}-${header.value}`} mih={40} ><Text size={"sm"} c="gray.8">{header.value == "createdAt"? (new Date(val[header.value])).toLocaleString():val[header.value]}</Text></Table.Td>
                                            )
                                        })}
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    )}
                </Table>
                {loading && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
            </Paper>
            
            {!loading && (
                <>
                <Group w={"100%"} justify='end'>
                    <Text>{paginatedData?.totalCount} total leads</Text>
                </Group>
                <Group gap={7} mt="xl">
                <Button onClick={
                    previousPage}
                    disabled={(1 == estimatedPage)}
                    >Previous</Button>
                    
                <Button
                    onClick={nextPage}
                    disabled={(Math.ceil((paginatedData?.totalCount || 0 )/LIMIT) == estimatedPage) || paginatedData?.data.length == 0}
                >Next</Button>
                </Group>
            </>
    
            )}
        </>
    )
}