// schemas/integrationSchema.ts
import { z } from 'zod';

export const integrationSchema = z.object({
    id: z.string().optional(),
    name: z.string().min(3, 'Name must be at least 3 characters long'),
    status: z.enum(['active', 'inactive']).default('inactive').optional(),
    type: z.enum(['leadAdd', 'leadUpdate', 'leadUpsert', 'formSubmit']).default('leadAdd').optional(),
    params: z.record(z.string(), z.any())
});

// TypeScript type derived from the Zod schema
export type IntegrationType = z.infer<typeof integrationSchema>;