import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text,  Paper, Avatar } from '@mantine/core';
import { IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";

export type BrochureActionType = {
    fallbackBrochure: string
}

export const BrochureActionSchema = [
    {
        target: "fallbackBrochure",
        label: "Fallback Brochure",
        type: "text",
    }
]

export const BrochureActionDefault:BrochureActionType = {
    fallbackBrochure: ""
}



export function BrochureActionNode({id, action, isConnectable, edges}) {

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Brochure Link</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
                <p>Show Brochure Link</p>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}

