import styles from "./actions.module.scss"
import { Button, Fieldset, ActionIcon, Group, TextInput, SegmentedControl, Select, Switch, MultiSelect } from "@mantine/core";
import { RichTextInput } from "../../../../components/RichTextInput";
import _, { flow } from "lodash";
import { IconTrash } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid"
import { ImageFieldComponent } from "../../../../components/ImageField";
import { getOrgCRMAttributes, getOrgFlows, getOrgPrograms } from "../../../../services/database";
import { useEffect, useState } from "react";
import { FlowType } from "../../../../models/@type.flow";
import { CRMAttributeType } from "../../../../models/@type.client";
import { ProgramType } from "../../../../models/@type.data";
import { formSchema } from "../../../../schemas/formSchema";
import { useFirestoreCollection } from "../../../../hooks/useFirestoreCollection";



export function EditAction({id, data, schema, onChange, onDelete, clientId = ""}) {
    const [flows, setFlows] = useState<FlowType[]>([])

    const [attributes, setAttributes] = useState<CRMAttributeType[]>([])

    const [ programs, setPrograms ] = useState<ProgramType[]>([])

    const { documents: forms, fetchDocuments: fetchForms } = useFirestoreCollection('forms', formSchema);

    useEffect(() => {
        loadFlows()
        loadAttributes()
        loadPrograms()
        fetchForms(200);
    }, [])

    async function loadFlows() {
        const _opts = await getOrgFlows(clientId || "");
        setFlows(_opts.sort((a,b) =>  {return (a.name < b.name)?-1:1} ));
    }

    async function loadAttributes() {
        const _attrs = await getOrgCRMAttributes(clientId || "");
        setAttributes(_attrs);
    }

    async function loadPrograms() {
        const _proms = await getOrgPrograms(clientId || "");
        setPrograms(_proms)
    }

    function onFieldChange(target:string, value:any) {
        const _action = _.cloneDeep(data.action);
        _action[target] = value;
        onChange(id, _action)
    }

    function inputRender(index: string, inputSchema:object, value:any, onChange:(target:string, value:any)=>void) {

        if(inputSchema.type == "multiple") {
            return (
                <div key={index} className={`${styles.inputField}`}>
                    <label className={`${styles.label}`}>{inputSchema.label}</label>
                    {value.map((multiple_value, i) => {      
                        return (
                            <div key={`${index}-${i}`} className={`${styles.inputField} ${styles.inputFieldMultiple}`}>
                                <Group justify={"space-between"} className={`${styles.multipleHead}`}>
                                    <label className={`${styles.label}`}>{inputSchema.multiple_label} {i+1}</label>
                                    <ActionIcon 
                                        color={"red.5"}
                                        onClick={() => {
                                            const _multiple = _.cloneDeep(value);
                                            _multiple.splice(i, 1);
                                            onChange(inputSchema.target, _multiple)
                                        }
                                        }>
                                        <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5}/>
                                        
                                    </ActionIcon>
                                </Group>
                                {inputSchema.schema.map((multiple_schema_field, e) => {
                                    return inputRender(`${index}-${i}-${e}`, multiple_schema_field, multiple_value[multiple_schema_field.target], 
                                    (_target, _value) => {
                                        const _multiple = _.cloneDeep(value);
                                        _multiple[i][_target] = _value;
                                        onChange(inputSchema.target, _multiple)
                                    }
                                    )
                                })}
                            </div>
                        )
                    })}
                    <Button 
                        mt={"md"} 
                        fullWidth 
                        onClick={() => {
                            const _multiple = _.cloneDeep(value);
                            _multiple.push({...inputSchema.default, id: uuidv4()})
                            onChange(inputSchema.target, _multiple)
                        }}
                    >
                        {inputSchema.add}
                    </Button>
                </div>
            )
        }
        else if(inputSchema.type == "rich_text") {
           return (
                <div key={index} className={`${styles.inputField}`}>
                    <label className={`${styles.label}`}>{inputSchema.label}</label>
                    <RichTextInput 
                        key={`rti-${index}`} 
                        id={`rti-${index}`} 
                        value={value || ""} 
                        onChange={(val) => {
                            onChange( inputSchema.target, val);
                            }
                        }/>
                </div>
           ) 
        }
        else if(inputSchema.type == "text") {        
            return (
                <TextInput  mb={"sm"} key={index}
                label={inputSchema.label}
                description={inputSchema.description}
                value={value}
                placeholder={inputSchema.placeholder}
                onChange={(e) => {onChange(inputSchema.target, e.target.value)}}
                />
            )

        }
        else if(inputSchema.type == "image") {
            return (
                <ImageFieldComponent
                    key={index}
                    value={value}
                    label={inputSchema.label}
                    clientId={clientId}
                    namespace={"flows"}
                    placeholder={"Upload Image"}
                    id={index}
                    onChange={(src) => {onChange(inputSchema.target, src)}}
                    />

            )
        }
        else if(inputSchema.type == "segment") {
        
            
                return (
                    <div key={index} className={styles.inputField}>
                    <label className={styles.label}>{inputSchema.label}</label>
                    <SegmentedControl mb={"sm"}
                    value={value}
                    onChange={(newValue) => {onChange(inputSchema.target, newValue)}}
                    data={inputSchema.options}
                    />
                    </div>
                )
            
        }
        else if(inputSchema.type == "switch") {
            return (
                <div key={index} className={styles.inputField}>
                    <label className={styles.label}>{inputSchema.label}</label>
                <Switch
                checked={value}
                onChange={(event) => onChange(inputSchema.target, event.currentTarget.checked)}
                />
                </div>
            )
        }
        else if(inputSchema.type == "select") {
            
            return (
                <Select
                    key={index}
                    className={styles.inputField}
                    label={inputSchema.label}
                    placeholder="Pick value"
                    value={value?.id || null}
                    data={flows.map((val) => {return {value: val.id as string, label: val.name}})}
                    onChange={(_, option) => {
                        onChange(inputSchema.target, {id: option.value, label: option.label})
                    }}
                />
            )
        }
        else if(inputSchema.type == "multiple_select") {
            if(inputSchema.optionType == "program")
            return (
                <MultiSelect
                    key={index}
                    className={styles.inputField}
                    label={inputSchema.label}
                    placeholder="Pick value"
                    value={value}
                    searchable
                    data={programs.map((val) => {return {value: val.id as string, label: val.name}})}
                    onChange={(values) => {
                        onChange(inputSchema.target, values)
                    }}
                />
            )
        }
        else if(inputSchema.type == "data_select") {
            let _data:{value:string, label: string}[] = []
            if(inputSchema.option == "attributes")
                _data = attributes.map((val) => {return {value: val.id as string, label: val.label}})
            else if(inputSchema.option == "forms")
                _data = forms.map((val) => {return {value: val.id as string, label: val.name}})
            
            return (
                <Select
                    key={index}
                    className={styles.inputField}
                    label={inputSchema.label}
                    placeholder="Pick value"
                    value={value?.id || null} 
                    data={_data}
                    searchable 
                    clearable
                    onChange={(_, option) => {
                        onChange(inputSchema.target, option?{id: option.value, label: option.label}:null)
                    }}
                />
            )
        }
    }    

    return (
        <Fieldset key={id} p={"lg"}>
            {schema.map((field, index) => {
                return inputRender(index, field, data.action[field.target], onFieldChange)
            })}
            
             { data.type != "trigger" && (<Button onClick={() => onDelete(id)} fullWidth mt={"xl"} variant={"outline"} color={"red.5"} rightSection={<IconTrash size={14}/>}>Delete Action</Button>)}
        </Fieldset>
    )
}