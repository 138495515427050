import { Box, Flex, Paper, Group, ScrollArea, Select, Stack, TextInput, Title, Button } from "@mantine/core"
import { CRMAttributeType } from "../../../models/@type.client"
import common from '../../../common.module.scss'
import { useState } from "react"
import _ from "lodash"

export type CRMAttributeEditProps = {
    attribute: CRMAttributeType,
    onSave(attribute: CRMAttributeType):void,
    onClose():void
}

type CRMAttributeField = "label" | "locked" | "type" | "target";
type CRMAttributeValue = string | boolean ;

export function CRMAttributeEdit({attribute, onSave, onClose}:CRMAttributeEditProps) {
    const [data, setData] = useState<CRMAttributeType>(attribute);

    function onValueChange(field:CRMAttributeField, value: CRMAttributeValue) {
        const _data = _.cloneDeep(data);
        _data[field] = value as never;
        setData(_data)
    }

    function onAttributeSave() {

        //change error handling to use zod
        if(!data.label)
            return;

        onSave(data);
    }


    return (
        <Flex key={attribute?.id || "new_attribute"} px={"lg"} py={0} direction={"column"} w={"100%"} className={common.dataWrapper}>
            <Group justify="space-between">
                <Title maw={220} lineClamp={1} order={4} c={"gray.8"}>{data.label}</Title>
                <Group>
                    <Button onClick={onAttributeSave}>Save</Button>
                    <Button color="gray.1" onClick={onClose}>Close</Button>
                </Group>
            </Group>
            <Box style={{ flexGrow: 1, overflow: 'auto' }}>
                <ScrollArea  pt={"md"}>
                    <Paper bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                        <Title mb={"xs"} c={"gray.8"} order={5}>Basic Information</Title>
                        <Stack>
                            <TextInput
                                classNames={{
                                    label: common.dataInputLabel,
                                    input: common.input
                                }}
                                label="Name"
                                size="md"
                                placeholder="Attribute Name"
                                value={data.label}
                                onChange={(e) => {onValueChange("label", e.target.value)}}
                                />
                            <Select
                                classNames={{
                                    label: common.dataInputLabel,
                                    input: common.input
                                }}
                        
                                size="md"
                                label="Target"
                                value={data.target}
                                data={["lead", "other"]}
                                withCheckIcon={false}
                                onChange={(value) => {onValueChange("target", value as string)}}
                                />

                            <Select
                                classNames={{
                                    label: common.dataInputLabel,
                                    input: common.input
                                }}
                        
                                size="md"
                                label="Type"
                                value={data.type}
                                data={["text", "boolean", "phone", "email", "long_text", "rich_text", "date", "number", "select", "multiselect"]}
                                withCheckIcon={false}
                                onChange={(value) => {onValueChange("type", value as string)}}
                                />
                        </Stack>
                    </Paper>
                </ScrollArea>
            </Box>
        </Flex>
    )
}