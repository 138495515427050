import { useEffect, useState } from "react";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { formSchema } from "../../../schemas/formSchema";
import { Badge, Button, Container, Drawer, Flex, Group, Loader, Modal, Paper, Stack, Table, Text, TextInput } from "@mantine/core";
import { MenuBarComponent } from "../../../components/MenuBar";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

type FormAddComponentProps = {
    addDoc(name: string): void
}

const FormAddComponent = ({addDoc}:FormAddComponentProps) => {

    const [ name, setName ] = useState<string>("");
    
    const handleAddForm = async () => {
        if (!name.trim()) return; // Prevent adding form with empty name
        addDoc(name);
    };
    return (
        <Stack gap={"lg"}>
             <TextInput
                label="Form Name"
                description="A recognizable name for your form."
                placeholder="Recognizable Name"
                value={name}
                onChange={(evt) => {setName(evt.target.value)}}
            />
            <Button onClick={handleAddForm}>Add Form</Button>
        </Stack>
    )
}

export const FormIndexPage = () => {

    const { documents, loading, fetchDocuments, addDoc, nextPage, prevPage } = useFirestoreCollection('forms', formSchema);
    const [ opened, { open, close }] = useDisclosure(false);
    const [ selection, setSelection ] = useState<string | null>(null)

    useEffect(() => {
        fetchDocuments();
    }, [])

    async function handleAddDocument(name:string) {
        const result = await addDoc({name: name});
        if(result) {
            close();
        }
        else {
            // Manage Error
        }
    }

    return (
        <>
            <Drawer opened={selection?true:false} onClose={() => setSelection(null)}>

            </Drawer>
            <Modal opened={opened} onClose={close}>
                <FormAddComponent addDoc={handleAddDocument}/>
            </Modal>
            <MenuBarComponent title={"Forms"}>
                <Group>
                    <Button onClick={open} variant="light" leftSection={<IconPlus size={14}/>}>Add Form</Button>
                </Group>
            </MenuBarComponent>
            <Container size={"lg"} p={"md"} px={"xl"}>
                <Paper withBorder>
                    <Table>
                        <Table.Thead bg={"gray.0"}>
                            <Table.Tr >
                                <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Name</Text></Table.Th>
                                <Table.Th><Text fw={"bold"} size={"xs"}>Status</Text></Table.Th>
                                <Table.Th><Text fw={"bold"} size={"xs"}>Responses</Text></Table.Th>
                                <Table.Th></Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        {!loading && (
                            <Table.Tbody>
                                {documents.map((element) => {
                                    return (
                                        <Table.Tr key={element.id} >
                                            <Table.Td><Flex mih={40} align={"center"}><Text size="sm" fw={"bold"}>{element.name}</Text></Flex></Table.Td>
                                            <Table.Td ><Badge variant={"dot"} color={`${element.status == "archived"? "gray":"green"}`}>{element.status}</Badge></Table.Td>
                                            <Table.Td> <Text size={"sm"} c={"dimmed"}>0</Text> </Table.Td>
                                            <Table.Td></Table.Td>
                                        </Table.Tr>
                                    ) 
                                })}
                            </Table.Tbody>
                        )}
                    </Table>
                    {loading && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                    {(!loading && documents.length == 0) && (
                        <Group w={"100%"} justify="center" py={"sm"}>
                            <Text c={"gray.9"} size="sm" fw="bold">You have 0 forms created.</Text>
                        </Group>
                    )}
                </Paper>
            </Container>
        </>
    )

}