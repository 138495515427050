import { ActionIcon, Anchor, Badge, Box, Button, Container, Group, Paper, SegmentedControl, Tabs, Text, TextInput, Tooltip, Notification, Drawer, rem  } from "@mantine/core";
import PageHeader from "../../../components/PageHeader";

import pageStyles from '../../page.module.scss'
import { useEffect, useMemo, useState } from "react";
import { IconSearch, IconTable, IconTableImport, IconX, IconCheck, IconPlus, IconDownload, IconArticle } from "@tabler/icons-react";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { programSchema, ProgramModelType, StudyOptionModelType, subjectSchema } from "../../../models/Program";
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useDebouncedValue } from "@mantine/hooks";
import { SubjectIndexComponent } from "../subjects/IndexComponent";
import { CampusIndexComponent } from "../campuses/IndexComponent";
import { z } from "zod";
import { CampusModelType, campusSchema } from "../../../models/Campus";
import { CSVImporter } from "csv-import-react";
import ProgramImportColumns from './ImportProgramConfig.json'
import { ProgramComponent } from "../../../../pages/data/Program";

import { notifications } from '@mantine/notifications';


const DEGREE_BADGE_COLOR:{ [key: string]: string } = {
    "bachelor": "green",
    "master": "blue",
    "phd": "violet",
    "diploma": "orange"
}

const STATUS_BADGE_COLOR:{[key: string]: string} = {
    "published": "green",
    "draft": "gray",
    "archived": "orange"
}

const PAGE_SIZES = [10, 25, 50];

export function ProgramIndexPage() {
    const [activeTab, setActiveTab] = useState<string | null>("programs");
    const { documents, loading, fetchDocuments,  addDoc, updateDoc, deleteDoc } = useFirestoreCollection('programs', programSchema);
    const [ selectedDocuments, setSelectedDocuments] = useState<ProgramModelType[]>([]);
    const [ paginatedDocuments, setPaginatedDocuments] = useState<ProgramModelType[]>([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [selectedRows, setSelectedRows] = useState<ProgramModelType[]>([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0])

    const { documents:campuses, fetchDocuments:fetchCampuses } = useFirestoreCollection('campuses', campusSchema);
    const { documents:subjects, fetchDocuments:fetchSubjects } = useFirestoreCollection('subjects', subjectSchema);

    const [ programEdit, setProgramEdit ] = useState<ProgramModelType | null>(null);

    const [ importOpen, setImportOpen ] = useState<boolean>(false);
    const [ importingNotification, setImportingNotification] = useState<boolean>(false)
    const [ importingStatus, setImportingStatus] = useState<string>("init");
    const [ importingError, setImportingError] = useState<string>("");

    const [showStatusFilter, setShowStatusFilter] = useState<string>("all")
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ProgramModelType>>({
        columnAccessor: 'name',
        direction: 'asc',
      });

    const notArchivedDocuments = useMemo( () => {
        return filter(documents,({status}) => {return status != "archived"});
    }, [documents])



    async function onPublishSelection() {
        selectedRows.map(async (doc) => {
            const _doc  = {...doc}
            _doc.status = "published";
            await updateDoc(_doc.id || "", _doc);
        })
    }

    useEffect(() => {
        fetchDocuments(1000);
        fetchCampuses(1000);
        fetchSubjects(1000);
    }, [])

    useEffect(() => {
        setSelectedDocuments(sortBy(filter(documents,({status}) => {return status != "archived"}), "name") as ProgramModelType[]);
    }, [documents])

    useEffect(() => {
        const data = sortBy(selectedDocuments, sortStatus.columnAccessor) as ProgramModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        let data = filter(documents, (doc) => {
            if(doc.status == "archived")
                return false;

            if (
                debouncedQuery !== '' &&
                !`${doc.name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
              )
            return false;
            
            if(showStatusFilter === "all")
                return true;
            

            return doc.status === showStatusFilter


        });
        data = sortBy(data, sortStatus.columnAccessor) as ProgramModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() as ProgramModelType[] : data as ProgramModelType[]);
    }, [debouncedQuery, showStatusFilter])

    useEffect(() => {
        const from = (page - 1) * pageSize;
        const to = from + pageSize;
        setPaginatedDocuments(selectedDocuments.slice(from, to))
    }, [page, selectedDocuments, pageSize])


    function getCampusList(studyOptions:StudyOptionModelType[]) {
        const _list = studyOptions.map((so) => {
            return so.campus.name
        })
        return [... new Set(_list)];
    }

    function convertDocsExportToCSV() {
        const headers = ['id', 'name', 'url'].map((d) => d).join(',') + '\n';

        const rows = documents.map((row) => {
            // Map each row to CSV format
            return ['id', 'name', 'url'].map((field) => `"${row[field as 'id' | 'name' | 'url']?.replace(/"/g, '""')}"` || '').join(',');
          })
          .join('\n');

        return headers + rows;
    }
    
    function downloadCSV() {
        const data = convertDocsExportToCSV();
        if (data === '') {
            alert('No data to export');
          } else {
            // Create CSV file and initiate download
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'programs.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
    }

    async function importProgramsFromCSV(data:any) {
        setImportOpen(false);
        setImportingNotification(true);
        setImportingError("");
        setImportingStatus("importing");
        if(data.error) {
            setImportingError(data.error);
            setImportingStatus("error");
            return;
        }
        const rows = data.rows as {index:number, values:{[key:string]:string}}[]
        const programsToAdd:ProgramModelType[] = []
        let error = ""
        rows.map((row) => {
            console.log(row);
            try {
                const campus = campuses.find((val) =>  val.name == row.values.campus);
                if(!campus) 
                    throw Error(`Campus named: ${row.values.campus} not found`)

                const _campus = {...campus};
                delete _campus.snapshot;
                
                const zodProgram = programSchema.parse({
                    name: row.values.name,
                    internalId: row.values.internalId,
                    overview: row.values.overview,
                    degreeType: row.values.degreeType?.toLocaleLowerCase(),
                    brochure: row.values.brochure,
                    tuition: [{
                        amount: Number.parseFloat(row.values.tuition),
                        currency: row.values.tuitionCurrency,
                        period: row.values.tuitionPeriod
                    }],
                    studyOptions: [
                        {
                            schedule: row.values.schedule?.toLocaleLowerCase(),
                            delivery: row.values.delivery?.toLocaleLowerCase(),
                            intakes: (row.values.intakes || "").split(","),
                            language: row.values.language?.toLocaleLowerCase(),
                            duration: Number.parseInt(row.values.duration),
                            durationType: row.values.durationType,
                            campus: _campus
                        }
                    ]
                })
                
                programsToAdd.push(zodProgram as ProgramModelType);
            }
            catch(err) {
                if(err instanceof z.ZodError)
                    error = `Error on validating row ${row.index+1}, error: ${err.message}`
                else
                    error = `Error on validating row ${row.index+1}, error: ${err}`
            }
        })
        if(error) {
            setImportingError(error);
            setImportingStatus("error");
            return;
        }

        await Promise.all(programsToAdd.map(async (p, index) => {
            try {
                await addDoc(p);
            }
            catch(err) {
                error = `Error on validating row ${index+1}, error: ${err}`
            }
        }))

        if(error) {
            setImportingError(error);
            setImportingStatus("error");
            return;
        }

        setImportingStatus("done");
    }
    function renderImportNotification() {
        
        if(!importingNotification || importingStatus == "init")
            return null;
        if(importingStatus == "importing") {
            return (
                <Notification loading withCloseButton={false} withBorder title="Importing Status">
                    We are processing the file and importing the programs
                </Notification>
            )
        }
        else if(importingStatus == "error") {
            return (
            <Notification icon={<IconX/>} color="red" withCloseButton={true} onClose={() => {setImportingNotification(false)}} withBorder title="Import Failed">
                {importingError}
            </Notification>
            )
        }
        else if(importingStatus == "done") {
            return (
            <Notification icon={<IconCheck/>} color="green" withCloseButton={true} onClose={() => {setImportingNotification(false)}} withBorder title="Import Successful">
                The programs where created and updated successfully.
            </Notification>
            )
        }
    }

    async function onProgramSave(program:ProgramModelType) {
        if(program.id) {
            const _update_res = await updateDoc(program.id, program);
            if(_update_res) {
                notifications.show({
                    title: 'Update Successful',
                    message: `${program.name} was updated successfully.`
                })
                setProgramEdit(null);
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Update Unsuccessful',
                    message: `${program.name} update had an issue.`
                })
                }
        }
        else {
            const _add_res = await addDoc(program);
            if(_add_res) {
                notifications.show({
                    title: 'Added Successful',
                    message: `${program.name} was added successfully.`
                })
                setProgramEdit(null);
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Addition Unsuccessful',
                    message: `${program.name} creation had an issue.`
                })
            }
            
        }
    }

    async function onProgramDelete(program:ProgramModelType) {
        const _del_res = await deleteDoc(program.id || "");
        if(_del_res) {
            notifications.show({
                title: 'Deletion Successful',
                message: `${program.name} was deleted successfully.`
            });
            setProgramEdit(null);
        }
        else {
            notifications.show({
                color: "red",
                title: 'Deletion Unsuccessful',
                message: `${program.name} deletion had an issue.`
            });
        }
    }

    function reloadCampuses() {
        fetchCampuses(1000);
    }
    function reloadSubjects() {
        fetchSubjects(1000);
    }

    return (
        <>
            <PageHeader title={"Programs"}>
                <Group>
                    <Button onClick={() => {setProgramEdit(programSchema.parse({}))}} leftSection={<IconPlus size={14}/>}>Add Program</Button>    
                </Group>
            </PageHeader>
            <Container size={"100%"} mih={"calc(100vh - 82px)"} className={pageStyles.main}>
                <CSVImporter
                    modalIsOpen={importOpen}
                    modalOnCloseTriggered={() => setImportOpen(false)}
                    darkMode={true}
                    onComplete={(data) => {importProgramsFromCSV(data) }}
                    template={{
                    columns: ProgramImportColumns,
                    }}
                />
                <Drawer pos={"relative"} withCloseButton={false} opened={programEdit?true:false} position="right" size={"xl"} onClose={() => {}}>
                    <ProgramComponent program={programEdit as ProgramModelType} campuses={campuses as CampusModelType[]} subjects={subjects} onSave={onProgramSave} onClose={() => {setProgramEdit(null)}} onDelete={onProgramDelete}/>
                </Drawer>
                <Paper withBorder radius={"lg"} bg={"gray.1"} style={{overflow: "hidden"}}>
                    <Group gap={"xs"}  p={"md"}>
                        <IconTable color="var(--mantine-color-brand-filled)" /><Text c={"dimmed"} size={"sm"}>This table show the data related to programs, campuses and subjects.</Text>
                    </Group>
                    <Tabs defaultValue={"programs"} value={activeTab} onChange={setActiveTab}>
                        <Tabs.List  bg={"white"} pt={"md"} px={"md"}>
                            <Tabs.Tab value={"programs"}>
                                <Text size={"md"} fw={`${activeTab == "programs"? "900":"normal"}`} c={`${activeTab == "programs"? "brand.7":"gray.7"}`}>Programs</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={"campuses"}>
                                <Text size={"md"} fw={`${activeTab == "campuses"? "900":"normal"}`} c={`${activeTab == "campuses"? "brand.7":"gray.7"}`}>Campuses</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={"subjects"}>
                                <Text size={"md"} fw={`${activeTab == "subjects"? "900":"normal"}`} c={`${activeTab == "subjects"? "brand.7":"gray.7"}`}>Subjects</Text>
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="programs">
                            <Group bg={"white"} p={"md"} justify="space-between" wrap="nowrap" h={72}>
                                <Group wrap="nowrap" >
                                    <TextInput
                                    placeholder="Search program by name"
                                    leftSection={<IconSearch size={16} />}
                                    w={"100%"}
                                    maw={300}
                                    value={query}
                                    onChange={(e) => {setQuery(e.currentTarget.value); setPage(1)}}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                        <IconX size={14} />
                                        </ActionIcon>
                                    }/>
                                    <Group visibleFrom="md" wrap="nowrap" ml={"sm"}>
                                    <Text size={"sm"}  c={"dimmed"}>Show: </Text>
                                    <SegmentedControl
                                        color={"brand.4"}
                                        value={showStatusFilter}
                                        onChange={setShowStatusFilter}
                                        styles={{
                                            root: {
                                                backgroundColor: "transparent"
                                            },
                                            label: {
                                                textTransform: "capitalize"
                                            }
                                        }}
                                        withItemsBorders={false} data={['all', 'published', 'draft']} 
                                        />
                                        </Group>
                                </Group>
                                <Group visibleFrom="md" justify={"flex-end"}>
                                    {selectedRows.length == 0 && <Button onClick={downloadCSV} variant={"light"} c={"green"} leftSection={<IconDownload style={{width: rem(16), height: rem(16)}}/>}>Export Programs</Button>}
                                    {selectedRows.length == 0 && <Button visibleFrom="lg"  onClick={() => setImportOpen(true)}  variant={"light"} color={"green"} leftSection={<IconTableImport/>}> Import Programs </Button> }
                                    {selectedRows.length > 0 && <Button onClick={onPublishSelection} color={"green"} leftSection={<IconArticle style={{width: rem(16), height: rem(16)}}/>}>Publish [<strong>{selectedRows.length} </strong>] programs</Button>}
                                </Group>
                            </Group>
                            {renderImportNotification()}
                            <DataTable
                            key={"Programs"}
                                withTableBorder
                                borderRadius="0"
                                withColumnBorders
                                highlightOnHover
                                fetching={loading}
                                selectedRecords={selectedRows}
                                onSelectedRecordsChange={setSelectedRows}
                                borderColor={"var(--mantine-color-gray-2"}
                                styles={{
                                    root: (theme) => ({
                                        border: `1px solid ${theme.colors.gray[1]}`,
                                    }),
                                    header: (theme) => ({
                                        backgroundColor: theme.colors.gray[0],
                                        height: "40px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                        fontSize: "small",
                                        color: theme.colors.gray[7],
                                        borderColor: theme.colors.gray[1]
                                    })
                                }}
                                rowStyle={({}) => (
                                    {
                                        height: "50px"
                                    }
                                )}
                                records={paginatedDocuments}
                                sortStatus={sortStatus}
                                onSortStatusChange={setSortStatus}
                                totalRecords={selectedDocuments.length}
                                recordsPerPage={pageSize}
                                recordsPerPageOptions={PAGE_SIZES}
                                onRecordsPerPageChange={setPageSize}
                                page={page}
                                onPageChange={(p) => setPage(p)}
                                // define columns
                                columns={[
                                    
                                    { accessor: 'name',
                                        sortable: true,
                                        width: 380,
                                        render: (d) => (
                                            <Box maw={"100%"}><Anchor size="sm" onClick={() => {setProgramEdit(d)}}> <Tooltip label={d?.name}><Text fw={"bold"} truncate="end">{d?.name}</Text></Tooltip></Anchor></Box>
                                        )
                                    },
                                    {
                                        accessor: 'degreeType',
                                        title: 'degree',
                                        sortable: true,
                                        render: ({ degreeType }) => (
                                            <Badge variant={"light"} color={`${DEGREE_BADGE_COLOR[degreeType || ""]}`}>{degreeType}</Badge>
                                        )
                                      
                                    },
                                    { accessor: 'status', 
                                        render: ({ status }) => (
                                            <Badge  variant={"dot"}   color={`${STATUS_BADGE_COLOR[status|| ""]}`}>{status}</Badge>
                                        )
                                    },
                                    /** CANT Implement correctly yet{ accessor: 'subjects', 
                                        render: ({ subjects}) => (
                                            <Group>
                                            {subjects.map((s) => {
                                                return <Badge variant={"dot"} >{s}</Badge>
                                            })}
                                            </Group>
                                        )
                                    },**/
                                    
                                    { accessor: 'studyOptions',
                                        render: ({studyOptions}) => (
                                            <Text size={"sm"} c={"dimmed"}>{(studyOptions || []).length}</Text>
                                        )
                                     },
                                     { accessor: 'campus',
                                        render: ({studyOptions}) => (
                                            <Text size={"sm"} c={"dimmed"}>{getCampusList(studyOptions as StudyOptionModelType[] || []).join(", ")}</Text>
                                        )
                                     },
                                ]}
                                
                                />
                        </Tabs.Panel>
                        <Tabs.Panel value={"subjects"}>
                            <SubjectIndexComponent programs={notArchivedDocuments as ProgramModelType[]} reload={reloadSubjects}/>
                        </Tabs.Panel>
                        <Tabs.Panel value={"campuses"}>
                            <CampusIndexComponent programs={notArchivedDocuments as ProgramModelType[]} reload={reloadCampuses}/>
                        </Tabs.Panel>
                    </Tabs>
                </Paper>
            </Container>
        </>
    )

}