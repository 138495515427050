import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconPlayerPlayFilled } from "@tabler/icons-react"
import _ from "lodash"

export type TriggerActionType = {
    trigger: string
}



export const TriggerActionSchema = [
    {
        target: "trigger",
        label: "Trigger",
        type: "text",
    }
]

export const TriggerActionDefault = {
    trigger: "Trigger Text"
}

export function TriggerActionNode({id, action, isConnectable, edges}) {

    return (
        <>
        <Stack>
          <Group bg={"yellow.1"} px={"lg"} py={4}>
            <Avatar color={"yellow.9"} size={18}>
              <IconPlayerPlayFilled size={10} />
            </Avatar>
            <Text  fw={"bold"} c={"yellow.9"}>Trigger</Text>
          </Group>
          
          <Title order={5} px={"lg"}>{action.trigger}</Title>
        </Stack>
        
        <Handle
          type="source"
          id={`${id}-source`}
          position={Position.Right}
          className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
          style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
          isConnectable={isConnectable}
        />
        </>
    )

}

