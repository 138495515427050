export type ChatUserType = {
    id?: string,
    type?: "ambassador" | "admission" | "recruitment",
    image?: string,
    name: string,
    email: string,
    status?: "draft" | "published" | "archived",
    description: string,
    graduationYear?: number,
    program?: string,
    requestsCount?: number,
    nationality?: {
        country: string,
        countryCode: string
    }
}

const ChatUserDefault:ChatUserType = {
    type: "ambassador",
    name: "",
    email: "",
    description: "",
    status: "draft",
    requestsCount: 0
}

export function newChatUser(type:"ambassador" | "admission" | "recruitment" = "ambassador"):ChatUserType {
    return {...ChatUserDefault, type: type};
}