import 'reactflow/dist/style.css';
import styles from "./flows.module.scss"

import ELK from 'elkjs/lib/elk.bundled.js';
import { v4 as uuidv4 } from "uuid";
import { useEffect, useContext, useState, MouseEvent, useRef, useCallback, useMemo } from "react";
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Group, Button, Table, Container, ActionIcon, Modal, Stack, TextInput, Fieldset, Checkbox, Select } from "@mantine/core";
import _ from "lodash";
import {  IconX, IconMessageCircle2Filled, IconSelect, IconLink, IconUnlink, IconInputCheck, IconSettings, IconForms } from '@tabler/icons-react';
import ReactFlow, {
    ReactFlowProvider,
    Controls,
    Background,
    useEdgesState,
    useNodesState,
    MarkerType,
    MiniMap,
    useReactFlow,
    useNodesInitialized,

    Node,

  } from 'reactflow';
 

import { MenuBarComponent } from '../../../components/MenuBar';


import ChatNode from './nodes/ChatNode';

import { EditAction } from './actions/EditAction';
import { MessageActionSchema, MessageActionDefault } from './actions/MessageAction';
import { StartFlowActionSchema, StartFlowActionDefault } from './actions/StartFlowAction';
import { FormSubmissionActionSchema, FormSubmissionActionDefault } from './actions/FormSubmissionAction';
import { TriggerActionSchema } from './actions/TriggerAction';
import { SimpleSelectActionDefault, SimpleSelectActionSchema } from './actions/SimpleSelectAction';
import {  EnhancedLinkActionSchema, EnhancedLinkDefault } from './actions/EnhancedLinkAction';
import { InputActionSchema, InputActionDefault } from './actions/InputAction';
import { SelectedProgramsActionDefault, SelectedProgramsActionSchema } from './actions/SelectedProgramsAction';
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext";
import { Link, useParams } from 'react-router-dom';
import { getOrgFlow, updateOrgFlow } from '../../../services/database';
import { FlowType } from '../../../models/@type.flow';
import { BrochureActionDefault, BrochureActionSchema } from './actions/BrochureLinkAction';
import { ShowProgramsActionDefault, ShowProgramsActionEdit, ShowProgramsActionSchema } from './actions/ShowProgramsAction';


/** 
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import dagre from 'cytoscape-dagre';

cytoscape.use(klay);
*/


const ActionAddList = [
    {
        label: "Message",
        value: "message",
        icon: <IconMessageCircle2Filled/>
    },
    {
        label: "Multiple Options",
        value: "options",
        icon: <IconSelect/>
    },
    {
        label: "Enhanced Link",
        value: "link",
        icon: <IconLink/>
    },
    {
        label: "User Input",
        value: "input",
        icon: <IconInputCheck/>
    },
    {
        label: "Start Flow",
        value: "start_flow",
        icon: <IconInputCheck/>
    }
    ,
    {
        label: "Form Submission",
        value: "form_submission",
        icon: <IconForms/>
    },
    {
        label: "Show Program Brochure Link",
        value: "brochure_link",
        icon: <IconSelect/>
    },
    {
        label: "Show Programs",
        value: "show_programs",
        icon: <IconSelect/>
    }
]

const ActionSchemaLibrary = {
    message: MessageActionSchema,
    trigger: TriggerActionSchema,
    options: SimpleSelectActionSchema,
    link: EnhancedLinkActionSchema,
    input: InputActionSchema,
    start_flow: StartFlowActionSchema,
    form_submission: FormSubmissionActionSchema,
    selected_programs: SelectedProgramsActionSchema,
    brochure_link: BrochureActionSchema,
    show_programs: ShowProgramsActionSchema
}

const ActionDefaultValuesLibrary = {
    message: MessageActionDefault,
    options: SimpleSelectActionDefault,
    link: EnhancedLinkDefault,
    input: InputActionDefault,
    start_flow: StartFlowActionDefault,
    form_submission: FormSubmissionActionDefault,
    selected_programs: SelectedProgramsActionDefault,
    brochure_link: BrochureActionDefault,
    show_programs: ShowProgramsActionDefault
}

const nodeTypes = {
    chatNode: ChatNode
};



  const layoutOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'RIGHT',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100',
    'elk.spacing.nodeNode': '80',
  };
  
  const elk = new ELK();
  
  // uses elkjs to give each node a layouted position
  export const getLayoutedNodes = async (nodes, edges) => {
    const graph = {
      id: 'root',
      layoutOptions,
      children: nodes.map((n) => {
        const targetPorts = [
            {id: `${n.id}-target`,
                properties: {
                    side: 'WEST',
                  },
            }
        ] 
        const sourcePorts =  [
            {
                id: `${n.id}-source`,
                properties: {
                    side: 'EAST',
                  },
            }
        ]
        if(n.data.type == "options") {
            n.data.action.options.map((val, index) => {
                sourcePorts.push({
                    id: `${n.id}-${val.id}-source`,
                    properties: {
                        side: 'EAST',
                    },
                })
            })
        }

  
        return {
          id: n.id,
          width: n.width ?? 390,
          height: n.height ?? 200,
          // ⚠️ we need to tell elk that the ports are fixed, in order to reduce edge crossings
          properties: {
            'org.eclipse.elk.portConstraints': 'FIXED_RATIO',
          },
          // we are also passing the id, so we can also handle edges without a sourceHandle or targetHandle option
          ports: [{ id: n.id }, ...targetPorts, ...sourcePorts],
        };
      }),
      edges: edges.map((e) => ({
        id: e.id,
        sources: [e.sourceHandle || e.source],
        targets: [e.targetHandle || e.target],
      })),
    };
  
    const layoutedGraph = await elk.layout(graph);
  
    const layoutedNodes = nodes.map((node) => {
      const layoutedNode = layoutedGraph.children?.find(
        (lgNode) => lgNode.id === node.id,
      );
  
      return {
        ...node,
        position: {
          x: layoutedNode?.x ?? 0,
          y: layoutedNode?.y ?? 0,
        },
      };
    });
  
    return layoutedNodes;
  };

  /** 
// Function to get layouted nodes using Cytoscape with klay layout
export const getLayoutedNodesCyto = async (nodes, edges) => {
    return new Promise((resolve, reject) => {
        const elements= {
            nodes: nodes.map(n => ({ data: { id: n.id, width: n.width ?? 350, height: n.height ?? 200 } })),
            edges: edges.map(e => ({ data: { id: e.id, source: e.source, target: e.target } }))
        }
        console.log('elements', elements)
        const cy = cytoscape({
            headless: true,
            elements: elements,
            layout: {
                name: 'klay',
                nodeDimensionsIncludeLabels: true,
                fit: false, // Adjust viewport to include all nodes
                klay: {
                    direction: 'RIGHT',
                    spacing: 200, // Increase spacing between nodes
                    edgeSpacingFactor: 1.0, // Increase edge spacing
                    nodePlacement: 'SIMPLE', // Ensure simple node placement
                    
                }
            },
            style: [
                {
                    selector: 'node',
                    style: {
                        width: 'data(width)',
                        height: 'data(height)',
                        'background-color': '#11479e',
                        label: 'data(id)',
                        'text-valign': 'center',
                        'text-halign': 'center',
                        'text-outline-color': '#11479e',
                        'text-outline-width': 2,
                        color: '#fff'
                    }
                },
                {
                    selector: 'edge',
                    style: {
                        width: 2,
                        'line-color': '#9dbaea',
                        'target-arrow-color': '#9dbaea',
                        'target-arrow-shape': 'triangle'
                    }
                }
            ]
        });

        console.log('Cytoscape elements before layout:', cy.elements().jsons());

        cy.on('layoutstop', () => {
            console.log('Layout finished');

            const layoutedNodes = nodes.map(node => {
                const cyNode = cy.getElementById(node.id);
                console.log(cyNode.position("x") + node.width);
                console.log(cyNode.position("y"));
                console.log(node);
                return {
                    ...node,
                    position: {
                        x: cyNode.position('x')*node.width/10,
                        y:  cyNode.position('y')
                    }
                };
            });

            console.log('Layouted nodes:', layoutedNodes);
            resolve(layoutedNodes);
        });

        cy.layout({ name: 'klay' }).run();

        // Set a timeout to reject the promise if layout takes too long
        setTimeout(() => {
            reject(new Error('Layout took too long to complete'));
        }, 100000); // Adjust timeout as needed
    });
};*/

function ReactFlowComponent({nodes, onEdit, setNodes, onNodesChange, edges, setEdges, onEdgesChange, onNodeClick, connectingNode, onNewNode, onConnectingNodes}) {
    const [viewport, setViewport] = useState({ x: 0, y: 0, zoom: 0.5 });
    
    const { screenToFlowPosition, getNodes, getEdges, fitView, setViewport: setFlowViewport } = useReactFlow();
    const nodesInitialized = useNodesInitialized();
    


  
    useEffect(() => {
      if (nodesInitialized) {
        const layoutNodes = async () => {
            try {
                const layoutedNodes = await getLayoutedNodes(
                    getNodes(),
                    getEdges(),
                );
        
                setNodes(layoutedNodes);
                setTimeout(() => setFlowViewport(viewport), 0);
            } catch(err) {
                console.log(err)
            }
        };
    
  
        layoutNodes();
        
      }
    }, [nodesInitialized, getNodes, getEdges, setNodes, setEdges,fitView, onEdit]);
     
    const onMove = useCallback((_, _viewport) => {
        setViewport(_viewport); // Store the x/y and zoom in the state

      }, []);

    const onConnect = useCallback(
        (params) => {
          // reset the start node on connections  
          onConnectingNodes(params.target, params.targetHandle)
          
        },
        [],
    );

    const onConnectStart = useCallback((_, { nodeId, handleId, handleType }) => {
        //console.log(nodeId, handleId)
        connectingNode.current = {id: nodeId, handleId: handleId};
        
    }, []);

    

    const onConnectEnd = useCallback(
        (event) => {
            //console.log(connectingNode)
          if (!connectingNode.current) return;
            
          const targetIsPane = event.target.classList.contains('react-flow__pane');
    
          if (targetIsPane) {
            // we need to remove the wrapper bounds, in order to get the correct position
            onNewNode()
          }
          
        },
        [screenToFlowPosition],
      );
      
    return (
        <ReactFlow 
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            fitView 
            onNodeClick={onNodeClick} 
            onMove={onMove}
            defaultViewport={viewport}
            nodeTypes={nodeTypes}>
                <Background />
                <Controls />
                <MiniMap nodeStrokeWidth={3} />
            </ReactFlow>
    )
}

function FlowSettings({flow, onChange}) {

    return (
        <>
        <Stack gap={"sm"}>
            <TextInput
            size="md"
            label="Flow Name"
            description="A name used to recognize the flow intent."
            placeholder="Flow Name"
            value={flow.name}
            onChange={(e) => {onChange({name: e.target.value})}}
            />
            <Fieldset legend={"Lead Capture"}>
                <Stack gap={"sm"}>
                    <Checkbox
                    checked={(flow.type == "form")}
                    label="Is the flow a form?"
                    onChange={(e) => {onChange({type: e.target.checked? "form":"default"})}}
                    />
                    <TextInput
                    disabled={(flow.type != "form")}
                    size="sm"
                    label="Form id"
                    description="The internal identificator for a form."
                    placeholder="form_id"
                    value={flow.form_id}
                    onChange={(e) => {onChange({form_id: e.target.value.replace(/\s/g, "_").toLowerCase()})}}
                    />
                </Stack>
            </Fieldset>
        </Stack>
        </>
    )
}


export function FlowPage() {
    const connectingNode = useRef<object|null>(null)

    const [init, setInit] = useState<boolean>(false);
    const params = useParams()
    const [opened, { open, close }] = useDisclosure(false);
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ editNode, setEditNode ] = useState<string|null>(null)
    const [flow, setFlow] = useState<FlowType | null>(null)
    const [ changed, setChanged ] = useState<boolean>(false)
    const [onFlowSettings, setOnFlowSettings] = useState<boolean>(false)


    
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const edgesRef = useRef(null)

    useEffect(() => {
        get(params.id)
    }, [])

    useEffect(() => {
        if(flow) {
            unserializeFlow(flow)
        }

    }, [init])

    useEffect(() => {
        edgesRef.current = edges;
    }, [edges])



    async function get(id) {
        const _res = await getOrgFlow(id, selected?.id)
        if(_res) 
            setFlow(_res)

        setInit(true)
    }

    function unserializeFlow(flow) {
        if(flow.actions.length > 0) {
            const _nodes = flow.actions.map((val) => { return {id: val.id, type: "chatNode", position: { x: 0, y: 0 }, data: val}})
            setNodes(_nodes)
        }
        if(flow.connections.length > 0) {
            const _edges = flow.connections.map((val) => { return {...val, type: "smoothstep", markerEnd: { type: MarkerType.ArrowClosed} }});   
            setEdges(_edges)
        }  
    }

    function serializeNodes() {
        const _actions = nodes.map((val) => val.data);

        return _actions;
    }

    function serializeConnections() {
        const _connections = edges.map((val) => { return {id: val.id, source: val.source, sourceHandle: val.sourceHandle, target: val.target, targetHandle: val.targetHandle } })
        return _connections;
    }

    async function onSave() {
        setChanged(false);
        const _flow = _.cloneDeep(flow || {})
        _flow.actions = serializeNodes();
        _flow.connections = serializeConnections();

        const _res = await updateOrgFlow(_flow, selected?.id);
        if(_res) {

        }
        else {
            setChanged(true)
        }

    }



    function onNodeClick(evt: MouseEvent, node: Node) {
        if(node.data.type == "end"){
            open()
        }
        else {
            const pos = _.findIndex(nodes, {id: node.id})
            setEditNode(pos.toString())
        }
    }

    function onEditNode(id:string, data: object) {
        setChanged(true);
        const _nodes  = _.cloneDeep(nodes);
        const _index = _.findIndex(_nodes, {id: id})
        _nodes[_index].data.action = data;
        
        setNodes(_nodes)
    }

    function onNewNode() {
        open()
    }

    function onConnectNodes(target:string, handleTarget: string) {
        //connectingNode.current?.id, connectingNode.current?.handleId)
        const _edge = {id: `${connectingNode.current?.id}-${connectingNode.current?.handleId || ""}-${target}`, source: connectingNode.current?.id, sourceHandle: connectingNode.current?.handleId, target: target, targetHandle: handleTarget, type: "smoothstep", markerEnd: {
            type: MarkerType.ArrowClosed,
          }}
        
          //console.log(edgesRef.current)
            const _edges = _.cloneDeep(edgesRef.current || []);
            const oldConnectionIndex = _.findIndex(_edges, {"source": connectingNode.current?.id, sourceHandle: connectingNode.current?.handleId})
          //console.log(oldConnectionIndex);
            if(oldConnectionIndex > -1) {
                _edges.splice(oldConnectionIndex, 1)
            }
            _edges.push(_edge)
            //console.log("pushed edge")
            //console.log(_edge)
        setChanged(true);
        //console.log(_edges)
        setEdges(_edges)
        connectingNode.current = null;
    }

    function onRemoveConnection() {
        setChanged(true);
        setEdges((old) => {
            const _edges = _.cloneDeep(old);
            const oldConnectionIndex = _.findIndex(_edges, {"source": connectingNode.current?.id, sourceHandle: connectingNode.current?.handleId})
           
            if(oldConnectionIndex > -1) {
                _edges.splice(oldConnectionIndex, 1)
            }
            return _edges
        })
        connectingNode.current = null;
        close()
    }
    
    function addNewNode(type: string) {
        const _id = uuidv4()
        const _new = {id: _id, type: "chatNode", position: { x: 0, y: 0 }, data: {action: ActionDefaultValuesLibrary[type], id: _id, type: type}};
        const _nodes  = _.cloneDeep(nodes);
        _nodes.push(_new)
        setChanged(true);
        setNodes(_nodes)
        if(connectingNode.current)
            onConnectNodes(_id, `${_id}-target`)
        close()
    }

    function onDeleteNode(id) {
        setChanged(true);
        setEdges((old) => {
            let _edges = _.cloneDeep(old);
            _.remove(_edges, {source: id});
            _.remove(_edges, {target: id});
            return _edges
        })
        setNodes((old) => {
            let _nodes = _.cloneDeep(old);
            _.remove(_nodes, {id: id})
            return _nodes;
        })
        setEditNode(null)
    }

    return (
        <>
        <MenuBarComponent title={flow?.name} >
            <Group>
                <ActionIcon onClick={() => {setOnFlowSettings(true)}} size={"lg"} variant={"transparent"}><IconSettings  size={18}/></ActionIcon>
                <Select checkIconPosition='right' value={flow?.status || "draft"} data={[{label: "Draft", value: "draft"}, {label: "Published", value: "published"}, {label: "Hidden", value: "hidden"}]} onChange={(value) => {setFlow({...flow as FlowType, status: (value as "draft" | "published" | "hidden" | "archived") || "draft"}); setChanged(true);}} />
                <Button disabled={!changed} onClick={() => onSave()}>Save</Button>
                <ActionIcon component={Link} to="/conversations/flows" size={"lg"} variant={"light"}><IconX  size={18}/></ActionIcon>
            </Group>
        </MenuBarComponent>
        <Drawer position='right' opened={editNode? true:false} onClose={() => setEditNode(null)} title="Edit Action">
            {editNode && 
                (nodes[editNode as string].data.type != "show_programs"?<EditAction clientId={selected?.id || ""} onDelete={onDeleteNode} id={nodes[editNode].id} data={nodes[editNode].data} schema={ActionSchemaLibrary[nodes[editNode].data.type]} onChange={onEditNode}/>: <ShowProgramsActionEdit id={nodes[editNode].id} data={nodes[editNode].data as object} onChange={onEditNode} onDelete={onDeleteNode}/>)
            }
            
        </Drawer>
        <Drawer position='right' opened={onFlowSettings} onClose={() => setOnFlowSettings(false)} title="Settings">
            <FlowSettings flow={flow} onChange={(value:{name?:string, type?:string, requestName?:string})=>{setChanged(true);setFlow((prev) => {return {...prev as FlowType, ...value}})}}/>            
        </Drawer>
        <Modal opened={opened} onClose={close} title="Add a New Action">
            <Stack px={"xl"} py={"lg"}>
                {ActionAddList.map((val, index) =>{
                    return (
                        <Button onClick={() => addNewNode(val.value)} fullWidth={false} rightSection={val.icon} key={index}>{val.label}</Button>
                    )
                })}
                <Button mt={"xl"} variant={"subtle"} onClick={onRemoveConnection} rightSection={<IconUnlink/>}>Remove Connection</Button>
            </Stack>
           
        </Modal>
        <Container className={styles.flowEditorWrapper} mt={"xl"} size={"xl"} px={0} h={"calc(100vh - 130px)"} bg={"gray.0"}>
        <ReactFlowProvider>
            <ReactFlowComponent onEdit={editNode} onConnectingNodes={onConnectNodes} onNewNode={onNewNode} connectingNode={connectingNode} nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange} edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onNodeClick={onNodeClick}  />
        </ReactFlowProvider>
        </Container>
        
        </>
    )
}