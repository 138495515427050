import { Box, Flex, ScrollArea, Stack, Divider, Text } from "@mantine/core";
import {  ChatHistoryElementType, ChatSessionType, ExtendedChatSessionType } from "../../models/@type.chats";
import { useEffect, useState } from "react";
import { getOrgCRMAttributes, getOrgSessionHistory, getOrgSessionLead } from "../../services/database";
import { SessionBoxComponent } from "./_SessionBox";


import styles from './chat.session.module.css'
import { ChatSessionHistoryComponent } from "./_SessionHistory";
import { LeadType } from "../../models/@type.lead";
import { ChatSessionLeadInfoComponent } from "./_SessionLead";
import { CRMAttributeType } from "../../models/@type.client";

type SessionChatComponentProps = {
    clientId: string,
    sessions: ExtendedChatSessionType[]
}

export function SessionChatComponent({clientId, sessions}:SessionChatComponentProps) {
    const [ current, setCurrent ] = useState<ExtendedChatSessionType | null>(null)
    const [ currentChatHistory, setCurrentChatHistory ] = useState<ChatHistoryElementType[] | null>(null);

    const [leadData, setLeadData] = useState<LeadType | null>(null);
    const [leadAttributes, setLeadAttributes] = useState<CRMAttributeType[]>([])

    useEffect(() => {
        init()
    }, []);
    useEffect(() => {
        init()
    },[clientId])

    async function init() {
        const _attrs = await getOrgCRMAttributes(clientId);
        setLeadAttributes(_attrs);
    }

    async function getSessionChatHistory(sessionId:string) {

        const _chatHistory = await getOrgSessionHistory(clientId, sessionId);
        setCurrentChatHistory(_chatHistory);
        
    } 

    async function getSessionDetails(session:ExtendedChatSessionType) {
        setCurrent(session);
        getSessionChatHistory(session.id);
        const _lead = await getOrgSessionLead(clientId, session.id, session.session.deviceId);
        setLeadData(_lead);

    }

    return (
        <Flex className={styles.chatSessionsWrapper} direction={"row"} wrap={"nowrap"} mah={"100%"} style={{overflow: "hidden"}}>
            <Box w={280} mah={"100%"} >
            <ScrollArea h={"100%"}>
            <Stack w={280}  gap={0} className={styles.chatSessionList}>

                {sessions.map((val) => {
                    return (
                        <>
                    <SessionBoxComponent session={val} onClick={getSessionDetails} active={current?.id == val.id}/>
                    <Divider  />
                    </>
                    )
                })}
                
            </Stack>
            </ScrollArea>
            </Box>
            <Box w={"100%"} mah={"100%"} pos={"relative"}>
                    {currentChatHistory? <ChatSessionHistoryComponent history={currentChatHistory}/>: <Text ta={"center"} c="dimmed" w="100%">No session selected</Text>}
            </Box>
            <Box miw={280} w={280} className={styles.chatSessionAdditionalInfoWrapper}>
                <ChatSessionLeadInfoComponent lead={leadData} attributes={leadAttributes}/>
            </Box>
        </Flex>
    )
}