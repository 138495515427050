import { ChatUserType } from "../models/@type.chat.user";
import { FlowType } from "../models/@type.flow";
import { KnowledgeType } from "../models/@type.knowledge";
import { AssistantType, CRMAttributeType, ClientType, PromptType, ThemeType, WidgetConfigType } from '../models/@type.client'
import { firestore } from "./firebase";
import { doc, getDoc, addDoc, getDocs, collection, query, where, setDoc, getCountFromServer, deleteDoc, updateDoc, startAt, limit, orderBy, startAfter, endBefore, endAt, limitToLast } from "@firebase/firestore"
import { CampusType, ProgramType } from "../models/@type.data";
import { LeadType } from "../models/@type.lead";
import _, { startsWith } from "lodash";
import { ChatHistoryElementType, ChatSessionType, ExtendedChatSessionType } from "../models/@type.chats";


//Change to ENV variable
const API_URL = import.meta.env.VITE_API_URL



export async function getUserOrgs(id:string):Promise<ClientType[]> {

    try {
        const _q = query(collection(firestore, "clients"), where("users", "array-contains", id))
        const _res = await getDocs(_q)
        if(_res.docs.length == 0) {
            return []
        }
        else {
            return _res.docs.map((val) => {
                return {id: val.id, ...val.data()} as ClientType
            });
        }
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}

export async function getUserOrg(orgId:string):Promise<ClientType | null> {
    try {
        const _doc = await getDoc(doc(firestore, "clients", orgId));
        if(!_doc.exists())
            return null;
        else
            return {id: _doc.id, ..._doc.data()} as ClientType
    }
    catch(err) {
        return null;
    }
}

export async function updateUserOrg(orgId:string, data: {assistant?:AssistantType, prompt?:PromptType, prompts?: PromptType[], theme?: ThemeType[]}):Promise<string> {
    try {
        await updateDoc(doc(firestore, "clients", orgId), data);
        return orgId;
    }
    catch(err) {
        console.log(err)
        return "";
    }
} 

export async function getOrgFlows(id:string):Promise<FlowType[]> {
    try {
        const _res = await getDocs(collection(firestore, "clients", id, "flows"))
        if(_res.docs.length == 0) {
            return []
        }
        else {
            return _res.docs.map((val) => {
                const _flow = {id: val.id, ...val.data()} as {[key:string]:any};
                return _flow as FlowType
            });
        }
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}

export async function addOrgFlow(flow:Object, orgId:string) {
    try {
        const _doc = await addDoc(collection(firestore, "clients", orgId, "flows"), flow)
        return _doc.id;
    }
    catch(err) {
        return null;
    }
}

export async function getOrgFlow(flowId:string, orgId:string) {
    try {
        const _doc = await getDoc(doc(firestore, "clients", orgId, "flows", flowId));
        if(!_doc.exists())
            return null;
        else
            return {id: _doc.id, ..._doc.data()}
    }
    catch(err) {
        return null;
    }
}

export async function deleteOrgFlow(flowId:string, orgId:string) {
    try {
        const _res = await deleteDoc(doc(firestore, "clients", orgId, "flows", flowId));
        return _res;
    }
    catch(err) {
        return null;
    }
}

export async function updateOrgFlow(flow:FlowType, orgId:string) {
    const _flow = {...flow};
    try {
        const _id = flow.id as string; 
        delete _flow.id;
        delete _flow.embedding;
        await setDoc(doc(firestore, "clients", orgId, "flows", _id), _flow as Object);
        return true;
    }
    catch(err) {
        console.log(err)
        return false;
    }
}


export async function getOrgKnowledge(orgId:string) {
    try {
        const _res = await getDocs(collection(firestore, "clients", orgId, "knowledge"))
        if(_res.docs.length == 0) {
            return []
        }
        else {
            return _res.docs.map((val) => {
                return {id: val.id, ...val.data()}
            });
        }
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}

export async function getOrgKnowledgeById(knowledgeId:string, orgId:string) {
    try {
        const _doc = await getDoc(doc(firestore, "clients", orgId, "knowledge", knowledgeId));
        if(!_doc.exists())
            return null;
        else
            return {id: _doc.id, ..._doc.data()}
    }
    catch(err) {
        return null;
    }
}

export async function deleteOrgKnowledgeById(knowledgeId:string, orgId:string) {
    try {
        const _res = await deleteDoc(doc(firestore, "clients", orgId, "knowledge", knowledgeId));
        return _res;
    }
    catch(err) {
        return null;
    }
}

export async function addOrgKnowledge(knowledge:KnowledgeType, orgId:string) {
    try {
        knowledge.updatedAt = Date.now();
        const _doc = await addDoc(collection(firestore, "clients", orgId, "knowledge"), knowledge)
        return _doc.id;
    }
    catch(err) {
        return null;
    }

}

export async function updateOrgKnowledge(knowledge:KnowledgeType, orgId:string) {
    const _knowledge = {...knowledge};
    try {
        const _id = knowledge.id as string; 
        delete _knowledge.id;
        delete _knowledge.embedding;
        _knowledge.updatedAt = Date.now();
        await setDoc(doc(firestore, "clients", orgId, "knowledge", _id), _knowledge as Object);
        return true;
    }
    catch(err) {
        console.log(err)
        return false;
    }
}


export async function addOrgChatUser(chatUser:ChatUserType, orgId:string):Promise<string> {
    try {
        delete chatUser.id
        const _doc = await addDoc(collection(firestore, "clients", orgId, "chat_users"), chatUser)
        return _doc.id;
    }
    catch(err) {
        return "";
    }
}

export async function updateOrgChatUser(chatUser: ChatUserType, orgId: string):Promise<string> {
    const _chatUser = {...chatUser}
    try {
        const _id = chatUser.id as string; 
        delete _chatUser.id;
        await setDoc(doc(firestore, "clients", orgId, "chat_users", _id), _chatUser);
        return _id;
    }
    catch(err) {
        console.log(err)
        return "";
    }
}

export async function getOrgChatUsers(orgId:string):Promise<ChatUserType[]> {
    try {
        const _res = await getDocs(collection(firestore, "clients", orgId, "chat_users"))
        if(_res.docs.length == 0) {
            return []
        }
        else {
            return _res.docs.map((val) => {
                return {id: val.id, ...val.data()} as ChatUserType
            });
        }
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}


export async function getOrgFlowStats(id:string) {
    const _flow_stats_res = await fetch(`${API_URL}client/${id}/stats/flows_triggered`);
    const _flow_stats = await _flow_stats_res.json();
    const _flows = await getOrgFlows(id);
    const _data = _flow_stats.data.map((val:any) => {
        const name = _flows.find((el) => el.id == val.description)?.name || val.description;
        return {flow_name: name, flow_id: val.description, trigger_count: val.trigger_count, unique_sessions: val.unique_sessions}
    })
   
    return _data;
}

export async function getOrgPrograms(orgId:string):Promise<ProgramType[]> {
    try {
        const _res = await getDocs(query(collection(firestore, "clients", orgId, "programs"), where("status", "!=", "archived")));
        if(_res.docs.length == 0) {
            return [];
        }
        else {
            return _res.docs.map((val) => {
                return {id: val.id, ...val.data()} as ProgramType
            })
        }
    }
    catch(err) {

        return []
    }
}

export async function addOrgProgram(program:ProgramType, orgId:string):Promise<string> {
    try {
        const _doc = await addDoc(collection(firestore, "clients", orgId, "programs"), program);
        return _doc.id;
    }
    catch(err) {
        return "";
    }
}

export async function updateOrgProgram(program:ProgramType, orgId:string):Promise<string> {
    const _program = {...program};
    try {
        const _id = program.id as string; 
        delete _program.id;
        await setDoc(doc(firestore, "clients", orgId, "programs", _id), _program as Object);
        return _id;
    }
    catch(err) {
        console.log(err)
        return "";
    }
}

export async function deleteOrgProgram(programId:string, orgId:string):Promise<string> {
    try {
        await setDoc(doc(firestore, "clients", orgId, "programs", programId), {status: "archived"} as Object);
        return programId;
    }
    catch(err) {
        console.log(err)
        return "";
    }
}

export async function getOrgCampuses(orgId: string):Promise<CampusType[]> {
    try {
        const _res = await getDocs(collection(firestore, "clients", orgId, "campuses"))
        if(_res.docs.length == 0) {
            return []
        }
        else {
            return _res.docs.map((val) => {
                return {id: val.id, ...val.data()} as CampusType
            });
        }
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return [];
    }
}

export async function addOrgCampus(campus:CampusType, orgId: string):Promise<string> {
    try {
        const _doc = await addDoc(collection(firestore, "clients", orgId, "campuses"), campus)
        return _doc.id;
    }
    catch(err) {
        return "";
    }
}

export async function updateOrgCampus(campus:CampusType, orgId:string):Promise<string> {
    const _campus = {...campus};
    console.log(_campus)
    try {
        const _id = campus.id as string; 
        delete _campus.id;
        await setDoc(doc(firestore, "clients", orgId, "campuses", _id), _campus as Object);
        return _id;
    }
    catch(err) {
        console.log(err)
        return "";
    }
}

export async function deleteOrgCampus(campusId:string, orgId:string):Promise<string> {
    try {
        await deleteDoc(doc(firestore, "clients", orgId, "campuses", campusId));
        return campusId;
    }
    catch(err) {
        return "";
    }
}


export async function getOrgLeads(orgId: string):Promise<LeadType[]> {
    try {
        const _snap = await getDocs(collection(firestore, "clients", orgId, "leads"));
        return _snap.docs.map((val) => {
            return {id: val.id, ...val.data()} as LeadType
        })
    }
    catch(err) {
        return []
    }
}

export async function getOrgForms(orgId: string):Promise<FlowType[]> {
    try {
        const _snap = await getDocs(query(collection(firestore, "clients", orgId, "flows"), where("type", "==", "form")))
       
        return _snap.docs.map((val) => {
            return {id: val.id, ...val.data()} as FlowType
        });
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}

export async function getOrgRequests(formId: string, orgId: string):Promise<{[key:string]: string | number | null | boolean}[]> {
    try {
        const _snap = await getDocs(query(collection(firestore, "clients", orgId, "requests"), where("type", "==", formId)))
       
        return _snap.docs.map((val) => {
            return {id: val.id, ...val.data()} as {[key:string]: string | number | null | boolean}
        });
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }

}

export async function getOrgWidgetConfiguration(internalId: string, orgId:string):Promise<WidgetConfigType | null > {
    try {
        const _snap = await getDocs(query(collection(firestore, "clients", orgId, "widgets"), where("internalId", "==", internalId)));
        if(_snap.docs.length > 0) {
            return {id: _snap.docs[0].id, ..._snap.docs[0].data()} as WidgetConfigType
        }
        else {
            return null;
        }
    }
    catch(err) {
        return null;
    }
}

export async function updateOrgWidgetConfiguration(config: WidgetConfigType, orgId: string):Promise<string> {
    const _config = _.cloneDeep(config);
    try {
        const _id = _config.id as string; 
        delete _config.id;
        await updateDoc(doc(firestore, "clients", orgId, "widgets", _id), _config);
        return _id;
    }
    catch(err) {
        return "";
    }
}


export async function getOrgCRMAttributes(id:string):Promise<CRMAttributeType[]> {
    try {
        const _res = await getDocs(collection(firestore, "clients", id, "attributes"))
        return _res.docs.map((val) => {
            return {id: val.id, ...val.data()} as CRMAttributeType
        });
        
    }
    catch(err) {
        //log error and rethink how to mange the error.. probably should show an error and say the page is down..
        return []
    }
}

export async function addOrgCRMAttribute(attribute:CRMAttributeType, orgId: string):Promise<CRMAttributeType | null> {
    try {
        const _doc = await addDoc(collection(firestore, "clients", orgId, "attributes"), attribute)
        return  {id: _doc.id, ...attribute}
    }
    catch(err) {
        return null;
    }
}

export async function updateOrgCRMAttribute(attribute:CRMAttributeType, orgId: string):Promise<CRMAttributeType | null> {
    const _attribute = _.cloneDeep(attribute);
    const _id= attribute.id;
    if(!_id)
        return null;
    try {
        delete _attribute.id;
        await updateDoc(doc(firestore, "clients", orgId, "attributes", _id), _attribute)
        return  attribute;
    }
    catch(err) {
        return null;
    }
}

export type PaginatedData = {
    position: number,
    directionAfter: boolean,
    limit: number,
    data: {[key:string]: any}[],
    totalCount: number
}

export async function getPaginatedOrgLeads(orgId: string, position=10000000, directionAfter=true, results=10, filter?:{field:string, filter: "==" | "startsWith", value:string}[]):Promise<PaginatedData> {
    const _query = query(collection(firestore, 'clients' ,orgId, 'leads'), orderBy("createdAt", "desc"))
    //const _query = query(collection(firestore, 'clients' ,orgId, 'leads'))
    let _finalQuery = query(_query);
    //let _finalQuery = query(_query, where("email", ">=", "pietro"), where("email", "<", "pietro"+'\uf8ff'));
    const _count = await getCountFromServer(_finalQuery);
    if(position == 0 ) {
        _finalQuery = query(_finalQuery, endBefore(position), limit(results))
    }
    else if(directionAfter) {
        _finalQuery = query(_finalQuery, startAfter(position), limit(results))
    }
    else {
        _finalQuery = query(_finalQuery, endBefore(position), limitToLast(results))
    }
    const _snap = await getDocs(_finalQuery);
    const _data = _snap.docs.map((doc) => {return {id: doc.id, ...doc.data()} as LeadType});

    return {
        position: position,
        directionAfter: directionAfter,
        limit: results,
        data: _data,
        totalCount: _count.data().count
    }
    
}


export async function getOrgSessionHistory(orgId: string, sessionId:string):Promise<ChatHistoryElementType[]> {
    const snap = await getDocs(query(collection(firestore, "clients", orgId, "sessions", sessionId, "history"), orderBy("createdAt", "asc")));
    const results = snap.docs.map((val) => {
        return {id: val.id, ...val.data()} as ChatHistoryElementType
    })

    return results;
}

export async function getOrgSessions(orgId:string, position?:number, limit2=60):Promise<ChatSessionType[]> {
    const _query = query(collection(firestore, 'clients' , orgId, 'sessions'), orderBy("lastActivity", "desc"), )
    //const _query = query(collection(firestore, 'clients' , orgId, 'sessions'));
    let _finalQuery = query(_query, limit(limit2))
    //let _finalQuery = _query;
    /**if(position)
        _finalQuery = query(_finalQuery, startAfter(position));**/
    const _snap = await getDocs(_finalQuery);
    const _data = _snap.docs.map((doc) => {return {id: doc.id, ...doc.data()} as ChatSessionType});
    _data.sort((a,b) => b.lastActivity - a.lastActivity)
    return _data;
}

export async function getOrgSessionsExtended(orgId: string, position=1000000000000, limit=35):Promise<ExtendedChatSessionType[]> {
    const _sessions = await getOrgSessions(orgId, position, limit);

    const _extendedSessionPromises = _sessions.map(async (val) => {
        const user = {
            id: "unkown",
            name: "Unkown Visitor"
        }
        const _userQuery = await getDocs(query(collection(firestore, "clients", orgId, "leads"), where('deviceIds', 'array-contains', val.deviceId)));
        if(!_userQuery.empty) {
            user.id = _userQuery.docs[0].id,
            user.name = (_userQuery.docs[0].data().firstName || "") + " " + (_userQuery.docs[0].data().lastName || "");
            if(user.name == " ")
                user.name = _userQuery.docs[0].data().email
        }
        const _lastMessage = await getDocs(query(collection(firestore, "clients", orgId, "sessions", val.id, "history"), where("from", "in", ["user", "assistant"]), orderBy("createdAt", "asc"), limitToLast(1)))
        let lastMessage = "";
        if(!_lastMessage.empty) {
            lastMessage = _lastMessage.docs[0].data().params.message || "";
        }
        return {
            id: val.id,
            session: val,
            lead: user,
            lastMessage: lastMessage
        } as ExtendedChatSessionType
    });
    const _results = await Promise.all(_extendedSessionPromises)
    return _results;
}


export async function getOrgSessionLead(orgId: string, sessionId:string, deviceId: string):Promise<LeadType | null> {
    const _userQuery = await getDocs(query(collection(firestore, "clients", orgId, "leads"), where('deviceIds', 'array-contains', deviceId)));
    if(!_userQuery.empty) {
        const firstLead = _userQuery.docs[0];
        return {id: firstLead.id, ...firstLead.data()} as LeadType
    }
    return null
}