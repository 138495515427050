import { Group, Code, ScrollArea, rem, Image, Stack, ActionIcon, Box, Select, Button } from '@mantine/core';
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconLayoutSidebarLeftCollapse,
  IconMessage2,
  IconBook2,
  IconUsersPlus,
} from '@tabler/icons-react';
import Logo from '../../../assets/unichats_logo.png'
//import { UserButton } from '../UserButton/UserButton';
import { LinksGroup } from './LinkGroup';
import classes from './MenuBar.module.scss';
import { ConsoleContext, ContextType } from '../../../context/ConsoleContext';
import { useContext, useEffect, useState } from 'react';

const mockdata = [
    { label: 'Dashboard', icon: IconGauge, link: '/overview' },
    {
      label: 'Conversations',
      icon: IconMessage2,
      initiallyOpened: true,
      links: [
        { label: 'Scripted', link: '/conversations/flows' },
        { label: 'Dynamic Prompts', link: '/conversations/prompts' },
        { label: 'Forms', link: '/conversations/forms' },
        { label: 'Fields', link: '/conversations/fields' },
        { label: 'Theme', link: '/conversations/theme' },
      ],
    },
    {
      label: 'Data',
      icon: IconBook2,
      links: [
        { label: 'Programs', link: '/data/programs' },
        { label: 'Knowledge', link: '/data/knowledge' },
      ],
    },
    {
        label: 'Leads',
        icon: IconUsersPlus,
        link: '/leads'
    },
    {
        label: 'Analytics',
        icon: IconPresentationAnalytics,
        links: [
          { label: 'Conversations', link: '/analytics/conversations' },
          { label: 'Usage', link: '/analytics/usage' },
          { label: 'Scripted', link: '/analytics/scripted' },
        ],
    },
    { label: 'Settings', 
        icon: IconAdjustments,
        links: [
            { label: 'Integrations', link: '/settings/integrations' },
            { label: 'Installation', link: '/settings/installation' },
          ],
    }
  ];

export default function MenuBar() {
    const [links, setLinks ] = useState<any[]>([])
    const { orgs, selected, setSelected, logOut, token } = useContext(ConsoleContext) as ContextType;

    useEffect(() => {
        setLinks(mockdata.map((item) => <LinksGroup clientId={selected?.id || ""} {...item} key={item.label} />));
    }, [,selected])

    /**async function createClient() {
      fetch('https://api-dsma3ukr6q-ew.a.run.app/api/v2/client/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify({
          name: "Jojo Demo School",
        }),
      })
      .then(response => response.json())
      .then(data => console.log('Success:', data))
      .catch(error => console.error('Error:', error));
    }**/

    return (
        <nav className={classes.navbar}>
            <div className={classes.header}>
                <Stack h={"100%"} p={15} justify='center'>
                    <Group justify='space-between'>
                        <Image src={Logo} maw={120}/>
                        <ActionIcon disabled={true} variant="transparent">
                            <IconLayoutSidebarLeftCollapse style={{ width: '90%', height: '90%' }} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Stack>
            </div>
            <Box className={classes.clientSelector}>
                <Select
                    checkIconPosition="right"
                    allowDeselect={false}
                    classNames={{input: classes.clientSelect, wrapper: classes.clientSelectWrapper, root: classes.clientSelectInputWrapper}}
                    placeholder="Pick value"
                    data={orgs.map((val) => { return {label: val.name as string, value: val.id as string}})}
                    onChange={(value) => { setSelected(value || "") }}
                    value={selected?.id || null}
                />
            </Box>


      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}>{links}</div>
      </ScrollArea>

      <div className={classes.footer}>
        {/**<Button onClick={createClient} >Create Client</Button>**/}
        {/**<UserButton />**/}
      </div>
    </nav>
    )
}