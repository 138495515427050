import styles from './actions.module.scss'
import { Edge, Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";

export type SelectedProgramActionType = {
    content: string[]
}

type ProgramValue = {
    id: string,
    name: string
}


export const SelectedProgramsActionSchema = [
    {
        target: "content",
        label: "Programs",
        type: "multiple_select",
        optionType: "program"
    }
]

export const SelectedProgramsActionDefault = {
    content: []
}

type ActionNodeProps = {
    id: string,
    action: Object,
    isConnectable: boolean,
    edges: Edge[]
}

export function SelectedProgramsActionNode({id, action, isConnectable, edges}:ActionNodeProps) {
    const _action = (action as SelectedProgramActionType);

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Selected Programs</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
            <Text># Programs: {_action.content.length}</Text>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}

