import Logo from '../assets/unify_logo.png';

import { useContext, ReactNode} from 'react';
import { Group, ActionIcon, Title, Container, MenuStylesNames } from '@mantine/core';

import { IconLayoutSidebarLeftExpand, IconLayoutSidebarLeftCollapse } from "@tabler/icons-react"

import { ConsoleContext, ContextType  } from '../context/ConsoleContext';

type MenuBarComponentProps = {
    title: string,
    children: ReactNode
}

export function MenuBarComponent({title, children}:MenuBarComponentProps) {

    const {opened, toggle } = useContext(ConsoleContext) as ContextType
    
    return (
        <Container size={"xl"} py={"lg"} w={"100%"}>
            <Group h="100%" justify="space-between" px="md" mah={36} mih={36}>
                <Group  h="100%" >
                    <ActionIcon variant="transparent" onClick={toggle} c={"black"} aria-label="Nav Bar Open/Close">
                        {opened? (
                            <IconLayoutSidebarLeftExpand size={20}/>
                        ):(
                            <IconLayoutSidebarLeftCollapse size={20}/>
                        )}
                    </ActionIcon>
                <Group>
                    {/**<Image src={Logo} height={36} radius="sm"/>
                    <Logo style={{ width: rem(120) }} />
                    <Code fw={700}>v0.0.1</Code>**/}
                    <Title order={2}>{title}</Title>
                </Group>
                </Group>
                {children}
            </Group>
        </Container>
    )
}