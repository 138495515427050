// schemas/programSchema.ts
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';

const MonthEnum = z.enum([
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]);

const locationSchema = z.object({
    city: z.string(),
    isoCode: z.string().optional(),
    countryCode: z.string(),
    country: z.string(),
    stateCode: z.string(),
    state: z.string()
})

export const campusSchema = z.object({
    id: z.string().default(uuidv4).optional(),
    name: z.string().min(3, 'Name must be at least 3 characters long'),
    location: locationSchema,
    cover: z.string().optional()
})

export const studyOptionSchema = z.object({
    id: z.string().default(uuidv4).optional(),
    schedule: z.enum(['full-time', 'part-time']).default('full-time'),
    delivery: z.enum(['in-person', 'online', 'blended']).default('in-person'),
    duration: z.number().default(12),
    durationType: z.enum(['days', 'weeks', 'months', 'years']).default('months'),
    language: z.enum(['english', 'italian', 'spanish']).default('english'),
    intakes: z.array(MonthEnum).default([]),
    campus: campusSchema
})

const tuitionSchema = z.object({
    amount: z.number().default(10000),
    currency: z.enum(['EUR', 'USD', 'CAD', 'GBP']).default('USD'),
    type: z.enum(['national', 'international', 'all']).default('all'),
    period: z.enum(['credit', 'month', 'semester', 'year', 'full']).default('year'),
    description: z.string().optional()
})

export const subjectSchema = z.object({
    id: z.string().default(uuidv4).optional(),
    name: z.string().min(3, 'Name must be at least 3 characters long'),
    url: z.string().optional(),
    status: z.enum(['published', 'archived']).default('published')
});

const degreeTypeEnum = z.enum([
    "bachelor", 
    "master", 
    "mba", 
    "phd", 
    "diploma", 
    "associate_degree", 
    "major", 
    "executive_master", 
    "dba", 
    "executive_program", 
    "executive_course", 
    "executive_mba", 
    "online_course"
]);

export const programSchema = z.object({
    id: z.string().optional(),
    cover: z.string().optional(),
    internalId: z.string().optional(),
    url: z.string().optional(),
    name: z.string().min(2, 'Name must be at least 2 characters long').default("New Program"),
    internalName: z.string().optional(),
    status: z.enum(['draft', 'published', 'archived']).default('draft'),
    overview: z.string().optional(),
    credits: z.number().optional(),
    degreeType: degreeTypeEnum.default('bachelor'),
    studyOptions: z.array(studyOptionSchema).default([]),
    subjects: z.array(subjectSchema).default([]),
    tuition: z.array(tuitionSchema).default([]),
    brochure: z.string().optional()
})


// TypeScript type derived from the Zod schema
export type ProgramType = z.infer<typeof programSchema>;