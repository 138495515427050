import { StringIterator } from "lodash"
import { FlowType } from "./@type.flow"
import { KnowledgeType } from "./@type.knowledge"
import { StringFormat } from "firebase/storage"

export type AssistantType = {
    name: string,
    greeting: string,
    avatar: string,
    messages: string[]
}

const defaultAssistant:AssistantType = {
    name: "",
    greeting: "",
    avatar: "",
    messages:[]
}
export function newAssistant():AssistantType {
    return {...defaultAssistant}
}

 // "url_exact" | "url_contains" | "url_starts" | "url_ends"
export type PromptType = {
    priority: number,
    type: "default" | "conditional",
    condition?: {
        type: string ,
        match: string
    },
    content: string,
    actions: {
        label: string,
        type: "flow" | "ambassador"
        actionId: string 
    }[]
}

export type ActionType = {
    label: string,
    type: "flow" | "link" | "other",
    actionId: string
}

export type WidgetConfigType = {
    id?: string,
    name: string,
    internalId: string,
    status: "inactive" | "active",
    category: string,
    params: {[key: string]: string | number | boolean | ActionType[]}
}

const defaultPrompt:PromptType = {
    priority: 0,
    type: "conditional",
    content: "How can I help?",
    actions: []
}

export function newPrompt(type?:"default" | "conditional"):PromptType {
    const condition:{
        type: string,
        match: string
    } = {
        type: "url_contains",
        match: ""
    }
    return {...defaultPrompt, type: type || "conditional", condition: condition}
}
export type ThemeType = {
    name: string,
    value: string
}

export type ClientType = {
    id?: string
    name: string
    flows?: FlowType[]
    knowledge?: KnowledgeType[]
    assistant: AssistantType,
    prompt?: PromptType,
    prompts?: PromptType[],
    theme?: ThemeType[],
    defaultMessages?: {
        API_ERROR?: string,
        FLOW_ERROR?:  string,
        TEXT_ON_TRIGGER_DOUBT?: string,
        TEXT_ON_TRIGGER_DOUBT_FORCE_AI?: string,
        TEXT_ON_TRIGGER_NO_MATCH?: string
    },
    language?: string
}

export type CRMAttributeType = {
    id?: string,
    label: string,
    locked: boolean,
    type: string,
    target: string,
    status:string
}

const defaultCRMAttribute:CRMAttributeType = {
    label: "",
    locked: false,
    type: "string",
    target: "lead",
    status: "active"
}

export function newAttribute():CRMAttributeType {
    return {...defaultCRMAttribute}
} 

export type CRMCategoryType = {
    id?: string,
    label: string,
    attributes: [CRMAttributeType]
}


export type FormType = {
    id?: string,
    name: string,
    status: string
}

const defaultForm: FormType = {
    name: "",
    status: "active"
}

export function newForm():FormType {
    return {...defaultForm}
}