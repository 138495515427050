// hooks/useFirestoreCollection.ts
import { useEffect, useState, useMemo, useContext } from 'react';
import { getCollection, getDocument, addDocument, updateDocument, deleteDocument } from '../services/firestoreService';
import { ZodSchema, ZodError } from 'zod';
import { DocumentSnapshot } from 'firebase/firestore';
import { ConsoleContext, ContextType } from '../context/ConsoleContext';

interface FirestoreDocument {
  id?: string;
  [key: string]: any;
}

/**
 * Custom hook to manage Firestore collections with schema validation and pagination.
 * 
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {ZodSchema<T>} schema - The Zod schema for validating documents in the collection.
 * @returns {object} An object containing functions and state for managing the Firestore collection.
 */
export const useFirestoreCollection = <T extends FirestoreDocument>(collectionName: string, schema: ZodSchema<T>) => {
  const { selected } = useContext(ConsoleContext) as ContextType
  const [documents, setDocuments] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [singleDoc, setSingleDoc] = useState<T | null>(null);
  const [singleDocLoading, setSingleDocLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [prevPages, setPrevPages] = useState<DocumentSnapshot[]>([]);

  // Fetch documents when the clientId or page changes
  useEffect(() => {
    if (selected) {
      fetchDocuments();
    }
  }, [selected, page]);


/**
   * Fetches a collection from Firestore with pagination.
   * 
   * @param {number} [limitCount=10] - The number of documents to fetch.
   * @returns {Promise<void>}
   */
const fetchDocuments = async (limitCount: number = 10) => {
    if (selected) {
      setLoading(true);
      try {
        let snapshot;
        if (page === 1) {
          snapshot = await getCollection(selected.id as string, collectionName, limitCount);
        } else if (page > 1 && prevPages.length > 0) {
          snapshot = await getCollection(selected.id as string, collectionName, limitCount, prevPages[page - 2]);
        }

        if (snapshot) {
          const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
          setDocuments(docs);

          if (page === 1) {
            setPrevPages([snapshot.docs[0]]);
          } else if (snapshot.docs.length > 0) {
            setPrevPages(prev => {
              const newPrev = [...prev];
              newPrev[page - 1] = snapshot.docs[0];
              return newPrev;
            });
          }
        }
      } catch (error) {
        console.error('Error fetching collection:', error);
      } finally {
        setLoading(false);
      }
    }
};

  /**
   * Fetches a single document from Firestore.
   * 
   * @param {string} docId - The ID of the document to fetch.
   * @returns {Promise<void>}
   */
  const fetchDocument = async (docId: string) => {
    if (selected) {
      setSingleDocLoading(true);
      try {
        const docSnap = await getDocument(selected.id as string, collectionName, docId);
        if (docSnap.exists()) {
          setSingleDoc({ id: docSnap.id, ...docSnap.data() } as T);
        } else {
          throw new Error('Document not found');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setSingleDocLoading(false);
      }
    }
  };

  /**
   * Adds a new document to the Firestore collection.
   * 
   * @param {T} data - The data of the new document.
   * @returns {Promise<void>}
   */
  const addDoc = async (data: T) => {
    if (selected) {
      try {
        const parsedData = schema.parse(data);
        const doc = await addDocument(selected.id as string, collectionName, parsedData);
        fetchDocuments(); // Refetch collection
        return doc.id;
      } catch (error) {
        if (error instanceof ZodError) {
          console.error('Validation error:', error.errors);
        } else {
          console.error('Error adding document:', error);
        }
        return null;
      }
    }
  };

  /**
   * Updates an existing document in the Firestore collection.
   * 
   * @param {string} docId - The ID of the document to update.
   * @param {T} data - The new data for the document.
   * @returns {Promise<void>}
   */
  const updateDoc = async (docId: string, data: T) => {
    if (selected) {
      try {
        const parsedData = schema.parse(data);
        await updateDocument(selected.id as string, collectionName, docId, parsedData);
        if (singleDoc && singleDoc.id === docId) {
          setSingleDoc({ ...singleDoc, ...data });
        }
        if(documents.length > 0) {
          fetchDocuments(); // Refetch collection if there is a collection
        }
      } catch (error) {
        if (error instanceof ZodError) {
          console.error('Validation error:', error.errors);
        } else {
          console.error('Error updating document:', error);
        }
      }
    }
  };

  /**
   * Deletes a document from the Firestore collection.
   * 
   * @param {string} docId - The ID of the document to delete.
   * @returns {Promise<void>}
   */
  const deleteDoc = async (docId: string) => {
    if (selected) {
      try {
        await deleteDocument(selected.id as string, collectionName, docId);
        setDocuments(docs => docs.filter(doc => doc.id !== docId));
        if (singleDoc && singleDoc.id === docId) {
          setSingleDoc(null);
        }
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const contextValue = useMemo(() => ({
    documents,
    loading,
    addDoc,
    updateDoc,
    deleteDoc,
    fetchDocument,
    fetchDocuments,
    singleDoc,
    singleDocLoading,
    setPage,
    nextPage: () => setPage(prev => prev + 1),
    prevPage: () => setPage(prev => (prev > 1 ? prev - 1 : 1)),
    page,
  }), [
    documents,
    selected,
    loading,
    singleDoc,
    singleDocLoading,
    page,
  ]);

  return contextValue;
};
