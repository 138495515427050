// hooks/useFirestoreCollection.ts
import { useEffect, useState, useMemo } from 'react';
import { ZodSchema, ZodError } from 'zod';
import { DocumentSnapshot, OrderByDirection } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { getClient, getCollection, getDocument, addDocument, updateDocument, deleteDocument, getCollectionCount } from '../services/firestore';

interface FirestoreDocument {
  id?: string;
  snapshot?: DocumentSnapshot,
  [key: string]: any;
}

/**
 * Custom hook to manage Firestore collections with schema validation and pagination.
 * 
 * @param {string} collectionName - The name of the Firestore collection.
 * @param {ZodSchema<T>} schema - The Zod schema for validating documents in the collection.
 * @param {DocumentSnapshot} snap - Optional Snapshot that will be user as the collection root.
 * @returns {object} An object containing functions and state for managing the Firestore collection.
 */
export const useFirestoreCollection = <T extends FirestoreDocument>(collectionName: string, schema: ZodSchema<T>, snap?: DocumentSnapshot) => {
    const clientId = useParams().clientId;
    const [root, setRoot] = useState<DocumentSnapshot | null>(snap?snap:null);
    const [documents, setDocuments] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [singleDoc, setSingleDoc] = useState<T | null>(null);
    const [singleDocLoading, setSingleDocLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [orderBy, setOrderBy] = useState<[string, OrderByDirection] | null>(null); 
    const [prevPages, setPrevPages] = useState<DocumentSnapshot[]>([]);
    const [documentCount, setDocumentCount] = useState<number | null>(null); 


    // Fetch documents when the clientId or page changes
    useEffect(() => {
        fetchDocuments(pageSize, orderBy?orderBy:undefined);
    }, [page]);


    /**
    * Fetches the Client Snapshot
    * @returns {Promise<DocumentSnapshot>}
    */
    const fetchClient = async () => {
        if(clientId) {
            const client = await getClient(clientId);
            if(client.exists()) {
                setRoot(client);
                return client;
            }
            else {
                throw Error(`No Client for the ID ${clientId}`);
            }
        }
        else {
            throw Error("Client Id is missing")
        }
    }

/**
   * Fetches a collection from Firestore with pagination.
   * 
   * @param {number} [limitCount=10] - The number of documents to fetch.
   * @returns {Promise<void>}
   */
    const fetchDocuments = async (limitCount: number = 10, order?:[string, OrderByDirection]) => {
        try {
            setPageSize(limitCount)
            if(order)
                setOrderBy(order)
            setLoading(true);
            let r: DocumentSnapshot;
            if(root) {
                r = root;
            }
            else {
                r = await fetchClient();
            }
        
            let snapshot;
            if (page === 1) {
            snapshot = await getCollection(r, collectionName, limitCount, undefined, order );
            } else if (page > 1 && prevPages.length > 0) {
            snapshot = await getCollection(r, collectionName, limitCount, prevPages[page - 2], order);
            }

            if (snapshot) {
                const docs = snapshot.docs.map(doc => ({ id: doc.id, snapshot: doc, ...doc.data()  } as T));
                setDocuments(docs);

                if (page === 1) {
                    setPrevPages([snapshot.docs[snapshot.docs.length - 1]]);
                } else if (snapshot.docs.length > 0) {
                    setPrevPages(prev => {
                    const newPrev = [...prev];
                    newPrev[page - 1] = snapshot.docs[snapshot.docs.length - 1];
                    return newPrev;
                    });
                }
                const count = await getCollectionCount(r, collectionName);
                setDocumentCount(count);
            }
        } catch (error) {
            console.error('Error fetching collection:', error);
        } finally {
            setLoading(false);
        }
};

  /**
   * Fetches a single document from Firestore.
   * 
   * @param {string} docId - The ID of the document to fetch.
   * @returns {Promise<void>}
   */
  const fetchDocument = async (docId: string) => {
    try {
        setSingleDocLoading(true);
        let r: DocumentSnapshot;
        if(root) {
            r = root;
        }
        else {
            r = await fetchClient();
        }
      
      
        const docSnap = await getDocument(r, collectionName, docId);
        if (docSnap.exists()) {
          setSingleDoc({ id: docSnap.id, snapshot:docSnap, ...docSnap.data() } as T);
        } else {
          throw new Error('Document not found');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setSingleDocLoading(false);
      }
    
  };

  /**
   * Adds a new document to the Firestore collection.
   * 
   * @param {T} data - The data of the new document.
   * @returns {Promise<string | null>} - Returns the ID of the new Doc when successfully added or null if an error occured.
   */
  const addDoc = async (data: T):Promise<string | null> => {
      Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});
      try {
        let r: DocumentSnapshot;
        if(root) {
            r = root;
        }
        else {
            r = await fetchClient();
        }
        const parsedData = schema.parse(data);
        const doc = await addDocument(r, collectionName, parsedData);
        fetchDocuments(pageSize); // Refetch collection
        return doc.id;
      } catch (error) {
        if (error instanceof ZodError) {
          console.error('Validation error:', error.errors);
        } else {
          console.error('Error adding document:', error);
        }
        return null;
      }
    
  };

  /**
   * Updates an existing document in the Firestore collection.
   * 
   * @param {string} docId - The ID of the document to update.
   * @param {T} data - The new data for the document.
   * @returns {Promise<boolean>} - Returns true when the update was successful and false when an error occured.
   */
  const updateDoc = async (docId: string, data: T):Promise<boolean> => {
      try {
        let r: DocumentSnapshot;
        if(root) {
            r = root;
        }
        else {
            r = await fetchClient();
        }
        const parsedData = schema.parse(data);
        console.log(docId, parsedData);
        await updateDocument(r, collectionName, docId, parsedData);
        if (singleDoc && singleDoc.id === docId) {
          setSingleDoc({ ...singleDoc, ...data });
        }
        if(documents.length > 0) {
          fetchDocuments(pageSize); // Refetch collection if there is a collection
        }
        return true;
      } catch (error) {
        if (error instanceof ZodError) {
          console.error('Validation error:', error.errors);
        } else {
          console.error('Error updating document:', error);
        }
        return false;
      }
    
  };

  /**
   * Deletes a document from the Firestore collection.
   * 
   * @param {string} docId - The ID of the document to delete.
   * @returns {Promise<boolean>} - Returns true when the update was successful and false when an error occured.
   */
  const deleteDoc = async (docId: string):Promise<boolean> => {

      try {
        let r: DocumentSnapshot;
        if(root) {
            r = root;
        }
        else {
            r = await fetchClient();
        }
        await deleteDocument(r, collectionName, docId);
        setDocuments(docs => docs.filter(doc => doc.id !== docId));
        if (singleDoc && singleDoc.id === docId) {
          setSingleDoc(null);
        }
        return true;
      } catch (error) {
        console.error('Error deleting document:', error);
        return false;
      }
    
  };

  const contextValue = useMemo(() => ({
    documents,
    loading,
    addDoc,
    updateDoc,
    deleteDoc,
    fetchDocument,
    fetchDocuments,
    documentCount,
    singleDoc,
    singleDocLoading, 
    setPage,
    nextPage: () => setPage(prev => prev + 1),
    prevPage: () => setPage(prev => (prev > 1 ? prev - 1 : 1)),
    page,
  }), [
    documents,
    loading,
    singleDoc,
    singleDocLoading,
    page,
    documentCount
  ]);

  return contextValue;
};
