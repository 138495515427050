import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar, Badge } from '@mantine/core';
import { IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";
import { ActionNodeProps } from '../nodes/ChatNode';


export type InputActionType = {
    content: string,
    type: "open_text" | "email" | "phone_number",
    field_name:string,
    placeholder: string
}


export const InputActionSchema = [
    {
        target: "content",
        label: "Messsage",
        type: "rich_text",
    },
    {
        target: "placeholder",
        label: "Placeholder",
        type: "text",
    },
    {
        target: "type",
        label: "Type",
        type: "segment",
        options: [
            {
                label: "Text Input",
                value: "open_text"
            },
            {
                label: "Email Input",
                value: "email"
            },
            {
                label: "Phone Input",
                value: "phone_number"
            }
        ]
    },
    {
        target: "attribute",
        label: "Save to",
        type: "data_select",
        option: "attributes"
    }
]

export const InputActionDefault = {
    content: "Please fill the following",
    type: "open_text",
    field_name: "form_field",
    placeholder: "placeholder text"
}

const TYPE_LABELS = {
    open_text: "Text",
    email: "Email",
    phone_number: "Phone"
}

export function InputActionNode({id, action, isConnectable, edges}:ActionNodeProps) {

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Message</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
                <Paper  shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                    <div dangerouslySetInnerHTML={{__html: action.content}}></div>
                </Paper>
                <Paper  shadow={"xs"} p={"xs"} py={"xs"} bg={"white"} withBorder>
                    <Group><Badge color={"gray"}>{TYPE_LABELS[action.type]}</Badge> <Text c="dimmed">{action.placeholder}</Text></Group>
                </Paper>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}
