import { z } from "zod";
import { BaseSchema } from "./Base";


export const LeadSchema = BaseSchema.extend({
    email: z.string(),
    phoneNumber: z.string(),
    firstName: z.string(),
    lastName: z.string()
}).catchall(z.any());

// TypeScript type inferred from the schema
export type LeadModelType = z.infer<typeof LeadSchema>;


