import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";

export type MessageActionType = {
    content: MessageValue[]
}

type MessageValue = {
    value: string
}

const MessageValueDefault = {
    value: "Some message"
}
export const MessageActionSchema = [
    {
        target: "content",
        label: "Messages",
        type: "multiple",
        multiple_label: "Message",
        schema: [
            {
                target: "value",
                label: "Content",
                type: "rich_text",
            }
        ],
        default: MessageValueDefault,
        add: "Add Message"
    }
]

export const MessageActionDefault = {
    content: []
}



export function MessageActionNode({id, action, isConnectable, edges}) {

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Message</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
            {action.content.map((val:string, index:number) => {
                return (
                    <Paper key={index} shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                        <div dangerouslySetInnerHTML={{__html: val.value}}></div>
                    </Paper>
                )
            })}
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}

