import { Table, Title, Text, Flex, Badge, Anchor, Box, Group, Button, Drawer } from "@mantine/core"
import { CRMAttributeType, newAttribute } from "../../../models/@type.client"

import styles from '../../dashboard.module.scss'
import { useEffect, useState } from "react"
import { CRMAttributeEdit } from "./_CRMAttributeEdit"
import { addOrgCRMAttribute, getOrgCRMAttributes, updateOrgCRMAttribute } from "../../../services/database"

type CRMAttributeConfigurationComponentProps = {
    data: CRMAttributeType[],
    orgId: string,
    onChange(attributes: CRMAttributeType[]): void
}

export function CRMAttributeConfigurationComponent({orgId, onChange}:CRMAttributeConfigurationComponentProps) {

    const [attributeEdit, setAttributeEdit] = useState<CRMAttributeType | null>(null)
    const [ data, setData ] = useState<CRMAttributeType[]>([])

    useEffect(() => {
        if(orgId)
            getData();
    }, [orgId])

    function addAttribute() {
        const _attr = newAttribute();
        setAttributeEdit(_attr);
    }

    async function getData() {
        const _attrs = await getOrgCRMAttributes(orgId);
        setData(_attrs);
    }

    async function onAttributeSave(attribute:CRMAttributeType) {

        const _response = attribute.id? await updateOrgCRMAttribute(attribute, orgId):await addOrgCRMAttribute(attribute, orgId);
        if(_response) {
            setAttributeEdit(null);
            getData();
        }
     
    }

    return (
        <>
        <Drawer withCloseButton={false} opened={attributeEdit?true:false} position="right" size={"lg"} onClose={() => {}}>
            {attributeEdit && <CRMAttributeEdit attribute={attributeEdit} onSave={onAttributeSave} onClose={() => {setAttributeEdit(null)}}/>}
        </Drawer>
        <Box px={"lg"}>
            <Group justify="space-between"><Title  px={0} mb={"xs"} c={"gray.8"} order={5}>Attributes Configuration</Title> <Button size={"xs"} onClick={addAttribute}>Add Attribute</Button></Group>
            <Table mt={"lg"}  classNames={{table: styles.table}}>
                <Table.Thead className={styles.tableHead} bg={"gray.1"}>
                        <Table.Tr>
                            <Table.Th><Text fw={"bold"} size={"xs"}>Id</Text></Table.Th>
                            <Table.Th><Text fw={"bold"} size={"xs"}>Label</Text></Table.Th>
                            <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                            <Table.Th><Text fw={"bold"} size={"xs"}>Locked</Text></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {data.map((element) => {
                            return (
                                <Table.Tr  key={element.id} bg={"gray.0"}>
                                    <Table.Td maw={100} align="left">{element.id}</Table.Td>
                                    <Table.Td><Flex mih={40} pl={"md"} align={"center"}><Anchor size="sm" fw="bold" onClick={() => setAttributeEdit(element)}>{element.label}</Anchor></Flex></Table.Td>
                                    <Table.Td><Flex mih={40} align={"center"}><Badge variant={"dot"} color={`${element.type== "lead"? "blue":"gray"}`}>{element.type}</Badge></Flex></Table.Td>
                                </Table.Tr>
                                )
                            })}
                    </Table.Tbody>
                </Table>
        </Box>
        </>
    )

}