import { Button, Container, Flex, Group, Paper, Stack, Tabs, Text, TextInput, Title, Select, ActionIcon, darken, lighten, ColorInput } from "@mantine/core"
import { MenuBarComponent } from "../../components/MenuBar"
import { IconDeviceFloppy, IconTrash } from "@tabler/icons-react"
import { useContext, useEffect, useState } from "react"
import { ConsoleContext, ContextType } from "../../context/ConsoleContext"
import _ from 'lodash';


import { getOrgFlows, getOrgWidgetConfiguration, getUserOrg, updateOrgWidgetConfiguration, updateUserOrg } from "../../services/database"
import { ImageFieldComponent } from "../../components/ImageField"
import { RichTextInput } from "../../components/RichTextInput"
import { AssistantPreviewComponent } from "./settings/AsisstantPreview"
import styles from './config.module.scss'
import { ActionType, AssistantType, PromptType, ThemeType, WidgetConfigType, newAssistant, newPrompt } from "../../models/@type.client"
import { FlowType } from "../../models/@type.flow"

import { EditPrompt } from "./settings/EditPrompt"


export function BotConfigurationPage() {
    
    const { selected } = useContext(ConsoleContext) as ContextType

    const [activeTab, setActiveTab] = useState<string | null>("assistant");
    const [init, setInit] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);

    const [assistant, setAssistant] = useState<AssistantType | null>(null);
    const [prompt, setPrompt] = useState<PromptType | null>(null);
    const [prompts, setPrompts] = useState<PromptType[]>([])
    const [theme, setTheme] = useState<ThemeType[]>([])

    const [programWidget, setProgramWidget] = useState<WidgetConfigType | null> (null);

    const [flows, setFlows] = useState<FlowType[]>([]);

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        getData()
    }, [selected])

    useEffect(() => {
        if(init)
            setChanged(true);
    }, [assistant, prompt, prompts, programWidget]);

    async function getData() {
        const _client = await getUserOrg(selected?.id || "");
        setAssistant(_client?.assistant? _client.assistant:newAssistant());
        setPrompt(_client?.prompt || newPrompt());
        setPrompts(_client?.prompts || []);
        setTheme(_client?.theme || []);
        const _flows = await getOrgFlows(selected?.id || "");
        setFlows(_flows)
        const _programWidget = await getOrgWidgetConfiguration("program_widget", selected?.id || "");
        setProgramWidget(_programWidget)
        setInit(true);
    }

    function onThemeColorChange(value:string) {
        if(value) {
            const _light = lighten(value, 0.9);
            const _dark = darken(value, 0.24);
            const _theme = _.cloneDeep(theme);
            ['--ua-color-primary', '--ua-color-primary-gradient-dark', '--ua-color-primary-light'].map((name, i) => {
                let index = _theme.findIndex((v) => v.name == name);
                if(index > -1) {
                    _theme.splice(index, 1);
                }
                _theme.push({
                    name: name,
                    value: i == 0? value:(i == 1? _dark:_light)
                })
            })
            setTheme(_theme);
        }
    }

    function getThemeColor():string {
        let _color = "#297DF2"
        theme.map((val) => {
            if(val.name == '--ua-color-primary')
                _color = val.value
        })
        return _color
    }

    async function onSave() {
        const _res = await updateUserOrg(selected?.id || "", {assistant:assistant as AssistantType, prompt: prompt as PromptType, prompts: prompts, theme: theme})
        if(programWidget) {
            const _res2 = await updateOrgWidgetConfiguration(programWidget as WidgetConfigType, selected?.id || "");
        }
        
        if(_res) {
            setChanged(false)
        }
        else {
            //throw error
        }
    }

    return (
        <>
            <MenuBarComponent title={"Chat Bot Configuration"}>
                <Group>
                    <Button disabled={!changed} onClick={onSave} leftSection={<IconDeviceFloppy size={14}/>}>Save</Button>
                </Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <Tabs defaultValue={"assistant"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value={"assistant"}>
                            <Text size={"md"} fw={`${activeTab == "assistant"? "bold":"normal"}`} c={`${activeTab == "assistant"? "blue.7":"gray.7"}`}>Assistant</Text>
                        </Tabs.Tab>
                        <Tabs.Tab value={"init_prompt"}>
                            <Text size={"md"} fw={`${activeTab == "init_prompt"? "bold":"normal"}`} c={`${activeTab == "init_prompt"? "blue.7":"gray.7"}`}>Initial Prompts</Text>
                        </Tabs.Tab>
                        <Tabs.Tab value={"program_widget"}>
                            <Text size={"md"} fw={`${activeTab == "program_widget"? "bold":"normal"}`} c={`${activeTab == "program_widget"? "blue.7":"gray.7"}`}>Program Widget</Text>
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="assistant">
                        <Paper mt={"lg"} bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                        <Title pl="lg" mb={"xs"} c={"gray.8"} order={5}>Assistant Configuration</Title>
                        {assistant && (
                            <Flex>
                                <Stack className={styles.formFieldSet} p={"md"}> 
                                    <ColorInput
                                        classNames={{
                                            label: styles.inputLabel,
                                            input: styles.input
                                        }}
                                        label="Theme Color"
                                        value={getThemeColor()}
                                        onChange={onThemeColorChange}
                                    />
                                    <TextInput
                                        classNames={{
                                            label: styles.inputLabel,
                                            input: styles.input
                                        }}
                                        label="Name*"
                                        size="md"
                                        placeholder="Assistant Name"
                                        value={assistant?.name as string}
                                        onChange={(e) => {setAssistant({...assistant, name: e.target.value})}}
                                        />
                                    <TextInput
                                        classNames={{
                                            label: styles.inputLabel,
                                            input: styles.input
                                        }}
                                        label="Greeting*"
                                        size="md"
                                        placeholder="How can I help you?"
                                        value={assistant?.greeting as string}
                                        onChange={(e) => {setAssistant({...assistant, greeting: e.target.value})}}
                                        />
                                        <div>
                                            <label className={styles.inputLabel}>Overview</label>
                                            <RichTextInput
                                                className={styles.input}
                                                id={`assistant-message`}
                                                value={(assistant.messages || [])[0] || ""}
                                                onChange={(value:string) => {setAssistant({...assistant, messages:[value]})}}
                                            />
                                        </div>
                                        <div>
                                            <label className={styles.inputLabel}>Avatar Image</label>
                                            <ImageFieldComponent 
                                                id={`assistant-avatar`}
                                                value={assistant.avatar || ""}
                                                onChange={(value) => {setAssistant({...assistant, avatar: value})}}
                                                namespace="assistant"
                                                clientId={selected?.id || ""}
                                                placeholder="Avatar Image"
                                            />
                                        </div>
                                </Stack>
                                <Paper className={styles.formPreview} p={"md"} bg={"transparent"} >
                                    <AssistantPreviewComponent assistant={assistant} theme={theme}/>
                                </Paper>
                            </Flex>
                        )}
                        </Paper>
                    </Tabs.Panel>
                    <Tabs.Panel value="init_prompt">
                        <Stack>
                            {prompt && <EditPrompt index={-1} prompt={prompt} onChange={setPrompt} flows={flows} onRemove={() =>{}}/>}
                            {prompts.map((prom, index) => {
                                return (<EditPrompt key={index} index={index} flows={flows} prompt={prom} onChange={(pr) => {
                                    const _prompts = _.cloneDeep(prompts);
                                    _prompts[index] = pr;
                                    setPrompts(_prompts);
                                }} onRemove={() => {
                                    const _prompts = _.cloneDeep(prompts);
                                    _prompts.splice(index, 1);
                                    setPrompts(_prompts);
                                }}/>)
                            })}
                             <Button
                                onClick={() => {
                                    setPrompts([...prompts, newPrompt("conditional")])
                                }}
                                w={"fit-content"}
                                m={"auto"}
                             >Add Conditional Prompt</Button>
                        </Stack>
                       
                    </Tabs.Panel>
                    <Tabs.Panel value="program_widget">
                    <Paper mt={"lg"} bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                            <Title pl="lg" mb={"xs"} c={"gray.8"} order={5}>Program Widget Configuration</Title>
                            {programWidget && (
                                <Flex>
                                    <Stack className={styles.formFieldSet} p={"md"}> 
                                        <div>
                                        <label className={styles.inputLabel}>Actions</label>
                                            <Stack>
                                                {programWidget.params.actions && (programWidget.params.actions as ActionType[]).map((action, index) => {
                                                    return (
                                                        <Group key={index} wrap="nowrap">
                                                            <TextInput 
                                                                onChange={(e) => {
                                                                    const _actions = [..._.cloneDeep((programWidget.params.actions as ActionType[]))]
                                                                    _actions[index] = {...action, label: e.target.value}
                                                                    const _params = {...programWidget.params, actions: _actions}
                                                                    setProgramWidget({...programWidget, params: _params});
                                                                }} 
                                                                w={"50%"} 
                                                                value={action.label}
                                                            />
                                                            <Select 
                                                                checkIconPosition="right"
                                                                onChange={(value) => {
                                                                    const _actions = [..._.cloneDeep((programWidget.params.actions as ActionType[]))]
                                                                    _actions[index] = {...action, actionId: value as string}
                                                                    const _params = {...programWidget.params, actions: _actions}
                                                                    setProgramWidget({...programWidget, params: _params});
                                                                }} 
                                                                w={"50%"} 
                                                                data={[...flows.map((val)=> { return {value: val.id, label: val.name}})] as {value:string, label:string}[]} 
                                                                value={action.actionId} />
                                                            <ActionIcon 
                                                                onClick={() => {
                                                                    const _actions = [..._.cloneDeep((programWidget.params.actions as ActionType[]))]
                                                                    _actions.splice(index, 1)
                                                                    const _params = {...programWidget.params, actions: _actions}
                                                                    setProgramWidget({...programWidget, params: _params});
                                                                }} 
                                                                variant="light" 
                                                                color={"red"}>
                                                                <IconTrash size={18}/>
                                                            </ActionIcon>
                                                        </Group>
                                                    )
                                                })}
                                                <Button 
                                                    onClick={()=> {
                                                        const _actions = [..._.cloneDeep((programWidget.params.actions as ActionType[]))]
                                                        _actions.push({label: "Some CTA", type: "flow", actionId: flows?.[0].id || ""});
                                                        const _params = {...programWidget.params, actions: _actions}
                                                        setProgramWidget({...programWidget, params: _params});
                                                    }} 
                                                    variant="light">
                                                        Add Action
                                                    </Button>
                                            </Stack>
                                        </div>
                                    </ Stack>
                                    <Paper className={styles.formPreview} p={"md"} bg={"transparent"} >
                                       
                                    </Paper>
                                </Flex>
                            )}
                        </Paper>
                    </Tabs.Panel>
                </Tabs>
                

            </Container>
        </>
    )
}