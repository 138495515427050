import {v4 as uuidv4} from "uuid"

export type FlowType = {
    id?: string,
    embedding?: any,
    name: string,
    status: "draft" | "published" | "archived" | "hidden",
    type: string,
    form_id?:string,
    actions: ActionType[],
    connections: Object[]
}

export type ActionType = {
    id: string,
    type: string,
    action: Object
}


const FlowDefault:FlowType = {
    name: "",
    status: "draft",
    type: "default",
    actions: [],
    connections: []
}

export function newFlow(name: string, type: string, trigger: string ):FlowType {
    return {...FlowDefault, name: name, type: type || "default", actions: [
        {
            id: uuidv4(),
            type: "trigger",
            action: {
                trigger: trigger
            }
        }
    ]};
}