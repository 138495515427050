import { Box, ScrollArea, Stack, Text, Tooltip } from "@mantine/core"
import { ChatHistoryElementType } from "../../models/@type.chats"

import styles from "./chat.session.module.css"
import { useEffect, useRef } from "react"

type ChatSessionHistoryComponentProps = {
    history: ChatHistoryElementType[]
}

export function ChatSessionHistoryComponent({history}:ChatSessionHistoryComponentProps) {
    const chatHistoryWrapperRef = useRef<null | HTMLDivElement>(null)

    useEffect(() => {
        chatHistoryWrapperRef.current?.lastElementChild?.scrollIntoView();
    }, [history])
    useEffect(() => {
        chatHistoryWrapperRef.current?.lastElementChild?.scrollIntoView();
    }, [])
    return (
            <ScrollArea ref={chatHistoryWrapperRef} h={"100%"}>
                <ul  style={{ listStyle: "none", padding: "0 var(--mantine-spacing-lg)"}}>
                    {history.length == 0 && (
                        <Text ta={"center"} c={"dimmed"} w={"100%"} >Session without messages</Text>
                    )}
                    {history.map((val) => {
                        return (<li className={styles.chatBubbleWrapper}>
                            <Box maw={"70%"} className={val.from == "user"? styles.bubbleUser:(val.from == "assistant"? styles.bubbleAssistant: styles.bubbleSystem)} px={"sm"} py={"xs"}>
                                <Stack gap={2}>
                                    <Text c={"dimmed"} size="xs">{val.type}</Text>
                                    {val.type == "action"? 
                                    <Tooltip
                                    label={JSON.stringify(val.params)}
                                    ><Text size={"xs"} fw={"bold"}>{val.params.type}</Text></Tooltip>
                                    
                                    :<Box dangerouslySetInnerHTML={{__html: val.params.message}}/>
                                    }
                                    {val.type == "options" && val.params.options && (
                                         <ul>
                                         {val.params.options.map((opt: {label:string, value: string}) => {
                                             return (<li>{opt.label}</li>)
                                         })}
                                     </ul>
                                    )}
                                </Stack> 
                            </Box>
                            </li>)
                    })}
                </ul>
                    <div></div>
            </ScrollArea>
    )
}