import {  Stack, Title, Text, Box, Group, Avatar } from "@mantine/core"
import { ExtendedChatSessionType } from "../../models/@type.chats"
import styles from './chat.session.module.css'

type SessionBoxComponentProps = {
    session: ExtendedChatSessionType,
    active: boolean,
    onClick(session:ExtendedChatSessionType): void
}

export function SessionBoxComponent({session, active, onClick}:SessionBoxComponentProps) {
    return (
        <Stack className={`${styles.chatSessionListElement} ${active && styles.active}`}  p={"sm"} w={"100%"} h={120} onClick={() => onClick(session)}>
            <Group wrap={"nowrap"}>
                <Avatar size={"sm"}></Avatar>
                <Title c={"gray.8"} order={6}>{session.lead.name}</Title>
                <Text></Text>
            </Group>
            <Box mah={40} style={{overflow: "hidden"}} fz={"sm"} c={"dimmed"} className={styles.content} dangerouslySetInnerHTML={{__html: session.lastMessage}}></Box>
            <Text ta={"right"} size={"xs"} c={"dimmed"}>{(session.session.lastActivity.toDate()).toDateString()}</Text>
        </Stack>
    )
}