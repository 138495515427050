import { Stack, Title, Text } from "@mantine/core"
import { CRMAttributeType } from "../../models/@type.client"
import { LeadType } from "../../models/@type.lead"

type ChatSessionLeadInfoComponentProps = {
    lead: LeadType | null,
    attributes: CRMAttributeType[]
}

export function ChatSessionLeadInfoComponent({lead, attributes}:ChatSessionLeadInfoComponentProps) {


    function renderAttributes() {

        if(lead) {
            return Object.keys(lead).map((key) => {
                const _index = attributes.findIndex((attr) => attr.id == key);
                if( _index >= 0)
                    if(key !== "email")
                        return (<Stack gap={0}>
                            <Text fw={"bold"} size={"sm"}>{attributes[_index].label}</Text> 
                            <Text>{lead[key]}</Text>
                            </Stack>)
                    else
                        return;
                else
                    return;
            })
        }
        else {
            return;
        }
    }
    if(lead)
    return (
        <Stack p={"lg"}>
            <Title order={5}>Lead Information</Title>
            <Stack gap={0}>
                <Text fw={"bold"} size={"sm"}>Email</Text> 
                <Text>{lead.email}</Text>
            </Stack>
            {renderAttributes()}
            <Stack gap={0}>
                <Text fw={"bold"} size={"sm"}>Lead since</Text> 
                <Text>{(new Date(lead.createdAt)).toDateString()}</Text>
            </Stack>
        </Stack>
    )
    else
    return null;
}