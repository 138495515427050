import { useContext, useEffect, useState } from "react";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { MenuBarComponent } from "../../components/MenuBar";
import { Button, Container, Flex, Group, Loader, Paper, ScrollArea, Table, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";

import styles from "./reports.module.scss";
import { LeadType } from "../../models/@type.lead";
import { getPaginatedOrgLeads } from "../../services/database";
import { DynamicTableComponent } from "../../components/DynamicTable";

export function LeadsReportPage() {
    const {selected} = useContext(ConsoleContext) as ContextType;



    useEffect(() => {

    },[])
    useEffect(() => {

    }, [selected])



    return (
        <>
            <MenuBarComponent title={"All Leads"}>
                <Group>
                    <Button onClick={() => {}} variant="light" leftSection={<IconDownload size={14}/>}>Export Leads</Button>
                </Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <ScrollArea>
                    <Group py={"lg"} className={styles.filterContainer}></Group>
                    <DynamicTableComponent 
                        dataFn={async (position?:number, directionAfter?:boolean ,limit?: number, filters?:any) => {
                            return await getPaginatedOrgLeads(selected?.id || "", position, directionAfter, limit, filters)
                        }}
                        headers={[{label: "Email", value: "email"}, {label: "First Name", value: "firstName"}, {label: "Last Name", value: "lastName"}, {label: "Phone Number", value: "phoneNumber"}, {label: "Created At", value: "createdAt"}]}
                        search={[]}
                        filters={[]}
                    />
                </ScrollArea>
            </Container>
        </>
    )
}