import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconChevronRight, IconMessageCircle2Filled } from "@tabler/icons-react"
import _ from "lodash";


export type FormSubmissionActionType = {
    id?: string,
    name?: string,
    form: {
        id: string,
        label: string
    }
}


export const FormSubmissionActionSchema = [

    {
        target: "form",
        label: "Form",
        type: "data_select",
        option: "forms"
    }
]

export const FormSubmissionActionDefault = {
}

type ActionNodeProps = {
    id: string,
    action: {[key:string]: string | boolean | number | Object | null | [] } | Object,
    isConnectable: boolean,
    edges: any
}

export function FormSubmissionActionNode({id, action, isConnectable, edges}: ActionNodeProps) {
    console.log(action);
    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Form Submission</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
                <Paper  shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                    <Group justify={"space-between"} wrap="nowrap">
                        <Text>{(action as FormSubmissionActionType).form?.label}</Text>
                        <IconChevronRight size={20}/>
                    </Group>
                </Paper>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}

