import { z } from "zod";
import { BaseSchema } from "./Base";


export const FieldSchema = BaseSchema.extend({
    label: z.string(),
    locked: z.boolean().default(false),
    status: z.enum(['published', 'archived']).default('published'),
    target: z.string(),
    type: z.string(),
    priority: z.number()
}).catchall(z.any());

// TypeScript type inferred from the schema
export type FieldModelType = z.infer<typeof FieldSchema>;


