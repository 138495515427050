// schemas/programSchema.ts
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { campusSchema } from './Campus';
import { BaseSchema } from './Base';

const MonthEnum = z.enum([
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Sept', 'Oct', 'Nov', 'Dec'
]);

export const StudyOptionScheduleOptions = ['full-time', 'part-time'] as const;
export const StudyOptionDeliveryOptions = ['in-person', 'online', 'blended'] as const;


export const studyOptionSchema = z.object({
    id: z.string().default(uuidv4).optional(),
    schedule: z.enum(StudyOptionScheduleOptions).default('full-time'),
    delivery: z.enum(StudyOptionDeliveryOptions).default('in-person'),
    duration: z.number().default(12),
    durationType: z.enum(['days', 'weeks', 'months', 'years', 'terms']).default('months'),
    language: z.enum(['english', 'italian', 'spanish']).default('english'),
    intakes: z.array(MonthEnum).default([]),
    campus: campusSchema
})

const tuitionSchema = z.object({
    amount: z.number().default(10000),
    currency: z.enum(['EUR', 'USD', 'CAD', 'GBP']).default('EUR'),
    type: z.enum(['national', 'international', 'all']).default('all'),
    period: z.enum(['credit', 'month', 'semester', 'term', 'year', 'all', 'full']).default('year'),
    description: z.string().optional()
})

export const subjectSchema = z.object({
    id: z.string().default(uuidv4).optional(),
    name: z.string().min(3, 'Name must be at least 3 characters long'),
    url: z.string().optional(),
    status: z.enum(['published', 'archived']).default('published')
});

const degreeTypeEnum = z.enum([
    "bachelor", 
    "master", 
    "mba", 
    "phd", 
    "diploma", 
    "associate_degree", 
    "major", 
    "executive_master", 
    "dba", 
    "executive_program", 
    "executive_course", 
    "executive_mba", 
    "online_course"
]);

export const programSchema = BaseSchema.extend({
    id: z.string().optional(),
    cover: z.string().optional(),
    internalId: z.string().default(""),
    url: z.string().optional().default(""),
    name: z.string().min(2, 'Name must be at least 2 characters long').default("New Program"),
    internalName: z.string().optional(),
    status: z.enum(['draft', 'published', 'archived']).default('draft'),
    overview: z.string().default(""),
    credits: z.number().optional(),
    degreeType: degreeTypeEnum.default('bachelor'),
    studyOptions: z.array(studyOptionSchema).default([]),
    subjects: z.array(subjectSchema.shape.id).default([]),
    tuition: z.array(tuitionSchema).default([]),
    brochure: z.string().optional()
})


// TypeScript type derived from the Zod schema
export type ProgramModelType = z.infer<typeof programSchema>;

export type StudyOptionModelType = z.infer<typeof studyOptionSchema>;