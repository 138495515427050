import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore} from "@firebase/firestore"

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);


const provider = new GoogleAuthProvider();
export const auth = getAuth();

export const storage = getStorage(app);
export const firestore = getFirestore(app);

export async function signInWithGoogle():Promise<boolean> {
    const res = await signInWithPopup(auth, provider)
    const user = res.user
    if(user)
        return true;
    else
        return false;
}

export async function signInWithUnichats(username:string, password: string):Promise<boolean> {
    try {
        const res = await signInWithEmailAndPassword(auth, username, password);
        const user = res.user;
        console.log(user);
        if(user)
            return true;
        else
            return false;
    }
    catch(err) {
        console.log(err);
        return false;
    }
}

