import styles from './chat.user.module.scss'

import { useContext, useEffect, useState } from 'react';
import { Button, Flex, Anchor, Loader, Title,Avatar, Group, SimpleGrid, Card, Text, Badge, Image, Stack, Modal, Container, TextInput, Paper, Table, Drawer } from "@mantine/core";
import { MenuBarComponent } from "../../components/MenuBar";
import { IconMessageQuestion, IconPlus, IconSearch } from "@tabler/icons-react";
import { v4 as uuidv4 } from 'uuid';
import { ChatUserComponent } from './ChatUser';
import { ChatUserType, newChatUser } from '../../models/@type.chat.user';
import { ConsoleContext, ContextType } from '../../context/ConsoleContext';
import { addOrgChatUser, getOrgChatUsers, updateOrgChatUser} from '../../services/database';
import _ from 'lodash'
import { notifications } from '@mantine/notifications';


const AMBASSADOR_STATUS:{[key:string]:string} = {
    draft: "Inactive",
    published: "Active",
    archived: "Disabled"
}

export function AmbassadorsIndexPage() {
    const [currentAmbassador, setCurrentAmbassador] = useState<Object | null>(null)
    const [chatUserEdit, setChatUserEdit] = useState<ChatUserType | null>(null)
    const [data, setData] = useState<ChatUserType[]>([])
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const {selected} = useContext(ConsoleContext) as ContextType

    useEffect(() => {
        getData();
    }, [])

    async function getData() {
        setLoadingData(true);
        const _data = await getOrgChatUsers(selected?.id || "");
        setData(_data as ChatUserType[])
        setLoadingData(false);
    }


    async function onSave(chatUser:ChatUserType) {
        if(chatUser.id) {
            const _update_res = await updateOrgChatUser(chatUser, selected?.id || "");
            if(_update_res) {
                notifications.show({
                    title: 'Update Successful',
                    message: `${chatUser.name} was updated successfully.`
                })
                setChatUserEdit(null);
                getData();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Update Unsuccessful',
                    message: `${chatUser.name} update had an issue.`
                })
                }
        }
        else {
            const _add_res = await addOrgChatUser(chatUser, selected?.id || "");
            if(_add_res) {
                notifications.show({
                    title: 'Added Successful',
                    message: `${chatUser.name} was added successfully.`
                })
                setChatUserEdit(null);
                getData();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Addition Unsuccessful',
                    message: `${chatUser.name} creation had an issue.`
                })
            }
            
        }
    }

    return (
        <>
            <Drawer withCloseButton={false} opened={chatUserEdit?true:false} position="right" size={"xl"} onClose={() => {}}>
                {chatUserEdit && (
                    <ChatUserComponent chatUser={chatUserEdit as ChatUserType} onSave={onSave} onClose={() => {setChatUserEdit(null)}}/>
                )}
            </Drawer>
            <MenuBarComponent title={"Ambassadors"}>
                <Group>
                    <Button onClick={() => {setChatUserEdit(newChatUser())}} leftSection={<IconPlus size={14}/>}>Add User</Button>
                </Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <Group py={"lg"}  className={styles.filterContainer}>
                    <TextInput
                                leftSectionPointerEvents="none"
                                leftSection={<IconSearch size={18}/>}
                                classNames={{input: styles.input}} 
                                placeholder="search ambassador" 
                                disabled
                                />
                </Group>
                <Paper className={styles.tableWrapper}>
                            <Table classNames={{table: styles.table}}>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                        <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>User</Text></Table.Th>
                                        <Table.Th><Text fw={"bold"} size={"xs"}>Status</Text></Table.Th>
                                        <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                                        <Table.Th><Text fw={"bold"} size={"xs"}># Requests</Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                {!loadingData && (
                                     <Table.Tbody>
                                     {data.map((element) => {
                                         return (
                                             <Table.Tr key={element.id} >
                                                 <Table.Td pl={"md"} maw={120} miw={120}>
                                                    <Group gap="sm" py={"xs"}>
                                                        <Avatar size={40} src={element.image} radius={40} />
                                                        <div>
                                                            <Anchor onClick={() => { setChatUserEdit(element)}} size="sm">
                                                                <Text fz="sm" fw={"bold"}>
                                                                {element.name}
                                                                </Text>
                                                            </Anchor>
                                                            <Text fz="xs" c="dimmed">
                                                            {element.email}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Table.Td>
                                                <Table.Td maw={60}><Flex mih={40} align={"center"} >
                                                    <Badge variant={"dot"} 
                                                    color={`${element.status == "published"? "green":(element.status == "archived"? "yellow":"gray")}`}
                                                    >
                                                        {AMBASSADOR_STATUS[element.status || "draft"]}
                                                    </Badge></Flex>
                                                </Table.Td>
                                                 <Table.Td maw={100}>
                                                    <Stack gap={0}>
                                                        <Text fz={"xs"} fw={500} tt={"capitalize"}>{element.type}</Text>
                                                        <Text fz={"xs"} c={"dimmed"}>{element.description}</Text>
                                                    </Stack>
                                                    </Table.Td>
                                                 <Table.Td> <Group gap={6}><Text size={"sm"} c="dimmed"> {element.requestsCount || 0}</Text><Text c="dimmed" p={0} mt={6}><IconMessageQuestion size={18}/></Text> </Group></Table.Td>
                                             </Table.Tr>
                                         )
                                     })}
                                 </Table.Tbody>
                                )}
                            </Table>

                            {loadingData && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(!loadingData && data.length == 0) && (
                                <Group w={"100%"} justify="center" py={"sm"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">You have 0 ambassadors.</Text>
                                </Group>
                            )}
                </Paper>
            </Container>
        </>
    )
}