// services/firestoreService.ts
import { collection, doc, getDocs, getDoc, addDoc, updateDoc, deleteDoc, query, limit, startAfter, DocumentSnapshot } from 'firebase/firestore';
import { firestore as db } from "./firebase";

/**
 * Fetches a collection from Firestore with optional pagination.
 * 
 * @param {string} clientId - The ID of the client.
 * @param {string} collectionName - The name of the collection.
 * @param {number} [limitCount=10] - The number of documents to fetch.
 * @param {DocumentSnapshot} [startAfterDoc] - The document snapshot to start after for pagination.
 * @returns {Promise<firebase.firestore.QuerySnapshot>} - A promise that resolves to the collection snapshot.
 */
export const getCollection = async (clientId: string, collectionName: string, limitCount: number = 10, startAfterDoc?: DocumentSnapshot) => {
    let q = query(collection(db, 'clients', clientId, collectionName), limit(limitCount));
    if (startAfterDoc) {
      q = query(collection(db, 'clients', clientId, collectionName), limit(limitCount), startAfter(startAfterDoc));
    }
    return await getDocs(q);
  };
  
/**
 * Fetches a single document from Firestore.
 * 
 * @param {string} clientId - The ID of the client.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document.
 * @returns {Promise<firebase.firestore.DocumentSnapshot>} - A promise that resolves to the document snapshot.
 */
export const getDocument = async (clientId: string, collectionName: string, docId: string) => {
    return await getDoc(doc(db, 'clients', clientId, collectionName, docId));
};
  
/**
 * Adds a new document to a Firestore collection.
 * 
 * @param {string} clientId - The ID of the client.
 * @param {string} collectionName - The name of the collection.
 * @param {any} data - The data to add to the new document.
 * @returns {Promise<firebase.firestore.DocumentReference>} - A promise that resolves to the document reference of the added document.
 */
export const addDocument = async (clientId: string, collectionName: string, data: any) => {
    delete data.id;
    return await addDoc(collection(db, 'clients', clientId, collectionName), data);
};

/**
 * Updates an existing document in Firestore.
 * 
 * @param {string} clientId - The ID of the client.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document to update.
 * @param {any} data - The new data for the document.
 * @returns {Promise<void>} - A promise that resolves when the document is updated.
 */
export const updateDocument = async (clientId: string, collectionName: string, docId: string, data: any) => {
    delete data.id;
    return await updateDoc(doc(db, 'clients', clientId, collectionName, docId), data);
};

/**
 * Deletes a document from Firestore.
 * 
 * @param {string} clientId - The ID of the client.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document to delete.
 * @returns {Promise<void>} - A promise that resolves when the document is deleted.
 */
export const deleteDocument = async (clientId: string, collectionName: string, docId: string) => {
    return await deleteDoc(doc(db, 'clients', clientId, collectionName, docId));
};