import styles from './knowledge.module.scss'

import { useEffect, useContext, useState } from "react";
import { useDisclosure } from '@mantine/hooks';
import { Text, Paper, Group, Button, Loader, Container, ActionIcon, Modal, Blockquote, TextInput, SimpleGrid, Badge, Anchor } from "@mantine/core";
import {IconPlus, IconPencil, IconTrash, IconSearch, IconBrain, IconSparkles} from "@tabler/icons-react"

import { AddKnowledge } from './AddKnowledge';
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext";
import { Link } from 'react-router-dom';


import { MenuBarComponent } from '../../../components/MenuBar';
import { deleteOrgKnowledgeById, getOrgKnowledge } from '../../../services/database';
import { KnowledgeType } from '../../../models/@type.knowledge';

const STATUS_COLORS:{[key:string]: string} = {
    "published": "green",
    "draft": "gray",
    "generated": "blue",
    "archived": "yellow"
}

export function KnowledgeIndexPage() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const [opened, { open, close }] = useDisclosure(false);
    const [ loadingData, setLoadingData ] = useState<boolean>(true)
    const [ data, setData ] = useState<KnowledgeType[]>([])

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        getData()
    }, [selected])

    async function getData() {
        setLoadingData(true);
        const _data = await getOrgKnowledge(selected?.id || "");

        setData((_data as KnowledgeType[]).sort((a,b) => {
            if(a.status == "generated"){
                return 1
            }
            else if(b.status == "generated") {
                return -1;
            }
            return 0;
        }));
        setLoadingData(false)
    }



    return (
        <>
        <MenuBarComponent title={"Knowledge"}>
            <Group>
                <Button leftSection={<IconPlus size={14}/>} onClick={open}>Add Knowledge</Button>
            </Group>
        </MenuBarComponent>
        <Modal opened={opened} onClose={close} title="Add Knowledge">
            <AddKnowledge/>
        </Modal>
        <Container size={"xl"} p={"md"} px={"xl"}>
            <Group py={"lg"}  className={styles.filterContainer}>
                    <TextInput
                                leftSectionPointerEvents="none"
                                leftSection={<IconSearch size={18}/>}
                                classNames={{input: styles.input}} 
                                placeholder="search knowledge name" 
                                disabled
                                />
                </Group>
                <SimpleGrid cols={{ base: 1, xs: 2, md: 3, xl: 4 }}>
                    {data.map((element) => {
                        return (
                            <Paper key={element.id} withBorder p="md" radius="md" bg={element.status == "generated"? "gray.1":"white"}>
                                <Group justify="space-between">
                                    <Badge variant='dot' color={STATUS_COLORS[element.status]}>
                                        {element.status == "generated"? "AI generated": element.status}
                                    </Badge>
                                    {element.status == "generated"? (<IconSparkles className={styles.cardIconGenerated} size="1.4rem" stroke={1.5} />): (
                                    <IconBrain className={[styles.cardIcon, styles[element.status]].join(" ")} size="1.4rem" stroke={1.5} />)}
                                </Group>
                                <Group align="flex-end" gap="xs" mt={25}>
                                    {element.status == "generated"? (<Text c={"gray.8"} className={styles.cardTitle} fz="md">{element.name}</Text>):(
                                    <Anchor component={Link} to={`/data/knowledge/${element.id}`} fz="md" className={styles.cardTitle}>{element.name}</Anchor>)}
                                </Group>
                                <Text fz="xs" c="dimmed" mt={7}>
                                    Last update: {element.updatedAt && (new Date(element.updatedAt || "")).toISOString().split('T')[0]}
                                </Text>
                            </Paper>
                        )
                    } )}
                </SimpleGrid>
                {loadingData && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(!loadingData && data.length == 0) && (
                                <Group w={"100%"} justify="center" py={"md"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">You have 0 knowledge added.</Text>
                                </Group>
                            )}
        </Container>
        </>
    )
}