import {  useEffect, useState } from "react";
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { LeadSchema } from "../../models/Lead";
import { FieldSchema } from "../../models/Fields";
import { Button, rem } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";


export function DownloadLeadsAsCSVButton() {
    const { documents:leads, loading: fetchingLeads, fetchDocuments:fetchLeads} = useFirestoreCollection('leads', LeadSchema)
    const { documents:fields, loading: fetchingFields, fetchDocuments:fetchFields} = useFirestoreCollection('attributes', FieldSchema)
    const [ exporting, setExporting ] = useState<boolean>(false);


    useEffect(() => {
        console.log("entered use Effect");
        console.log(fields);
        if(!fetchingLeads && !fetchingFields && exporting) {
            console.log("entered here")
           downloadCSV()
        } 
    }, [fetchingLeads, fetchingFields])

    function convertLeadsExportToCSV() {
        const headers = fields.map((d) => d.label).join(',') + ',created_at'  + '\n';

        const rows = leads.map((row) => {
            // Map each row to CSV format
            return fields.map((field) => row[field.id || ""] || '').join(',') + "," + (new Date(row["createdAt"] as number).toDateString());
          })
          .join('\n');

        return headers + rows;
    }
    
    function downloadCSV() {
        const data = convertLeadsExportToCSV();
        if (data === '') {
            alert('No data to export');
          } else {
            // Create CSV file and initiate download
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'lead_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          setExporting(false);
    }

    function onExportClick() {
        setExporting(true);
        fetchLeads(1000);
        fetchFields(1000);
    }

    return (
        <Button disabled={exporting} onClick={onExportClick} variant={"light"} c={"green"} leftSection={<IconDownload style={{width: rem(16), height: rem(16)}}/>}>{exporting? "Exporting":"Export Leads"}</Button>
    )

}