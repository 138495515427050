
import { useNavigate } from "react-router-dom"
import { addOrgFlow } from "../../../services/database"
import { useContext, useState } from "react"
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext"
import {v4 as uuidv4} from "uuid"
import { Button, Stack, TextInput } from "@mantine/core"
import { newFlow } from "../../../models/@type.flow"

export function AddFlow() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const navigate = useNavigate()
    const [name, setName] = useState<string>("")
    const [trigger, setTrigger] = useState<string>("")

    async function add() {
        if(!name)
            return;
        if(!trigger)
            return;

        const _add = newFlow(name, "default", trigger)
        const _res = await addOrgFlow(_add, selected?.id as string)
        if(_res)
            navigate(`/conversations/flows/${_res}`);
    }

    return (
        <Stack gap={"lg"}>
             <TextInput
                label="Flow Name"
                description="A recognizable name for your flow."
                placeholder="Recognizable Name"
                value={name}
                onChange={(evt) => {setName(evt.target.value)}}
            />
            <TextInput
                label="Trigger"
                description="Trigger Text or Question that will start the flow."
                placeholder="Why should I study here?"
                value={trigger}
                onChange={(evt) => {setTrigger(evt.target.value)}}
            />
            <Button onClick={add}>Add Flow</Button>
        </Stack>
    )
}