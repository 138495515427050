//import { DocumentSnapshot } from "@firebase/firestore"; could not set snapshot correctly here...
import { z } from "zod";


export const BaseSchema = z.object({
    id: z.string().optional(),
    //createdAt: z.custom((val) => {return val && typeof val['toDate'] === "function" && val.toDate() instanceof Date; }).optional(),
    //updatedAt: z.custom((val) => {return val && typeof val['toDate'] === "function" && val.toDate() instanceof Date; }).optional(),
    createdAt: z.number().optional(),
    updatedAt: z.number().optional(),
   
    snapshot: z.any().optional(),
});