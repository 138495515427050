import { useContext, useEffect, useState } from "react";
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext";
import { CRMAttributeType, ClientType } from "../../../models/@type.client";
import { MenuBarComponent } from "../../../components/MenuBar";
import { Button, Container, Group, Paper, Tabs, Text } from "@mantine/core";
import { Loading } from "../../../components/Loading";
import { getOrgCRMAttributes } from "../../../services/database";
import { CRMAttributeConfigurationComponent } from "./_CRMAttributeConfiguration";


export function GeneralCRMConfiguration() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ initStatus, setInitStatus ] = useState<boolean>(false);

    const [ attributes, setAttributes ] = useState<CRMAttributeType[]>([])
    const [activeTab, setActiveTab] = useState<string | null>("attributes");

    useEffect(() => {
        if(selected)
            init(selected)
    }, [selected])


    // Get all the initial Data
    async function init(client:ClientType) {
        setInitStatus(false);
        const _attrs = await getOrgCRMAttributes(client.id as string);
        setAttributes(_attrs);
        setInitStatus(true);
    }


    return (
        <>
            <MenuBarComponent title={"CRM General Configuration"}>
                <Group>
                    <Button>Save</Button>
                </Group>
            </MenuBarComponent>
            {initStatus? 
            <>
                <Container size={"xl"} p={"md"} px={"xl"}>
                    <Tabs defaultValue={"attributes"} value={activeTab} onChange={setActiveTab}>
                        <Tabs.List>
                            <Tabs.Tab value={"attributes"}>
                                <Text size={"md"} fw={`${activeTab == "attributes"? "bold":"normal"}`} c={`${activeTab == "attributes"? "blue.7":"gray.7"}`}>Attributes</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value={"categories"}>
                                <Text size={"md"} fw={`${activeTab == "categories"? "bold":"normal"}`} c={`${activeTab == "categories"? "blue.7":"gray.7"}`}>Categories</Text>
                            </Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="attributes">
                            <Paper mt={"lg"} bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                                <CRMAttributeConfigurationComponent orgId={selected?.id || ""} data={attributes} onChange={setAttributes} />
                            </Paper>
                        </Tabs.Panel>
                    </Tabs>
                </Container>
            </>
            :<Loading/>}
        </>
    )
}