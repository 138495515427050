import { useEffect, useState } from "react"
import { IntegrationType } from "../../../schemas/integrationSchema"
import { ActionIcon, Button, Fieldset, Flex, Group, Loader, Select, Stack, TextInput, Title } from "@mantine/core"
import { IconTrash } from "@tabler/icons-react"
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection"
import { attributeSchema } from "../../../schemas/attributeSchema"

export type IntegrationSchemaType = {
    name: string,
    type: string,
    integrationId: string,
    params: { name: string, label: string, type: string }[]
}
type IntegrationPageProps = {
    schema: IntegrationSchemaType,
    data: null | IntegrationType,
    update(docId:string, data:IntegrationType):void,
    add(data:IntegrationType):void,
    onClose():void
}

type MappedFieldType = {
    fieldFrom: string,
    fieldTo: string
}

function generateDefaultIntegrationData(schema:IntegrationSchemaType) {
    const data:{[key:string]: any} = {};
    schema.params?.map((param) => {
        if(param.type == "string")
            data[param.name] = "";
        else if(param.type == "field_mapping")
            data[param.name] = [];
    })
    return data;
}

export function IntegrationEditPage({schema, data, update, add, onClose}:IntegrationPageProps) {
    const [formData, setFormData] = useState(data?.params || generateDefaultIntegrationData(schema));
    const { documents, loading, fetchDocuments } = useFirestoreCollection('attributes', attributeSchema);

    useEffect(() => {
        fetchDocuments(1000)
    }, [])

    async function onSave() {
        try {
            if(data) {
                await update(data.id || "", {...data, params: formData})
            }
            else {
                await add({
                    name: schema.integrationId,
                    type: schema.type as 'leadAdd' | 'leadUpdate' | 'formSubmit',
                    status: "active",
                    params: formData
                })
            }
            //onClose();
        }
        catch(error) {
            console.error(error);
        }
    }

    function onMappedFieldChange(name: string, fieldName:string, index:number, value:string | undefined | null) {
        const _mappedFields = [...formData[name] as MappedFieldType[]];
        _mappedFields[index] = {..._mappedFields[index], [fieldName]: value}
        setFormData({...formData, [name]:_mappedFields});
    }

    function onMappedFieldRemove(name: string, index:number) {
        const _mappedFields = [...formData[name] as MappedFieldType[]];
        _mappedFields.splice(index, 1);
        setFormData({...formData, [name]:_mappedFields});
    }

    function onMappedFieldAdd(name: string) {
        const _mappedFields = [...formData[name] as MappedFieldType[]];
        _mappedFields.push({fieldFrom: documents[0]?.id || "", fieldTo: ""});
        setFormData({...formData, [name]:_mappedFields});
    }

    function fieldMappingField(name:string, label:string) {
        return (
            <Fieldset legend={label}>
                {formData[name]?.map((element:MappedFieldType, index:number) => {
                    return (
                    <Group mb={"lg"}>
                        <Select 
                            label={"Unichats Field"}
                            value={element.fieldFrom}
                            data={documents?.map((doc) => {
                                return {label: doc.label, value: doc.id || ""}
                            })}
                            onChange={(value) => {
                                onMappedFieldChange(name, "fieldFrom", index, value);
                            }}
                        />
                        <TextInput label={"CRM Field"} value={element.fieldTo} onChange={(event) => {
                            onMappedFieldChange(name, "fieldTo", index, event.target.value);
                        }}/>
                        <ActionIcon onClick={() => onMappedFieldRemove(name, index)} size={"xs"} variant="transparent" c={"red"}><IconTrash/></ActionIcon>
                    </Group>
                    )
                })}
                <Button onClick={() => onMappedFieldAdd(name)}>Add Field</Button>
            </Fieldset>
        )
    }

    return (
        <Flex direction={"column"}>
            <Group justify={"space-between"}>
                <Title order={3}>{schema.name}</Title>
                <Group>
                    <Button size="sm" variant={"filled"} onClick={onSave}>Save</Button>
                </Group>
            </Group>
            {loading && <Loader/>}
            {!loading && (
            <Stack mt={"xl"}>
                {schema.params.map((param) => {
                    if(param.type  == "string") {
                        return (
                            <TextInput
                                label={param.label}
                                value={formData[param.name]}
                                onChange={(event) => { setFormData({...formData, [param.name]: event.target.value || ""})}}
                            />
                        )
                    }
                    if(param.type == "field_mapping") {
                        return fieldMappingField(param.name, param.label)
                    }
                })}
            </Stack>
            )}
        </Flex>
    )
}