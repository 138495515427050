import styles from './imageField.module.scss'

import { Group, Image, ActionIcon, FileInput } from '@mantine/core';
import { IconPhoto, IconTrash } from '@tabler/icons-react';
import { uploadFile } from '../services/storage';

export type ImageFieldComponentProps = {
    id: string,
    value: string,
    label?: string,
    placeholder: string,
    clientId: string,
    namespace: string,
    onChange(src:string):void
}

export function ImageFieldComponent({id, value, label, placeholder, clientId , namespace ,onChange}:ImageFieldComponentProps) {

    async function uploadImage(file:File | null) {
        if(file) {
            const _res = await uploadFile(clientId,namespace, file as File);
            onChange(_res)
        }
    }

    if(value) {
        return (
            <div key={id} className={styles.inputField}>
                {label && <label className={styles.label}>{label}</label>}
                <Group pos="relative">
                    <Image src={value} w={"100%"} fit="contain"/>
                    <ActionIcon pos="absolute" top={5} right={5} color='black' onClick={() => onChange("")}><IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5}/></ActionIcon>
                </Group>
            </div>
        )
    }
    else {
        return (
            <FileInput 
                classNames={{
                    input: styles.input
                }}
                leftSection={<IconPhoto stroke={1.5}/>}
                key={id}
                size='md'
                placeholder={placeholder}
                label={label}
                onChange={uploadImage}
            />
        )
    }
}