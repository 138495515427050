import { Paper, Title, Button, Stack, Group, TextInput, Select, ActionIcon, Box, NumberInput } from "@mantine/core"
import { RichTextInput } from "../../../components/RichTextInput"
import { PromptType } from "../../../models/@type.client"
import _ from "lodash"

import styles from "../config.module.scss"
import { FlowType } from "../../../models/@type.flow"
import { IconTrash } from "@tabler/icons-react"

export type EditPromptProps = {
    prompt: PromptType,
    flows: FlowType[],
    index: number,
    onChange(prompt:PromptType): void,
    onRemove(): void
}

export function EditPrompt({prompt, flows, index, onChange, onRemove}:EditPromptProps) {

    return  (
        <Paper mt={"lg"} bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
            <Group align="center" justify="space-between" mb="md">
                <Title pl="lg" mb={"xs"} c={"gray.8"} order={5}>{prompt.type == "default"? "Default":"On Condition"}</Title>
                {index > -1 && (
                    <ActionIcon 
                    onClick={onRemove} 
                     
                    color={"red"}>
                        <IconTrash size={18}/>
                </ActionIcon>
                )}
            </Group>
            <Group gap={0} align={"start"}>
                <Stack className={styles.formFieldSet} p={"md"}>
                    {prompt.type != "default" && (
                        <>
                            <div>
                                <label className={styles.inputLabel}>URL/Title Match Type</label>
                                <Select 
                                    checkIconPosition="right"
                                    onChange={(value) => {
                                        const _condition = _.cloneDeep(prompt.condition || {type: "url_contains", match: ""});
                                        _condition.type = value || "url_contains";
                                        onChange({...prompt, condition: _condition});
                                    }}
                                    data={[{label: "URL Contains", value: "url_contains"}, {label: "URL Exact", value: "url_exact"}, {label: "URL Ends", value: "url_ends"}, {label: "URL Starts", value: "url_starts"}, {label: "Title Contains", value: "title_contains"}]} 
                                    value={prompt.condition?.type || "url_contains"} 
                                />
                            </div>
                            <div>
                                <label className={styles.inputLabel}>URL/Title Match</label>
                                <TextInput
                                    
                                    placeholder="/programs/"
                                    onChange={(e) => {
                                        const _condition = _.cloneDeep(prompt.condition || {type: "url_contains", match: ""});
                                        _condition.match = e.target.value || "";
                                        onChange({...prompt, condition: _condition});
                                    }} 
                                    value={prompt.condition?.match || ""}
                                />
                            </div>
                            <div>
                                <label className={styles.inputLabel}>Priority</label>
                                <NumberInput
                                    
                                    placeholder="priority"
                                    onChange={(e) => {
                                        onChange({...prompt, priority: e as number || 0});
                                    }} 
                                    value={prompt.priority || 0}
                                />
                            </div>
                        </>
                    )}
                    <div>
                        <label className={styles.inputLabel}>Prompt Message</label>
                        <RichTextInput
                            className={styles.input}
                            id={`${index}_prompt_content`} 
                            value={prompt.content || ""} 
                            onChange={(value:string) => {onChange({...prompt, content: value})}} 
                        />
                    </div>
                </Stack>
                <Box className={styles.formFieldSet} p={"md"} bg={"gray.1"} style={{borderRadius: "var(--mantine-radius-sm)"}}>
                    <label className={styles.inputLabel}>Actions</label>
                    <Stack>
                        {prompt.actions.map((action, index) => {
                            return (
                                <Group key={index} wrap="nowrap">
                                    <TextInput
                                        onChange={(e) => {
                                            const _actions = [..._.cloneDeep(prompt.actions)]
                                            _actions[index] = {...action, label: e.target.value}
                                            onChange({...prompt, actions: _actions});
                                        }} 
                                        w={"50%"} 
                                        value={action.label}
                                    />
                                    <Select 
                                        checkIconPosition="right"
                                        onChange={(value) => {
                                            const _actions = [..._.cloneDeep(prompt.actions)]
                                            _actions[index] = {...action, actionId: value as string}
                                            onChange({...prompt, actions: _actions});
                                        }} 
                                        w={"50%"} 
                                        data={[...flows.map((val)=> { return {value: val.id, label: val.name}}), {value: "ambassador", label: "Open Ambassador List"}] as {value:string, label:string}[]} 
                                        value={action.actionId} 
                                    />
                                    <ActionIcon 
                                        onClick={() => {
                                            const _actions = [..._.cloneDeep(prompt.actions)]
                                            _actions.splice(index, 1)
                                            onChange({...prompt, actions: _actions});
                                        }} 
                                        variant="light" 
                                        color={"red"}>
                                            <IconTrash size={18}/>
                                    </ActionIcon>
                                </Group>
                            )
                        })}
                        <Button 
                            onClick={()=> {
                                const _actions = [..._.cloneDeep(prompt.actions)];
                               _actions.push({label: "Some CTA", type: "flow", actionId: flows?.[0].id || ""});
                                onChange({...prompt, actions: _actions});
                            }} 
                            variant="light">
                                Add Action
                        </Button>
                    </Stack>
                </Box>
            </ Group>
        </Paper>
    )
}