import { Stack, Group } from "@mantine/core";
import { AssistantType, ThemeType } from "../../../models/@type.client";

import styles from './preview.module.scss'
import { useEffect } from "react";

type AssistantPreviewComponentProps = {
    assistant: AssistantType
    theme: ThemeType[]
}

export function AssistantPreviewComponent({assistant, theme}:AssistantPreviewComponentProps) {
    useEffect(() => {
        theme.map((val) => {
            document.documentElement.style.setProperty(val.name, val.value);
        })
    }, [theme])
    return (
        <>
            <Stack gap={0} className={styles.assistantPreviewWrapper}>
                <Group className={styles.assistantPreviewHeading} gap={16}>
                    {assistant.avatar && (
                        <img className={styles.avatar} alt={assistant.name} src={assistant.avatar}/>
                    )}
                    <Stack gap={0}>
                        <h3 className={styles.assistantName}>{assistant.name}</h3>
                        <p className={styles.assistantGreeting}>{assistant.greeting}</p>
                    </Stack>
                </Group>
                <Stack gap={8} className={styles.assistantPreviewMessages}>
                        {assistant.messages?.map((msg, index) => {
                            return (<div key={index} className={styles.messageWrapper}><div className={styles.message} key={index} dangerouslySetInnerHTML={{__html:msg}}/></div>)
                        })}
                    </Stack>
            </Stack>
        </>
    )
}