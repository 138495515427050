import { Fieldset, TextInput, Button, Flex, Paper, ScrollArea, Group, Box, Title, Select, Stack } from "@mantine/core"
import { ChatUserType } from "../../models/@type.chat.user"
import { useContext, useState } from "react"
import { ImageFieldComponent } from "../../components/ImageField"
import { ConsoleContext, ContextType } from "../../context/ConsoleContext"
import styles from "./chat.user.module.scss"

export type ChatUserComponentProps = {
    chatUser: ChatUserType,
    onClose():void,
    onSave(chatUser:ChatUserType): void
}


export function ChatUserComponent({chatUser, onClose, onSave}:ChatUserComponentProps) {
    const [ change, setChange ] = useState<boolean>(false);
    const [ data, setData ] = useState<ChatUserType>({...chatUser});

    const {selected} = useContext(ConsoleContext) as ContextType

    async function onChatUserSave() {
        onSave(data);
    }

    return (
        <>
        <Flex key={chatUser?.id || "new_campus"} px={"lg"} py={0} direction={"column"} w={"100%"} className={styles.dataWrapper}>
            <Group pb={"sm"} justify={"space-between"} className={styles.dataHeader}>
                <Title maw={250} lineClamp={1} order={4} c={"gray.8"}>{data.name}</Title>
                <Group> 
                    
                    <Select 
                        onChange={(value) => {setChange(true); setData({...data, status: (value || "draft") as "draft" | "published" | "archived" | undefined})}} 
                        withCheckIcon={false} maw={110} value={data.status} 
                        classNames={{input: `${styles.programStatusSelect} ${data.status=="published"?styles.programStatusPublished:""}`}} 
                        data={[{value: "draft", label: "Inactive"}, {value: "published", label: "Active"}, {value: "archived", label: "Disabled"}]}></Select>
                    <Button disabled={!change} size="sm" variant={"filled"} onClick={onChatUserSave}>Save</Button>
                    <Button size={"sm"} variant="light" onClick={onClose}>Close{change?" without Saving":""}</Button>
                </Group>
            </Group>
            <Box style={{ flexGrow: 1, overflow: 'auto' }}>
                <ScrollArea  pt={"md"}>
                    <Paper bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                        <Title mb={"xs"} c={"gray.8"} order={5}>Basic Information</Title>
                        <Stack>
                         <TextInput
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Name"
                            size="md"
                            placeholder="Name Last Name"
                            value={data.name}
                            onChange={(e) => {setChange(true);setData({...data, name: e.target.value})}}
                            />
                            <Select
                                classNames={{
                                    label: styles.dataInputLabel,
                                    input: styles.input
                                }}
                                label="Degree Type"
                                size="md"
                                value={data.type || "ambassador"}
                                withCheckIcon={false}
                                data={[
                                    {value: "ambassador", label:"Ambassador"},
                                    {value: "admission", label: "Admission"},
                                    {value: "recruitment", label: "Recruitment"},
                                ]}
                                onChange={(value) => {setChange(true);setData({...data, type: (value || "ambassador") as "ambassador"|"admission"|"recruitment" | undefined})}}
                            />
                            <TextInput
                                classNames={{
                                    label: styles.dataInputLabel,
                                    input: styles.input
                                }}
                                label="Email"
                                size="md"
                                type={"email"}
                                placeholder="email@email.com"
                                value={data.email}
                                onChange={(e) => {setChange(true);setData({...data, email: e.target.value})}}
                            />

                            <div>
                                <label className={styles.dataInputLabel}>Photo</label>
                                <ImageFieldComponent 
                                    id={`${data.id}-overview`}
                                    value={data.image || ""}
                                    onChange={(value) => {setChange(true);setData({...data, image: value})}}
                                    namespace="chat_users"
                                    clientId={selected?.id || ""}
                                    placeholder="Photo"
                                />
                            </div>
                            <TextInput
                                classNames={{
                                    label: styles.dataInputLabel,
                                    input: styles.input
                                }}
                                label="Description"
                                size="md"
                                placeholder="Ex: BA in Management 2022"
                                value={data.description}
                                onChange={(e) => {setChange(true);setData({...data, description: e.target.value})}}
                                />
                        </Stack>
                    </Paper>
                </ScrollArea>
            </Box>
        </Flex>
        </>
       
    )
}