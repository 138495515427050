import { useState } from "react"
import { Notification, Flex, Text, Group, Title, ActionIcon, Button, Box, ScrollArea, Paper, Stack, TextInput, Transition } from "@mantine/core"
import { IconTrash, IconX } from "@tabler/icons-react";
import { SubjectModelType } from "../../../models/Subject"
import {cloneDeep} from 'lodash';


import styles from '../../../../pages/data/data.module.scss';


type SubjectPageProps = {
    subject: SubjectModelType,
    onSave(subject:SubjectModelType):void,
    onDelete(subject:SubjectModelType):void,
    onClose():void
}


const ERROR_MESSAGES = {
    NAME: "The subject name cannot be empty.",
}

export function SubjectPage({subject, onSave, onDelete, onClose}:SubjectPageProps) {

    const [ data, setData ] = useState<SubjectModelType>(cloneDeep(subject));
    const [ onDeleteTry, setOnDeleteTry ] = useState<boolean>(false);
    const [ change, setChange ] = useState<boolean>(false);

    const [ error, setError ] = useState<string | null>(null);



    function onCampusSave() {
        if (!data.name) {
            setError(ERROR_MESSAGES.NAME)
            return;
        }
        setError(null)
        onSave(data);
    }



    return (
        <Flex key={subject?.id || "new_subject"} px={"lg"} py={0} direction={"column"} w={"100%"} className={styles.dataWrapper}>
            {onDeleteTry && (
                <Stack style={{zIndex: 11}} bg={"white"} align="center" justify="center" pos="absolute" top={0} w={"100%"} bottom={0} left={0}>
                    <Text fw={"bold"}>Are you sure?</Text>
                    <Button onClick={() => {onDelete(subject); setOnDeleteTry(false);}} w={150} color={"red"} leftSection={<IconTrash size={18}/>}>Yes Delete</Button>
                    <Button onClick={() => {setOnDeleteTry(false);}} w={150} color={"gray"} variant="light" leftSection={<IconX size={18}/>}>Cancel</Button>
                </Stack>
            )}
            <Group pb={"sm"} justify={"space-between"} className={styles.dataHeader}>
                <Title maw={250} lineClamp={1} order={4} c={"gray.8"}>{data.name}</Title>
                <Group> 
                    {data.id && <ActionIcon onClick={() => {setOnDeleteTry(true)}} variant="transparent" c={"red"}><IconTrash size={18}/></ActionIcon>}
                    <Button disabled={!change} size="sm" variant={"filled"} onClick={onCampusSave}>Save</Button>
                    <Button size={"sm"} variant="light" onClick={onClose}>Close{change?" without Saving":""}</Button>
                </Group>
            </Group>
            <Box style={{ flexGrow: 1, overflow: 'auto' }}>
                <ScrollArea  pt={"md"}>
                    <Transition
                            mounted={error?true:false}
                            transition="scale"
                            duration={200}
                            timingFunction="ease">
                            {(styles) => 
                                <Notification mb={"lg"} withBorder style={styles} icon={<IconX size={20}/>} color="red" title={"Error"} onClose={() => { setError(null)}}>
                                    {error}
                                </Notification>
                            }
                    </Transition>
                    <Paper bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                        <Title mb={"xs"} c={"gray.8"} order={5}>Basic Information</Title>
                        <Stack > 
                            <TextInput
                                classNames={{
                                    label: styles.dataInputLabel,
                                    input: styles.input
                                }}
                                label="Name*"
                                size="md"
                                placeholder="Subject Name"
                                value={data.name}
                                onChange={(e) => {setData({...data, name: e.target.value}); setChange(true)}}
                                />     
                        </Stack>
                    </Paper>
                </ScrollArea>
            </Box>
        </Flex>
    )
}