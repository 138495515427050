

import { useEffect, useContext, useState } from "react";
import { useDisclosure } from '@mantine/hooks';
import { Title, Paper, Stack, Group, Button, Table, Container, ActionIcon, Modal, Badge, Text, rem, Center, TextInput, Loader, Anchor, Flex } from "@mantine/core";
import {IconMoodKidFilled, IconPencil, IconPlus, IconSearch, IconSettings, IconTrash, IconX} from "@tabler/icons-react"

import styles from './flows.module.scss'

import { ConsoleContext, ContextType } from "../../../context/ConsoleContext";
import { Link } from 'react-router-dom';
import { AddFlow } from './AddFlow';

import { MenuBarComponent } from '../../../components/MenuBar';
import {  getOrgFlows} from '../../../services/database';

import { FlowType } from "../../../models/@type.flow";



export function FlowsIndexPage() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const [opened, { open, close }] = useDisclosure(false);
    const [ loadingData, setLoadingData ] = useState<boolean>(true)
    const [ data, setData ] = useState<FlowType[]>([]);


    useEffect(() => {
        getFlows()

    }, [])
    useEffect(() => {
        getFlows()

    }, [selected])


    async function getFlows() {
        const _flows = await getOrgFlows(selected?.id || "");
        setData(_flows.sort((a,b) => (a.name < b.name)?-1:1))
        setLoadingData(false)
    }



    return (
        <>
        <MenuBarComponent title={"Flows"}>
            <Group>
            <Button leftSection={<IconPlus size={14}/>} onClick={open}>New Flow</Button>
            </Group>
        </MenuBarComponent>
        <Modal opened={opened} onClose={close} title="Add a New Flow">
            <AddFlow/>
        </Modal>

        
        
        <Container size={"xl"} p={"md"}  px={"xl"}>
            <Group py={"lg"}  justify={"space-between"}>
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18}/>}
                    classNames={{input: styles.input}} 
                    placeholder="search flow" 
                    disabled
                    />
            </Group>
            <Paper className={styles.tableWrapper}>
                <Table classNames={{table: styles.table}}>
                    <Table.Thead className={styles.tableHead} bg={"gray.0"}>
                        <Table.Tr>
                        <Table.Th></Table.Th>
                        <Table.Th><Text fw={"bold"} size={"xs"}>Name</Text></Table.Th>
                        <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                        <Table.Th><Text fw={"bold"} size={"xs"}>Status</Text></Table.Th>
                        <Table.Th><Text fw={"bold"} size={"xs"}>Usage</Text></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    {!loadingData && (
                        <Table.Tbody>
                            {data.map((element, index) => {
                                return (
                                    <Table.Tr  key={element.id}>
                                        <Table.Td align="right">{index+1}</Table.Td>
                                        <Table.Td><Flex mih={40} pl={"md"} align={"center"}><Anchor size="sm" fw="bold" component={Link} to={`${element.id}`}>{element.name}</Anchor></Flex></Table.Td>
                                        <Table.Td><Flex mih={40} align={"center"}><Badge variant={"dot"} color={`${element.status == "draft"? "gray":(element.status == "published"? "green":"yellow")}`}>{element.status}</Badge></Flex></Table.Td>
                                        <Table.Td><Badge>{element.type=="default"? "Conversation": element.type}</Badge></Table.Td>
                                        <Table.Td>
                                               
                                        </Table.Td>
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    )}
               
                </Table>
                {loadingData && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
            </Paper>
         
        </Container>
        </>
    )
}