import { useContext, useEffect, useState } from "react";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { MenuBarComponent } from "../../components/MenuBar";
import { Drawer, Button, Group, Container, Tabs, Text, TextInput, Table, Paper, Flex, Badge, Anchor, Loader, Stack, Notification } from "@mantine/core";
import { IconCheck, IconPlus, IconSearch, IconTableImport, IconX } from "@tabler/icons-react";
import { notifications } from '@mantine/notifications';
import {CSVImporter} from "csv-import-react";

import styles from './data.module.scss'
import { CampusType, ProgramType, getNewCampus, getNewProgram } from "../../models/@type.data";

import { ProgramComponent } from "./Program";
import { CampusComponent } from "./Campus";
import { addOrgCampus, addOrgProgram, deleteOrgCampus, deleteOrgProgram, getOrgCampuses, getOrgPrograms, updateOrgCampus, updateOrgProgram } from "../../services/database";
import { z, ZodError } from "zod";
import { programSchema } from "../../schemas/programSchema";
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { SubjectModelType, SubjectSchema } from "../../v2/models/Subject";
import { SubjectPage } from "../../v2/pages/data/subjects/SubjectPage";


const DEGREE_BADGE_COLOR:{ [key: string]: string; } = {
    "bachelor": "green",
    "master": "blue",
    "phd": "violet",
    "diploma": "orange"
}

const ProgramImportColumns = [
    {
        name: "Unichats Id",
        key: "id",
        description: "Used to update a already created program"
    },
    {
        name: "Program Name",
        key: "name",
        required: true,
        //description: "The program ",
    },
    {
        name: "Internal Id",
        key: "internalId",
        data_type: "string",
    },
    {
        name: "Overview",
        key: "overview",
    },
    {
        name: "DegreeType",
        key: "degreeType",
        required: true,
    },
    {
        name: "Campus",
        key: "campus",
        required: true,
    },
    {
        name: "Delivery",
        key: "delivery",
        required: true,
    },
    {
        name: "Duration",
        key: "duration",
        data_type: "numeric",
        required: true,
    },
    {
        name: "Duration Period",
        key: "durationType",
    },
    {
        name: "Intakes",
        key: "intakes",
        required: true
    },
    {
        name: "Language",
        key: "language"
    },
    {
        name: "Schedule",
        key: "schedule",
        required: true
    },
    {
        name: "URL",
        key: "url"
    },
    {
        name: "Tuition Fee",
        key: "tuition",
        data_type: "number",
        required: "true"
    },
    {
        name: "Tuition Fee Currency",
        key: "tuitionCurrency",
    },
    {
        name: "Tuition Fee Period",
        key: "tuitionFeePeriod",
    },
    {
        name: "Brochure Link",
        key: "brochure"
    }

];

export function ProgramIndexPage() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ activeTab, setActiveTab ] = useState<string | null>('programs');

    const [ importOpen, setImportOpen ] = useState<boolean>(false);
    const [ importingNotification, setImportingNotification] = useState<boolean>(false)
    const [ importingStatus, setImportingStatus] = useState<string>("init");
    const [ importingError, setImportingError] = useState<string>("");

    const [ loadingCampuses, setLoadingCampuses ] = useState<boolean>(false);
    const [ campuses, setCampuses ] = useState<CampusType[]>([]);
    const [ campusEdit, setCampusEdit ] = useState<CampusType | null>(null);

    const [ loadingPrograms, setLoadingPrograms ] = useState<boolean>(false);
    const [ programs, setPrograms ] = useState<ProgramType[]>([]);
    const [ programEdit, setProgramEdit ] = useState<ProgramType | null>(null);

    const { documents:subjects, loading: loadingSubjects, fetchDocuments: fetchSubjects, addDoc:addSubject, updateDoc: editSubject } = useFirestoreCollection('subjects', SubjectSchema);
    const [ subjectEdit, setSubjectEdit] = useState<SubjectModelType | null>(null)

    useEffect(() => {
        getCampuses();
        getPrograms();
        fetchSubjects(200);
    }, [, selected])


    function getCampusList(program:ProgramType) {
        const _list = program.studyOptions.map((so) => {
            return so.campus.name
        })
        return [... new Set(_list)];
    }

    async function getCampuses() {
        setLoadingCampuses(true);
        const _campuses = await getOrgCampuses(selected?.id || "");
        setCampuses(_campuses);
        setLoadingCampuses(false);
    }

    async function getPrograms() {
        setLoadingPrograms(true);
        const _programs = await getOrgPrograms(selected?.id || "");
        setPrograms(_programs.sort((a,b) => {return (a.degreeType > b.degreeType)?1:-1}));
        setLoadingPrograms(false);
    }

    async function onCampusSave(campus:CampusType) {
        if(campus.id) {
            const _update_res = await updateOrgCampus(campus, selected?.id || "");
            if(_update_res) {
                notifications.show({
                    title: 'Update Successful',
                    message: `${campus.name} was updated successfully.`
                })
                setCampusEdit(null);
                getCampuses();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Update Unsuccessful',
                    message: `${campus.name} update had an issue.`
                })
                }
        }
        else {
            const _add_res = await addOrgCampus(campus, selected?.id || "");
            if(_add_res) {
                notifications.show({
                    title: 'Added Successful',
                    message: `${campus.name} was added successfully.`
                })
                setCampusEdit(null);
                getCampuses();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Addition Unsuccessful',
                    message: `${campus.name} creation had an issue.`
                })
            }
            
        }
    }
    
    async function onSubjectSave(subject: SubjectModelType) {
        if(!subject.id) {
            await addSubject(subject);
        }
        else {
            await editSubject(subject.id, subject);
        }
        setSubjectEdit(null);
        fetchSubjects(200);
    }

    async function onCampusDelete(campus:CampusType) {
        const _del_res = await deleteOrgCampus(campus.id || "", selected?.id || "");
        if(_del_res) {
            notifications.show({
                title: 'Deletion Successful',
                message: `${campus.name} was deleted successfully.`
            });
            setCampusEdit(null);
            getCampuses();
        }
        else {
            notifications.show({
                color: "red",
                title: 'Deletion Unsuccessful',
                message: `${campus.name} deletion had an issue.`
            });
        }
    }
    
    async function onProgramSave(program:ProgramType) {
        if(program.id) {
            const _update_res = await updateOrgProgram(program, selected?.id || "");
            if(_update_res) {
                notifications.show({
                    title: 'Update Successful',
                    message: `${program.name} was updated successfully.`
                })
                setProgramEdit(null);
                getPrograms();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Update Unsuccessful',
                    message: `${program.name} update had an issue.`
                })
                }
        }
        else {
            const _add_res = await addOrgProgram(program, selected?.id || "");
            if(_add_res) {
                notifications.show({
                    title: 'Added Successful',
                    message: `${program.name} was added successfully.`
                })
                setProgramEdit(null);
                getPrograms();
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Addition Unsuccessful',
                    message: `${program.name} creation had an issue.`
                })
            }
            
        }
    }

    async function onProgramDelete(program:ProgramType) {
        const _del_res = await deleteOrgProgram(program.id || "", selected?.id || "");
        if(_del_res) {
            notifications.show({
                title: 'Deletion Successful',
                message: `${program.name} was deleted successfully.`
            });
            setProgramEdit(null);
            getPrograms();
        }
        else {
            notifications.show({
                color: "red",
                title: 'Deletion Unsuccessful',
                message: `${program.name} deletion had an issue.`
            });
        }
    }

    async function importProgramsFromCSV(data:any) {
        setImportOpen(false);
        setImportingNotification(true);
        setImportingError("");
        setImportingStatus("importing");
        if(data.error) {
            setImportingError(data.error);
            setImportingStatus("error");
            return;
        }
        const rows = data.rows as {index:number, values:{[key:string]:string}}[]
        const programsToAdd:ProgramType[] = []
        let error = ""
        rows.map((row) => {
            console.log(row);
            try {
                const campus = campuses.find((val) =>  val.name == row.values.campus);
                if(!campus) 
                    throw Error(`Campus named: ${row.values.campus} not found`)

                const zodProgram = programSchema.parse({
                    name: row.values.name,
                    internalId: row.values.internalId,
                    overview: row.values.overview,
                    degreeType: row.values.degreeType,
                    brochure: row.values.brochure,
                    tuition: [{
                        amount: Number.parseFloat(row.values.tuition),
                        currency: row.values.tuitionCurrency,
                        period: row.values.tuitionPeriod
                    }],
                    studyOptions: [
                        {
                            schedule: row.values.schedule,
                            delivery: row.values.delivery,
                            intakes: (row.values.intakes || "").split(","),
                            language: row.values.language,
                            duration: Number.parseInt(row.values.duration),
                            durationType: row.values.durationType,
                            campus: campus
                        }
                    ]
                })
                programsToAdd.push(zodProgram as ProgramType);
            }
            catch(err) {
                if(err instanceof z.ZodError)
                    error = `Error on validating row ${row.index+1}, error: ${err.message}`
                else
                    error = `Error on validating row ${row.index+1}, error: ${err}`
            }
        })
        if(error) {
            setImportingError(error);
            setImportingStatus("error");
            return;
        }

        await Promise.all(programsToAdd.map(async (p, index) => {
            try {
                await onProgramSave(p);
            }
            catch(err) {
                error = `Error on validating row ${index+1}, error: ${err}`
            }
        }))

        if(error) {
            setImportingError(error);
            setImportingStatus("error");
            return;
        }

        setImportingStatus("done");
    }
    function renderImportNotification() {
        
        if(!importingNotification || importingStatus == "init")
            return null;
        if(importingStatus == "importing") {
            return (
                <Notification loading withCloseButton={false} withBorder title="Importing Status">
                    We are processing the file and importing the programs
                </Notification>
            )
        }
        else if(importingStatus == "error") {
            return (
            <Notification icon={<IconX/>} color="red" withCloseButton={true} onClose={() => {setImportingNotification(false)}} withBorder title="Import Failed">
                {importingError}
            </Notification>
            )
        }
        else if(importingStatus == "done") {
            return (
            <Notification icon={<IconCheck/>} color="green" withCloseButton={true} onClose={() => {setImportingNotification(false)}} withBorder title="Import Successful">
                The programs where created and updated successfully.
            </Notification>
            )
        }
    }
    //The Program drawer onClose on purpose does nothing so it cannot be closes by accident.
    return (
        <>
            <Drawer pos={"relative"} withCloseButton={false} opened={programEdit?true:false} position="right" size={"xl"} onClose={() => {}}>
                <ProgramComponent program={programEdit as ProgramType} campuses={campuses} subjects={subjects} onSave={onProgramSave} onClose={() => {setProgramEdit(null)}} onDelete={onProgramDelete}/>
            </Drawer>
            <Drawer classNames={{content: styles.dataDrawerContent}} withCloseButton={false} opened={campusEdit?true:false} onClose={()=>{setCampusEdit(null)}} position="right" size={"xl"}>
                <CampusComponent campus={campusEdit as CampusType} onSave={onCampusSave} onClose={()=>{setCampusEdit(null)}} onDelete={onCampusDelete}/>
            </Drawer>
            <Drawer classNames={{content: styles.dataDrawerContent}} withCloseButton={false} opened={subjectEdit?true:false} onClose={()=>{setSubjectEdit(null)}} position="right" size={"xl"}>
                <SubjectPage subject={subjectEdit as SubjectModelType} onSave={onSubjectSave} onClose={()=>{setSubjectEdit(null)}} onDelete={(subject:SubjectModelType) => {}}/>
            </Drawer>
            <CSVImporter
                modalIsOpen={importOpen}
                modalOnCloseTriggered={() => setImportOpen(false)}
                darkMode={true}
                onComplete={(data) => {importProgramsFromCSV(data) }}
                template={{
                columns: ProgramImportColumns,
                }}
            />
            <MenuBarComponent title={"Programs"}>
                <Group>
                    <Button onClick={() => {setCampusEdit(getNewCampus())}} variant="light" leftSection={<IconPlus size={14}/>}>Add Campus</Button>
                    <Button onClick={() => {setProgramEdit(getNewProgram())}} leftSection={<IconPlus size={14}/>}>Add Program</Button>
                </Group>
            </MenuBarComponent>
            <div>

            </div>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <Tabs defaultValue={"programs"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value={"programs"}>
                            <Text size={"md"} fw={`${activeTab == "programs"? "bold":"normal"}`} c={`${activeTab == "programs"? "blue.7":"gray.7"}`}>Programs</Text>
                        </Tabs.Tab>
                        <Tabs.Tab value={"campuses"}>
                            <Text size={"md"} fw={`${activeTab == "campuses"? "bold":"normal"}`} c={`${activeTab == "campuses"? "blue.7":"gray.7"}`}>Campuses</Text>
                        </Tabs.Tab>
                        <Tabs.Tab value={"subjects"}>
                            <Text size={"md"} fw={`${activeTab == "subjects"? "bold":"normal"}`} c={`${activeTab == "subjects"? "blue.7":"gray.7"}`}>Subjects</Text>
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="programs">
                        
                        <Group py={"lg"}>
                            <TextInput
                                leftSectionPointerEvents="none"
                                leftSection={<IconSearch size={18}/>}
                                classNames={{input: styles.input}} 
                                placeholder="search program" 
                                disabled
                                />
                            <Button variant={"light"} color={"green"} onClick={() => setImportOpen(true)} leftSection={<IconTableImport/>}> Import Programs </Button>
                        </Group>
                        {importingNotification  && 
                        (<Stack my={"md"} align="center">
                           
                        {renderImportNotification()}
                        </Stack>
                        )}
                        <Paper className={styles.tableWrapper}>
                            <Table classNames={{table: styles.table}}>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Name</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Status</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Degree</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}># Study Options</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Campuses</Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                {!loadingPrograms && (
                                    <Table.Tbody>
                                        {programs.map((element) => {
                                            return (
                                                <Table.Tr key={element.id} >
                                                    <Table.Td ><Flex mih={40} pl={"md"} align={"center"}><Anchor onClick={() => { setProgramEdit(element)}} size="sm" fw="bold">{element.name}</Anchor></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}><Badge variant={"dot"} color={`${element.status == "draft"? "gray":(element.status == "published"? "green":"yellow")}`}>{element.status}</Badge></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}><Badge variant={"light"} color={`${DEGREE_BADGE_COLOR[element.degreeType]}`}>{element.degreeType}</Badge></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}><Text size={"sm"} c="dimmed">{element.studyOptions?.length}</Text></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}><Text size={"sm"} c="dimmed">{getCampusList(element).join(", ")}</Text></Flex></Table.Td>
                                                </Table.Tr>
                                            ) 
                                        })}
                                    </Table.Tbody>
                                )}
                            </Table>
                            {loadingPrograms && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(!loadingPrograms && programs.length == 0) && (
                                <Group w={"100%"} justify="center" py={"sm"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">You have 0 programs created.</Text>
                                </Group>
                            )}
                        </Paper>
                    </Tabs.Panel>
                    <Tabs.Panel value="campuses">
                    <Group py={"lg"}>
                            <TextInput
                                leftSectionPointerEvents="none"
                                leftSection={<IconSearch size={18}/>}
                                classNames={{input: styles.input}} 
                                placeholder="search campus"
                                disabled
                                />
                        </Group>
                        <Paper className={styles.tableWrapper}>
                            <Table classNames={{table: styles.table}}>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Name</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Country</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>State</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>City</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}># of Programs</Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                
                                    {!loadingCampuses && (
                                        <Table.Tbody>
                                            {campuses.map((value) => {
                                                return (
                                                <Table.Tr key={value.id} >
                                                    <Table.Td ><Flex mih={40} pl={"md"} align={"center"}><Anchor size="sm" onClick={() => {setCampusEdit(value)}} fw="bold">{value.name}</Anchor></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}>{value.location.country}</Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}>{value.location.state}</Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}>{value.location.city}</Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}>{}</Flex></Table.Td>
                                                  </Table.Tr>
                                                )
                                            })}

                                        </Table.Tbody>
                                    )}
                                
                            </Table>
                            {loadingCampuses && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(!loadingCampuses && campuses.length == 0) && (
                                <Group w={"100%"} justify="center" py={"md"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">You have 0 campuses created.</Text>
                                </Group>
                            )}
                        </Paper>
                    </Tabs.Panel>
                    <Tabs.Panel value="subjects">
                    <Group py={"lg"}>
                            <TextInput
                                leftSectionPointerEvents="none"
                                leftSection={<IconSearch size={18}/>}
                                classNames={{input: styles.input}} 
                                placeholder="search subjects"
                                disabled
                                />
                                <Button variant={"light"} color={"brand"} onClick={() => setSubjectEdit({name: "New Subject"})} leftSection={<IconPlus/>}> Add Subject </Button>
                        </Group>
                        <Paper className={styles.tableWrapper}>
                            <Table classNames={{table: styles.table}}>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Name</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}># of Programs</Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                
                                    {!loadingSubjects && (
                                        <Table.Tbody>
                                            {subjects.map((value) => {
                                                return (
                                                <Table.Tr key={value.id} >
                                                    <Table.Td ><Flex mih={40} pl={"md"} align={"center"}><Anchor size="sm" onClick={() => {setSubjectEdit(value)}} fw="bold">{value.name}</Anchor></Flex></Table.Td>
                                                    <Table.Td><Flex mih={40} align={"center"}>{}</Flex></Table.Td>
                                                  </Table.Tr>
                                                )
                                            })}

                                        </Table.Tbody>
                                    )}
                                
                            </Table>
                            {loadingCampuses && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(!loadingCampuses && campuses.length == 0) && (
                                <Group w={"100%"} justify="center" py={"md"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">You have 0 subjects created.</Text>
                                </Group>
                            )}
                        </Paper>
                    </Tabs.Panel>

                </Tabs>
            </Container>
        </>
    )

}