import { Box, Container, Flex, Title } from "@mantine/core"
import { ReactNode} from "react"

import styles from './PageHeader.module.scss';
import { useDocumentTitle } from "@mantine/hooks";

/**
 * Props for the PageHeader component.
 * @typedef {Object} PageHeaderProps
 * @property {string} title - The title to display in the header.
 * @property {ReactNode | null} children - Optional content to display on the right side of the header.
 */
type PageHeaderProps = {
    title:string,
    children: ReactNode | null
}

/**
 * PageHeader component to display a header section with a title and additional content.
 * 
 * The document title is updated dynamically based on the `title` prop.
 * 
 * @param {PageHeaderProps} props - The props for the component.
 * @param {string} props.title - The title of the page.
 * @param {ReactNode | null} [props.children=null] - Optional children to be displayed beside the title.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function PageHeader({title, children}:PageHeaderProps) {
    // Set the document title for the page
    useDocumentTitle(`Unichats - ${title}`);

    return (
        <Container size={"100%"} className={styles.pageHeaderContainer}>
            <Flex direction={"row"} align={"center"} h={"100%"} justify={"space-between"}>
            <Title order={1} size={'h2'} fw={900}>{title}</Title>
            <Box>
                {children}
            </Box>
            </Flex>
        </Container>
    )
}


export default PageHeader;