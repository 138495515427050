import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar } from '@mantine/core';
import { IconChevronRight, IconLink } from "@tabler/icons-react"
import _ from "lodash";

export type EnhancedLinkActionType = {
    content: string,
    link: string,
    image?: string,
}


export const EnhancedLinkDefault = {
    content: "Some Link Info",
    link: "https://"
}
export const EnhancedLinkActionSchema = [
    {
        target: "content",
        label: "Message",
        type: "rich_text",
    },
    {
        target: "link",
        label: "Link",
        type: "text",
    },
    {
        target: "image",
        label: "Image (optional)",
        type: "image",
    }
]



export function EnhancedLinkActionNode({id, action, isConnectable, edges}) {

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconLink size={10} />
                    </Avatar>
                    <Text  fw={"bold"} c={"gray.9"}>Enhanced Link</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
                <Paper  shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                    <Group justify={"space-between"} wrap="nowrap">
                        {action.image && <Avatar radius={"sm"} size={"lg"} src={action.image} />}
                        <div dangerouslySetInnerHTML={{__html: action.content}}></div>
                        <IconChevronRight size={20}/>
                    </Group>
                </Paper>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}

