import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { integrationSchema, IntegrationType } from "../../../schemas/integrationSchema";
import { useEffect, useMemo, useState } from "react";
import { Badge, Button, Container, Drawer, Flex, Grid, Group, Loader, Table, Text } from "@mantine/core";
import { MenuBarComponent } from "../../../components/MenuBar";

import integrations from './integrations';
import { IntegrationEditPage, IntegrationSchemaType } from "./EditPage";


export const IntegrationsIndexPage = () => {

    const { documents, loading, fetchDocuments, updateDoc, addDoc } = useFirestoreCollection('integrations', integrationSchema);
    const [ selection, setSelection ] = useState<IntegrationSchemaType | null>(null)

    useEffect(() => {
        fetchDocuments();
    }, []);

    
    const findIntegration = (query:string, type: string):IntegrationType | null => {
        if(loading || !documents || documents.length == 0)
            return null;

        const result = documents.find((doc) => { return doc.name == query && doc.type == type})
        if(!result)
            return null;

        return result;
    }

    return (
        <>
            <Drawer size={"lg"} opened={selection?true:false} onClose={() => setSelection(null)} position="right">
                {selection && (
                    <IntegrationEditPage schema={selection} data={findIntegration(selection.integrationId, selection.type)} update={updateDoc} add={addDoc} onClose={() => setSelection(null)}/>
                )}
            </Drawer>
            <MenuBarComponent title={"CRM Lead Integrations"}>
               <Group></Group>
            </MenuBarComponent>
            <Container size={"lg"} p={"md"} px={"xl"}>
                {loading && <Loader></Loader>}
                {!loading && (
                    <Table>
                        <Table.Thead bg={"gray.0"}>
                            <Table.Tr >
                                <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Integration</Text></Table.Th>
                                <Table.Th><Text fw={"bold"} size={"xs"}>Status</Text></Table.Th>
                                <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                                <Table.Th></Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                        {integrations.map((element, index) => {
                            const integration = findIntegration(element.integrationId, element.type);
                            return (
                                <Table.Tr key={index} >
                                            <Table.Td><Flex mih={40} align={"center"}><Text size="sm" fw={"bold"}>{element.name}</Text></Flex></Table.Td>
                                            <Table.Td ><Badge variant={"dot"} color={`${integration?.status == "active"? "green":"gray"}`}>{integration?.status || "inactive"}</Badge></Table.Td>
                                            <Table.Td> <Text size={"sm"} c={"dimmed"}>{element.type}</Text> </Table.Td>
                                            <Table.Td><Button onClick={() => setSelection(element)}>Edit</Button></Table.Td>
                                        </Table.Tr>
                            )
                        })}
                        </Table.Tbody>
                    </Table>
                )}
            </Container>
        </>
    )

}