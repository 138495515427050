import { ActionIcon, Anchor, Badge, Box, Button, Container, Drawer, Group, Paper, SegmentedControl, Tabs, Text, TextInput, Tooltip  } from "@mantine/core";

import { useEffect, useState } from "react";
import { IconPlus, IconSearch, IconTable, IconTableImport, IconX } from "@tabler/icons-react";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useDebouncedValue } from "@mantine/hooks";

import { campusSchema, CampusModelType } from "../../../models/Campus";
import { ProgramModelType } from "../../../models/Program";
import { CampusComponent } from "../../../../pages/data/Campus";

import { notifications } from '@mantine/notifications';


const PAGE_SIZE = 10;

type CampusIndexProps = {
    programs: ProgramModelType[],
    reload?():void
}

export function CampusIndexComponent({programs = [], reload = () => {}}: CampusIndexProps) {
    const { documents, loading, fetchDocuments, addDoc, updateDoc, deleteDoc } = useFirestoreCollection('campuses', campusSchema);
    const [ selectedDocuments, setSelectedDocuments] = useState<CampusModelType[]>([]);
    const [ paginatedDocuments, setPaginatedDocuments] = useState<CampusModelType[]>([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [selectedRows, setSelectedRows] = useState<CampusModelType[]>([]);
    const [programCountPerCampus, setProgramCountPerCampus] = useState<{[key:string]: number}>({})
    const [ campusEdit, setCampusEdit ] = useState<CampusModelType | null>(null);

    const [page, setPage] = useState(1);


    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<CampusModelType>>({
        columnAccessor: 'name',
        direction: 'asc',
      });

    

    useEffect(() => {
        fetchDocuments(1000);
    }, [])

    useEffect(() => {
        setSelectedDocuments(sortBy(filter(documents,({status}) => {return status != "archived"}), "name") as CampusModelType[]);
    }, [documents])

    useEffect(() => {
        calculateProgramCount();
    }, [documents, programs])

    useEffect(() => {
        const data = sortBy(selectedDocuments, sortStatus.columnAccessor) as CampusModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    useEffect(() => {
        let data = filter(documents, (doc) => {
            if(doc.status == "archived")
                return false;

            if (
                debouncedQuery !== '' &&
                !`${doc.name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
              )
            return false;

            return true;
        });
        data = sortBy(data, sortStatus.columnAccessor) as CampusModelType[];
        setSelectedDocuments(sortStatus.direction === 'desc' ? data.reverse() as CampusModelType[] : data as CampusModelType[]);
    }, [debouncedQuery])

    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setPaginatedDocuments(selectedDocuments.slice(from, to))
    }, [page, selectedDocuments])

    function calculateProgramCount() {
        const data:{[key:string]: number} = {}
        documents.map((c) => {
            const pc = filter(programs, (p) => {
                return p.studyOptions.some(option => option.campus && option.campus.id == c.id);
            })
            data[c.id || "blank"] = pc.length
        })
        setProgramCountPerCampus(data);
    }

    async function onCampusSave(campus:CampusModelType) {
        if(campus.id) {
            const _update_res = await updateDoc(campus.id, campus);
            if(_update_res) {
                notifications.show({
                    title: 'Update Successful',
                    message: `${campus.name} was updated successfully.`
                })
                reload();
                setCampusEdit(null);
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Update Unsuccessful',
                    message: `${campus.name} update had an issue.`
                })
                }
        }
        else {
            const _add_res = await addDoc(campus);
            if(_add_res) {
                notifications.show({
                    title: 'Added Successful',
                    message: `${campus.name} was added successfully.`
                })
                reload();
                setCampusEdit(null);
            }
            else {
                notifications.show({
                    color: "red",
                    title: 'Addition Unsuccessful',
                    message: `${campus.name} creation had an issue.`
                })
            }
            
        }
    }
    

    async function onCampusDelete(campus:CampusModelType) {
        const _del_res = await deleteDoc(campus.id || "");
        if(_del_res) {
            notifications.show({
                title: 'Deletion Successful',
                message: `${campus.name} was deleted successfully.`
            });
            reload();
            setCampusEdit(null);
        }
        else {
            notifications.show({
                color: "red",
                title: 'Deletion Unsuccessful',
                message: `${campus.name} deletion had an issue.`
            });
        }
    }


    return (
        <>
            <Drawer withCloseButton={false} opened={campusEdit?true:false} onClose={()=>{setCampusEdit(null)}} position="right" size={"xl"}>
                <CampusComponent campus={campusEdit as CampusModelType} onSave={onCampusSave} onClose={()=>{setCampusEdit(null)}} onDelete={onCampusDelete}/>
            </Drawer>
            <Group bg={"white"} p={"md"} justify="space-between" wrap="nowrap" h={72}>
                <Group wrap="nowrap" w={"100%"}>
                    <TextInput
                                    placeholder="Search subject by name"
                                    leftSection={<IconSearch size={16} />}
                                    w={"100%"}
                                    maw={300}
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                        <IconX size={14} />
                                        </ActionIcon>
                                    }/>
                                    
                                </Group>
                                <Group justify={"flex-end"}> <Button onClick={() => {setCampusEdit(campusSchema.parse({}))}} variant={"light"} color={"brand"} leftSection={<IconPlus/>}> Add a Campus </Button> </Group>
                            </Group>
                            <DataTable
                                withTableBorder
                                borderRadius="0"
                                withColumnBorders
                                highlightOnHover
                                fetching={loading}
                                selectedRecords={selectedRows}
                                onSelectedRecordsChange={setSelectedRows}
                                borderColor={"var(--mantine-color-gray-2"}
                                styles={{
                                    root: (theme) => ({
                                        border: `1px solid ${theme.colors.gray[1]}`,
                                    }),
                                    header: (theme) => ({
                                        backgroundColor: theme.colors.gray[0],
                                        height: "40px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                        fontSize: "small",
                                        color: theme.colors.gray[7],
                                        borderColor: theme.colors.gray[1]
                                    })
                                }}
                                rowStyle={({}) => (
                                    {
                                        height: "50px"
                                    }
                                )}
                                records={paginatedDocuments}
                                sortStatus={sortStatus}
                                onSortStatusChange={setSortStatus}
                                totalRecords={selectedDocuments.length}
                                recordsPerPage={PAGE_SIZE}
                                page={page}
                                onPageChange={(p) => setPage(p)}
                                idAccessor="name"
                                // define columns
                                columns={[
                                    
                                    { accessor: 'name',
                                        sortable: true,
                                        width: 380,
                                        render: (d) => (
                                            <Box maw={"100%"}><Anchor size="sm" onClick={() => setCampusEdit(d)}> <Tooltip label={d.name}><Text fw={"bold"} truncate="end">{d.name}</Text></Tooltip></Anchor></Box>
                                        )
                                    },
                                    {
                                        accessor: "location.country",
                                        title: "country",
                                        render: ({location}) => (
                                            <Text size={"sm"}>{location.country} </Text>
                                        )
                                    },
                                    {
                                        accessor: "location.state",
                                        title: "state",
                                        render: ({location}) => (
                                            <Text size={"sm"}>{location.state} </Text>
                                        )
                                    },
                                    {
                                        accessor: "location.city",
                                        title: "city",
                                        render: ({location}) => (
                                            <Text size={"sm"}>{location.city} </Text>
                                        )
                                    },
                                    
                                     { accessor: '# Programs',
                                        render: ({id}) => (
                                            <Text size={"sm"} c={"dimmed"}>{programCountPerCampus[id || ""] || "0"}</Text>
                                        )
                                     },
                                ]}
                                
                                />

        </>
    )

}