import styles from "./nodes.module.scss";
import React, { memo, useState } from 'react';
import { Edge, Handle, Position } from 'reactflow';
import { Paper, Avatar, Stack, Title, Group, Text } from '@mantine/core';  
import {IconPlayerPlayFilled, IconMessageCircle2Filled} from "@tabler/icons-react"
import { useEdges } from "reactflow";



import { MessageActionNode } from "../actions/MessageAction";
import { TriggerActionNode } from "../actions/TriggerAction";
import { SimpleSelectActionNode } from "../actions/SimpleSelectAction";
import { EnhancedLinkActionNode } from "../actions/EnhancedLinkAction";
import { InputActionNode } from "../actions/InputAction";
import { ActionType } from "../../../../models/@type.flow";
import { StartFlowActionNode } from "../actions/StartFlowAction";
import { FormSubmissionActionNode } from "../actions/FormSubmissionAction";
import { SelectedProgramsActionNode } from "../actions/SelectedProgramsAction";
import { BrochureActionNode } from "../actions/BrochureLinkAction";
import { ShowProgramsActionNode } from "../actions/ShowProgramsAction";

export type ActionNodeProps = {
  id: string,
  action: ActionType,
  isConnectable: boolean,
  edges: Edge[]
}

type ChatNodeProps = {
  data: {
    id: string,
    type: string,
    action: Object
  },
  isConnectable: boolean
}

export default memo(({ data, isConnectable }:ChatNodeProps) => {
  const [hover, setHover] = useState<boolean>(false)
  const edges = useEdges();
  
  function onMouseEnter() {
    setHover(true)
  }
  function onMouseLeave() {
    setHover(false)
  }


    return (
      <Paper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} shadow={"sm"} pb={"lg"} w={350} className={`${styles.node} ${styles.nodeChat} ${hover? styles.nodeHover: ""}`}>
        {(()=>{
            if(data.type == "message") {
                return <MessageActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "trigger") {
                return <TriggerActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "options") {
                return <SimpleSelectActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "link") {
              return <EnhancedLinkActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "input") {
              return <InputActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "start_flow") {
              return <StartFlowActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "form_submission") {
              return <FormSubmissionActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "selected_programs") {
              return <SelectedProgramsActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "brochure_link") {
              return <BrochureActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
            else if(data.type == "show_programs") {
              return <ShowProgramsActionNode key={data.id} id={data.id} action={data.action} isConnectable={isConnectable} edges={edges}/>
            }
        })()}
      </Paper>
    );
  });