import { z } from "zod";
import { BaseSchema } from "./Base";


export const SubjectSchema = BaseSchema.extend({
    name: z.string(),
}).catchall(z.any());

// TypeScript type inferred from the schema
export type SubjectModelType = z.infer<typeof SubjectSchema>;


