import { useContext, useEffect, useState } from "react";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import { MenuBarComponent } from "../../components/MenuBar";
import { Anchor, Badge, Button, Container, Flex, Group, Loader, Paper, ScrollArea, Table, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";

import styles from "./reports.module.scss";
import { getOrgFlow, getOrgRequests } from "../../services/database";
import { FlowType } from "../../models/@type.flow";
import { useParams } from "react-router-dom";


export function FormRequetsReportPage() {
    const {selected} = useContext(ConsoleContext) as ContextType;

    const params = useParams()

    const [form, setForm] = useState<FlowType | null>(null)
    const [header, setHeader] = useState<string[]>([])
    const [data, setData] = useState<{[key:string]: string | number | null | boolean | undefined}[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        if(!params.id )
            return;
        if(params.id == "ambassadors"){
            setForm({
                name: "Ambassador Chat",
                actions: [],
                status: "published",
                connections: [],
                type: "form"
            })
            getData("ambassador_chat");
        } 
        else {
            getForm(params.id as string)
        }
    },[])

    async function getForm(id:string) {
        const _form = await getOrgFlow(id, selected?.id || "") as FlowType;
        setForm(_form);
        getData(_form.form_id || "");
    }

    async function getData(id:string) {
        const _data = await getOrgRequests(id, selected?.id || "")
        setHeader(getUniqueKeys(_data))
        console.log(_data);
        setData(_data);
        setLoaded(true);
    }

    function getUniqueKeys(data:{[key:string]: string | number | null | boolean | undefined | Object | {}}[]):string[] {
        const _keys:string[] = []
        data.map((val) => {
            Object.keys(val.data || val).map((key) => {
                if(key != "type")
                _keys.push(key)
            });
        })
        return [... new Set(_keys)];
    }

    return (
        <>
            <MenuBarComponent title={`${form?.name} Requests`}>
                <Group>
                    <Button onClick={() => {}} variant="light" leftSection={<IconDownload size={14}/>}>Export Leads</Button>
                </Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <ScrollArea>
                <Group py={"lg"} className={styles.filterContainer}></Group>
                    <Paper className={styles.tableWrapper}>
                        <Table classNames={{table: styles.table}}>
                            <Table.Thead bg={"gray.0"}>
                               
                                    {loaded && (
                                         <Table.Tr>
                                            {header.map((val, index) => {
                                                return (
                                                    <Table.Th key={index} pl={index == 0? "lg": 0} py={"md"}><Text fw={"bold"} size={"xs"}>{val}</Text></Table.Th>
                                                )
                                            })}
                                         </Table.Tr>
                                    )}
                                    
                            </Table.Thead>
                            {loaded && (
                                <Table.Tbody>
                                    {data.map((val, index) => {
                                        return (
                                            <Table.Tr key={index}>
                                                {header.map((header, index2) => { 
                                                    return (
                                                        <Table.Td key={index2}><Flex mih={40} align={"center"}><Text pl={index2 == 0? "sm": 0} size={"sm"} c="dimmed">{header == "submittedAt"? ((new Date(val[header] as number)).toLocaleString()):(val.data?[header]:val[header]) }</Text></Flex></Table.Td>
                                                    )
                                                })}
                                            </Table.Tr>
                                        )
                                    })}
                                </Table.Tbody>
                            )}
                            {!loaded && (<Group w={"100%"} justify="center" py={"sm"}><Loader/></Group>)}
                            {(loaded && data.length == 0) && (
                                <Group w={"100%"} justify="center" py={"md"}>
                                    <Text c={"gray.9"} size="sm" fw="bold">There are no form submissions.</Text>
                                </Group>
                            )}
                        </Table>
                    </Paper>
                </ScrollArea>
            </Container>
        </>
    )
}