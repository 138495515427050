import { useContext, useEffect, useState } from "react";
import { SessionChatComponent } from "../../components/chats/SessionChat";
import { ExtendedChatSessionType } from "../../models/@type.chats";
import { ConsoleContext, ContextType } from "../../context/ConsoleContext";
import {  getOrgSessionsExtended } from "../../services/database";
import { MenuBarComponent } from "../../components/MenuBar";
import { Container, Group, Loader } from "@mantine/core";

export function ChatSessions() {
    const {selected} = useContext(ConsoleContext) as ContextType;
    const [sessions, setSessions] = useState<ExtendedChatSessionType[]>([]);
    const [initStatus, setInitStatus] = useState<boolean>(false);

    useEffect(()=> {
        getSessions();
    }, []);
    useEffect(() => {
        getSessions();
    }, [selected])

    async function getSessions() {
        const _sessions = await getOrgSessionsExtended(selected?.id || "");
        setSessions(_sessions)
        setInitStatus(true)
    }

    async function loadMore() {
        if(sessions.length > 0) {
            const _more = await getOrgSessionsExtended(selected?.id || "", sessions[sessions.length-1].session.createdAt);
            setSessions([...sessions, ..._more]);
        }
    }

    return (
        <>
        <MenuBarComponent title={"Conversations"}>
            <></>
        </MenuBarComponent>
        <Container pos={"relative"} h={"calc(100vh - 76px - 32px"} style={{overflow: "hidden"}} size={"xl"} p={"md"} px={"xl"}>
            {!initStatus? <Group p="xl" justify="center"><Loader></Loader></Group>:<SessionChatComponent clientId={selected?.id || ""} sessions={sessions} />}
        </Container>
        </>
    )

}