import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-datatable/styles.layer.css';


import {  useEffect, useContext } from 'react'
import { Routes, Route, BrowserRouter, Outlet, useNavigate } from "react-router-dom";
import { MantineProvider, createTheme, AppShell, Burger, Group, ScrollArea, Image, Code, Container } from '@mantine/core'

import { Notifications } from '@mantine/notifications';

import { NavBarComponent } from './layout/NavBar';

import { ConsoleContextProvider, ConsoleContext, ContextType } from './context/ConsoleContext';

import { IndexPage } from './pages/Index';
import { LoginPage } from './pages/accounts/LoginPage';
import { FlowsIndexPage } from './pages/bot/flows/Index';
import { FlowPage } from './pages/bot/flows/Flow';
import { BotConfigurationPage } from './pages/bot/Config';
import { InstallationCodePage } from './pages/InstallationCodePage';

import { KnowledgeIndexPage } from './pages/bot/knowledge/Index';
import { KnowledgePage } from './pages/bot/knowledge/Knowledge';

import { AmbassadorsIndexPage } from './pages/ambassadors/Index';

import { ProgramIndexPage } from './pages/data/Index';

import { LeadsReportPage } from './pages/reports/Leads';
import { FormsReportPage } from './pages/reports/Forms';
import { FormRequetsReportPage } from './pages/reports/FormRequests';

import { DashboardPage } from './pages/reports/Reports';
import { ScriptedConversationReportPage } from './pages/reports/Reports';
import { ConversationReportPage } from './pages/reports/Reports';

import { GeneralCRMConfiguration } from './pages/crm/configuration/Index';

import { Loading } from './components/Loading';
import { ChatSessions } from './pages/reports/ChatSessions';

import { FormIndexPage } from './pages/conversations/forms/IndexPage';

import { IntegrationsIndexPage } from './pages/crm/integrations/IndexPage';

import { LeadIndexPage } from './v2/pages/leads/IndexPage';

import { ProgramIndexPage as ProgramIndex } from './v2/pages/data/programs/IndexPage';

import { NoMatchReportPage } from './v2/pages/reports/NoMatchReportPage';
import MenuBar from './v2/components/MenuBar';

function ProtectedLayout() {
  
  const { initStatus, currentUser, opened, toggle } = useContext(ConsoleContext) as ContextType;
  const navigate = useNavigate();


  useEffect(() => {
    if(!initStatus) return;
    if(!currentUser) return navigate("/login")
  }, [currentUser, initStatus])

  if(initStatus) {
    return (
      <AppShell
        header={{ height: 0 }}
        navbar={{ width: !opened?260:55, breakpoint: 'sm' }}
        padding="0"
        layout='alt'
      >
        <Notifications position={"top-right"}/>
        <AppShell.Navbar bg={"gray.0"} withBorder={false}>
          <MenuBar/>
        </AppShell.Navbar>
        <AppShell.Main  >
          <Container w={"100%"} fluid p={0} pos={"relative"} mih={"100vh"}>
            <Outlet/>
            </Container>
        </AppShell.Main>
      </AppShell>
    )
  }
  else
    return <Loading/>
}

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    brand: 
     ["#ECECF8",
      "#CBCBEC",
      "#A9A9DF",
      "#8888D3",
      "#6666C7",
      "#4545BA",
      "#373795",
      "#292970",
      "#1C1C4A",
      "#0E0E25"
  ]
  },
  primaryColor: 'brand'
});

function App() {
  
  return (
    <MantineProvider theme={theme}>
      
        <BrowserRouter>
        <ConsoleContextProvider>
            <Routes>
              <Route element={<ProtectedLayout/>}>
                
                <Route path="/" element={<DashboardPage/>} />
                <Route path="/conversations">
                  <Route path="flows">
                    <Route index element={<FlowsIndexPage/>} />
                    <Route path=":id" element={<FlowPage/>} />
                  </Route>
                  <Route path="prompts" element={<BotConfigurationPage/>}/>
                  <Route path="widgets" element={<BotConfigurationPage/>}/>
                  <Route path="forms" element={<FormIndexPage/>}/>
                  <Route path="themes" element={<BotConfigurationPage/>}/>
                  <Route path="code" element={<InstallationCodePage/>}/>
                  <Route path="integrations" element={<IntegrationsIndexPage/>}/>
                </Route>
                
                <Route path="/knowledge/">
                  <Route index element={<KnowledgeIndexPage/>}/>
                  <Route path=":id" element={<KnowledgePage/>}/>
                </Route>
                <Route path="/ambassadors/">
                  <Route index element={<AmbassadorsIndexPage/>}/>
                </Route>
                
                <Route path="/leads/">
                <Route path="attributes" element={<GeneralCRMConfiguration/>}/>
                <Route path="all" element={<LeadsReportPage/>}/>
                <Route path="test" element={<LeadIndexPage/>}/>
                </Route>
                
                <Route path="/analytics">
                <Route path="sessions" element={<ChatSessions/>}/>
                <Route path="scripted-conversations" element={<ScriptedConversationReportPage/>}/>
                <Route path="conversations" element={<ConversationReportPage/>}/>
                </Route>

                <Route path="/reports">
                  <Route path="leads" element={<LeadsReportPage/>}/>
                  <Route path="forms" element={<FormsReportPage/>}/>
                  <Route path="forms/:id" element={<FormRequetsReportPage/>}/>
                  <Route path="no-match" element={<NoMatchReportPage/>}/>
                </Route>
                <Route path="/data">
                  <Route path="knowledge">
                    <Route index element={<KnowledgeIndexPage/>}/>
                    <Route path=":id" element={<KnowledgePage/>}/>
                  </Route>
                  <Route path="programs">
                    <Route index element={<ProgramIndexPage/>}/>
                  </Route>
                  <Route path="campuses">
                    <Route index element={<ProgramIndexPage/>}/>
                  </Route>
                  
                </Route>


                {/** VERSION 2 ROUTES  START*/}
                <Route path="apps/:clientId">
                  <Route path="overview" element={<DashboardPage/>} />
                  <Route path="conversations">
                    <Route path="flows">
                      <Route index element={<FlowsIndexPage/>} />
                      <Route path=":id" element={<FlowPage/>} />
                    </Route>
                    <Route path="prompts" element={<BotConfigurationPage/>}/>
                    <Route path="widgets" element={<BotConfigurationPage/>}/>
                    <Route path="theme" element={<BotConfigurationPage/>}/>
                    <Route path="forms" element={<FormIndexPage/>}/>
                    <Route path="fields" element={<GeneralCRMConfiguration/>}/>
                  </Route>
                  <Route path="leads">
                    <Route index element={<LeadIndexPage/>}/>
                  </Route>
                  <Route path="analytics">
                    <Route path="conversations" element={<ChatSessions/>}/>
                    <Route path="scripted" element={<ScriptedConversationReportPage/>}/>
                    <Route path="usage" element={<ConversationReportPage/>}/>
                  </Route>
                  <Route path="settings">
                    <Route path="installation" element={<InstallationCodePage/>}/>
                    <Route path="integrations" element={<IntegrationsIndexPage/>}/>
                  </Route>
                  <Route path="data">
                    <Route path="knowledge">
                      <Route index element={<KnowledgeIndexPage/>}/>
                      <Route path=":id" element={<KnowledgePage/>}/>
                    </Route>
                    <Route path="programs">
                      <Route index element={<ProgramIndex/>}/>
                    </Route>
                  </Route>
                </Route>
                {/** VERSION 2 ROUTES END */}
              </Route>
              <Route path="/login" element={<LoginPage/>} />
            </Routes> 
            </ConsoleContextProvider>
        </BrowserRouter>
    </MantineProvider>
  )
}

export default App
