import { useContext, useMemo } from "react";
import { useFetch } from '@mantine/hooks';
import { ConsoleContext, ContextType } from "../context/ConsoleContext";


const API_URL = import.meta.env.VITE_API_URL;

interface AnalyticsApiResponse {
    results: any[]; // Adjust the type as necessary
}

interface UseAnalyticsParams {
    metrics: string[];
    dimensions?: string[];
    startDate?: Date;
    endDate?: Date;
    filterBySessions?: string[];
    filterByDevices?: string[];
}

/**
 * Custom hook to fetch analytics data from an API with authentication.
 *
 * @param {UseAnalyticsParams} params - Parameters for fetching analytics data.
 * @param {string[]} [params.metrics] - The metric to be queried in the analytics data.
 * @param {string[]} [params.dimensions] - Optional dimensions to refine the query.
 * @param {Date} [params.startDate] - Optional start date for the query. Defaults to one month ago.
 * @param {Date} [params.endDate] - Optional end date for the query. Defaults to today.
 * @param {string[]} [params.filterBySessions] - Optional Session Ids to filter the query.
 * @param {string[]} [params.filterByDevices] - Optional Device Ids to filter the query.
 *
 * @returns {{
 *   data: any[];
 *   loading: boolean;
 *   error: any;
 *   refetch: () => void;
 *   abort: () => void;
 * }} The results of the analytics query, loading and error states, and control functions.
 */
export function useAnalytics({ metrics, dimensions, startDate, endDate, filterBySessions, filterByDevices }: UseAnalyticsParams) {
    const today = new Date();
    const { selected, token } = useContext(ConsoleContext) as ContextType
    

    // If clientId or token is not available, do not proceed with fetching data
    if (!selected || !token) {
        return { results: [], loading: false, error: new Error("No client selected"), refetch: () => {}, abort: () => {} };
    }

    // Calculate the start date. Defaults to one month ago if not provided.
    const start = useMemo(() => startDate || new Date(new Date().setMonth(today.getMonth() - 1)), [startDate, today]);

    // Calculate the end date. Defaults to today if not provided.
    const end = useMemo(() => endDate ? new Date(new Date(endDate.getTime()).setDate(endDate.getDate() + 1)) : new Date(new Date().setDate(today.getDate() + 1)), [endDate, today]);

    // Construct the API URL with query parameters.
    const url = useMemo(() => {
        const dimensionParams = dimensions ? dimensions.map(dimension => `&dimensions=${dimension}`).join("") : "";
        return `${API_URL}client/${selected.id}/analytics/query?metrics=${metrics.join(",")}${dimensionParams}&start=${start.toISOString().split('T')[0]}&end=${end.toISOString().split('T')[0]}&filterBySessions=${filterBySessions?.join(',')}&filterByDevices=${filterByDevices?.join(",")}`;
    }, [selected, metrics, dimensions, start, end, filterBySessions, filterByDevices]);

    // Set up the headers for the fetch request, including the Bearer token.
    const headers = useMemo(() => ({
        Authorization: `${token}`,
    }), [token]);

    // Fetch the analytics data using the constructed URL and headers.
    const { data, loading, error, refetch, abort } = useFetch<AnalyticsApiResponse>(url, { headers });

    // Extract the results from the fetched data.
    const results = useMemo(() => data?.results || [], [data]);

    return { data: results, loading, error, refetch, abort };
}