export type LocationType = {
    city: string,
    isoCode?: string,
    countryCode: string,
    country: string,
    stateCode: string,
    state: string
}

export type CampusType = {
    id?: string,
    name: string
    cover?: string,
    location: LocationType
}

export type StudyOptionType = {
    id?: string,
    schedule: "full-time" | "part-time",
    delivery: "in-person" | "online" | "blended",
    campus?: CampusType,
    duration: number,
    language: string,
    durationType: "weeks" | "months" | "years",
    intakes: string[]
}
export type SubjectType = {
    id?: string,
    name: string,
    url: string
}

export enum TuitionPeriodType {
    Monthly = "month",
    Yearly = "year",
    All = "all"
}

export enum TuitionTypeType {
    National = "national",
    International = "international",
    All = "all"
}

export type TuitionType = {
    amount: number,
    currency: string,
    type: TuitionTypeType,
    period: TuitionPeriodType,
    description?: string
}

const TuitionDefault = {
    amount: 10000,
    currency: "USD",
    type: TuitionTypeType.All,
    period: TuitionPeriodType.All,
    description: ""
}

export function getNewTuition():TuitionType {
    return {... TuitionDefault}
}

// to add -> You are -> You will get
export type ProgramType = {
    id?: string,
    cover?: string,
    internalId?: string,
    url?: string,
    brochure?: string,
    status: "draft" | "published" | "archived",
    name: string,
    internalName?: string,
    degreeType: "bachelor" | "master" | "mba" | "phd" | "diploma" | "associate_degree" | "major" | "executive_master" | "dba" | "executive_program" | "executive_course" | "executive_mba" | "online_course",
    overview: string,
    studyOptions: StudyOptionType[],
    subjects: string[],
    credits?: number,
    tuition?: TuitionType[]
}

const ProgramDefault:ProgramType = {
    status: "draft",
    name: "",
    url: "",
    internalName: "",
    degreeType: "bachelor",
    overview: "",
    studyOptions: [],
    subjects: []
}

const StudyOptionDefault:StudyOptionType = {
    schedule: "full-time",
    delivery: "in-person",
    duration: 24,
    durationType: "months",
    language: "english",
    intakes: []
}

const CampusDefault:CampusType = {
    name: "New Campus",
    location: {
        city: "",
        isoCode: "",
        country: "",
        countryCode: "",
        state: "",
        stateCode: ""
    }
}
const SubjectDefault:SubjectType = {
    name: "",
    url: ""
}

//Data Factories -- We make sure to send clones of the objects and not the default object itself.
export function getNewProgram():ProgramType {
    //return a copy of the default program
    return {... ProgramDefault}
}

export function getNewStudyOption(defaultCampus:CampusType):StudyOptionType {
    return {... StudyOptionDefault, campus: defaultCampus}
}

export function getNewCampus() {
    return {... CampusDefault}
}

export function getNewSubject() {
    return {... SubjectDefault}
}