import { useContext, useState } from "react"
import { CampusType, ProgramType, TuitionPeriodType, TuitionType, TuitionTypeType, getNewStudyOption, getNewTuition } from "../../models/@type.data"
import { Flex, MultiSelect, Box, Group, Title, Text, ScrollArea, Button, ActionIcon, Select, Stack, TextInput, Paper, Table, Transition, ComboboxItem, NumberInput, Notification } from "@mantine/core"

import styles from "./data.module.scss"
import { IconCash, IconCheck, IconPlus, IconTrash, IconX } from "@tabler/icons-react"
import _ from "lodash"
import { RichTextInput } from "../../components/RichTextInput"
import { ImageFieldComponent } from "../../components/ImageField"
import { ConsoleContext, ContextType } from "../../context/ConsoleContext"
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection"
import { SubjectModelType } from "../../v2/models/Subject"
import { ProgramModelType } from "../../v2/models/Program"
import { CampusModelType } from "../../v2/models/Campus"

type ProgramComponentProps = {
    program: ProgramModelType,
    campuses?: CampusModelType[],
    subjects?: SubjectModelType[],
    onClose():void,
    onSave(program:ProgramModelType):void,
    onDelete(program:ProgramModelType):void
}

const ERROR_MESSAGES = {
    NAME: "The program name cannot be empty.",
    STUDY_OPTIONS: "The program must have at least one study option."
}

export function ProgramComponent({program, campuses=[], subjects=[], onClose, onSave, onDelete}:ProgramComponentProps) {
    const { selected } = useContext(ConsoleContext) as ContextType
    const [ data, setData ] = useState<ProgramType>(_.cloneDeep(program));
    console.log(program);
    const [ change, setChange ] = useState<boolean>(false);
    const [ deletePrompt, setDeletePrompt ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null)

    const [ studyOptionToDelete, setStudyOptionToDelete ] = useState<number | null>(null)
    const [ tuitionOptionToDelete, setTuitionOptionToDelete ] = useState<number | null>(null)

    



    function onProgramSave() {
        //run validations
        if(!data.name) {
            setError(ERROR_MESSAGES.NAME);
            return;
        }
        else if(data.studyOptions.length == 0) {
            setError(ERROR_MESSAGES.STUDY_OPTIONS)
            return;
        }
        data.internalName = data.name.toLowerCase().replace(/ /g, "_");
        setError(null)
        onSave(data);
    }


    function onValueChange(field: string, value: string | string[]) {
        const _data = _.cloneDeep(data);
        _data[field] = value;
        setData(_data)
        setChange(true);
    }

    function onTuitionOptionValueChange(index:number, field: keyof TuitionType, value:any) {
        const _data = _.cloneDeep(data);
        _data.tuition[index][field] = value;
        setData(_data);
        setChange(true);
    }

    function onTuitionOptionAdd() {
        const _data = _.cloneDeep(data);
        if(!_data.tuition) {
            _data.tuition = [];
        } 
        _data.tuition?.push(getNewTuition())
        setData(_data);
        console.log(_data);
        setChange(true);
    }

    function onTuitionOptionRemove(index:number) {
        const _data = _.cloneDeep(data);
        _data.tuition?.splice(index, 1);
        setData(_data);
        setStudyOptionToDelete(null);
        setChange(true);
    }
    

    function onStudyOptionValueChange(index:number, field: string, value:string | CampusType | number) {
        const _data = _.cloneDeep(data);
        if(field == "campus") {
            const _campus = _.cloneDeep(value as CampusType)
            delete _campus.snapshot;
            _data.studyOptions[index][field] = _campus;
        }
        else { 
            _data.studyOptions[index][field] = value;
        }
        setData(_data);
        setChange(true);
    }

    function onStudyOptionAdd() {
        const _data = _.cloneDeep(data);
        const _campus = _.cloneDeep(campuses[0] || {})
        delete _campus.snapshot;
        _data.studyOptions.push(getNewStudyOption(_campus))
        setData(_data);
        setChange(true);
    }

    function onStudyOptionRemove(index:number) {
        const _data = _.cloneDeep(data);
        _data.studyOptions.splice(index, 1);
        setData(_data);
        setStudyOptionToDelete(null);
        setChange(true);
    }

    return (
        <Flex pos={"relative"} key={program?.id || "new_program"} px={"lg"} py={0} direction={"column"} w={"100%"} className={styles.dataWrapper}>
            {deletePrompt && (
                <Stack style={{zIndex: 11}} bg={"white"} align="center" justify="center" pos="absolute" top={0} w={"100%"} bottom={0} left={0}>
                    <Text fw={"bold"}>Are you sure?</Text>
                    <Button onClick={() => {onDelete(program); setDeletePrompt(false);}} w={150} color={"red"} leftSection={<IconTrash size={18}/>}>Yes Delete</Button>
                    <Button onClick={() => {setDeletePrompt(false);}} w={150} color={"gray"} variant="light" leftSection={<IconX size={18}/>}>Cancel</Button>
                </Stack>
            )}
            
            <Group pb={"sm"} justify={"space-between"} className={styles.dataHeader}>
                <Title maw={250} lineClamp={1} order={4} c={"gray.8"}>{data.name}</Title>
                <Group> 
                    <ActionIcon onClick={() => {setDeletePrompt(true)}} variant="transparent" c={"red"}><IconTrash size={18}/></ActionIcon>
                    
                    <Select onChange={(value) => {onValueChange("status", value || "draft")}} withCheckIcon={false} maw={110} value={data.status} classNames={{input: `${styles.programStatusSelect} ${data.status=="published"?styles.programStatusPublished:""}`}} data={[{value: "draft", label: "Draft"}, {value: "published", label: "Published"}]}></Select>
                    <Button onClick={onProgramSave} disabled={!change} size="sm" variant={"filled"}>Save</Button>
                    <Button size={"sm"} variant="light" onClick={onClose}>Close {change? "without Saving":""}</Button>
                </Group>
            </Group>
            <Box pos={"relative"} maw={"100%"} style={{ flexGrow: 1, overflow: 'auto', overflowX: "hidden" }}>
                
                    <Transition
                            mounted={error?true:false}
                            transition="scale"
                            duration={200}
                            timingFunction="ease">
                            {(styles) => 
                                <Notification mb={"lg"} withBorder style={styles} icon={<IconX size={20}/>} color="red" title={"Error"} onClose={() => { setError(null)}}>
                                    {error}
                                </Notification>
                            }
                    </Transition>
                <Paper bg={"gray.0"} py={"md"} px={"sm"} radius={"md"}>
                    <Title mb={"xs"} c={"gray.8"} order={5}>Basic Information</Title>
                    <Stack >  
                        <TextInput
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Name"
                            size="md"
                            placeholder="Program Name"
                            value={data.name}
                            onChange={(e) => {onValueChange("name", e.target.value)}}
                            />
                        <Select 
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Degree Type"
                            size="md"
                            value={data.degreeType}
                            withCheckIcon={false}
                            data={[
                                {group: "Undergraduate", items:[
                                    {value: "bachelor", label:"Bachelor"},
                                    {value: "associate_degree", label:"Associate Degree"},
                                    {value: "major", label: "Major"},
                                    {value: "minor", label: "Minor"},
                                    {value: "undergraduate_diploma", label: "Undergraduate Diploma"},
                                ]},
                                {group: "Graduate", items: [
                                    {value: "master", label: "Master"},
                                    {value: "mba", label: "MBA"},
                                    {value: "phd", label: "PhD"},
                                    {value: "dba", label: "DBA"},
                                    {value: "graduate_diploma", label: "Graduate Diploma"}
                                ]},
                                {group: "Executive", items: [
                                    {value: "executive_master", label: "Executive Master"},
                                    {value: "executive_mba", label: "Executive MBA"},
                                    {value: "executive_program", label: "Executive Program"},
                                    {value: "executive_course", label: "Executive Course"}
                                ]},
                                {group: "Other", items: [
                                    {value: "online_course", label: "Online Course"},
                                ]}     
                            ]}
                            onChange={(value) => {onValueChange("degreeType", value || "bachelor")}}
                        />
                        <MultiSelect
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            size="md"
                            label="Subjects"
                            value={data.subjects || []}
                            data={subjects.map((s) => {return {label: s.name, value: s.id || ""}})}
                            onChange={(value) => {onValueChange("subjects", value)}}
                            searchable
                            checkIconPosition="right"
                       />
                        <div>
                            <label className={styles.dataInputLabel}>Cover Image</label>
                            <ImageFieldComponent 
                                id={`${data.id}-overview`}
                                value={data.cover || ""}
                                onChange={(value) => {onValueChange("cover", value)}}
                                namespace="programs"
                                clientId={selected?.id || ""}
                                placeholder="Cover Image"
                            />
                        </div>
                        <TextInput
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Internal Id"
                            size="md"
                            placeholder="id"
                            value={data.internalId}
                            onChange={(e) => {onValueChange("internalId", e.target.value)}}
                            />
                            <TextInput
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Website Link"
                            size="md"
                            placeholder="Link"
                            value={data.url}
                            onChange={(e) => {onValueChange("url", e.target.value)}}
                            />
                        <TextInput
                            classNames={{
                                label: styles.dataInputLabel,
                                input: styles.input
                            }}
                            label="Brochure Link"
                            size="md"
                            placeholder="Link"
                            value={data.brochure}
                            onChange={(e) => {onValueChange("brochure", e.target.value)}}
                            />
                        <div>
                            <label className={styles.dataInputLabel}>Overview</label>
                            <RichTextInput
                                className={styles.input}
                                id={`${data.id}-overview`}
                                value={data.overview}
                                onChange={(value:string) => {onValueChange("overview", value)}}
                            />
                        </div>
                        
                        
                    </Stack>
                </Paper>

                <Group justify="space-between" px={"sm"} mt={"xl"} mb={"xs"}><Title  c={"gray.8"} order={5}>Tuition Fees</Title>
                        <Button onClick={onTuitionOptionAdd} leftSection={<IconPlus size={14}/>} size={"xs"} color={"black"}> Add Tuition Option</Button>
                    </Group>
                    <ScrollArea maw={"100%"} w={"100%"} >
                    <Paper className={styles.tableWrapper} mb={"xl"}>
                        
                            <Table w={"100%"} classNames={{table: styles.table}} withColumnBorders>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Amount</Text></Table.Th>
                                    <Table.Th pl={"lg"}><Text fw={"bold"} size={"xs"}>Currency</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Type</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Period</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}></Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.tuition?.map((value, index) => {
                                        return (
                                            <Table.Tr p={0} key={index} pos={"relative"}>
                                                <Table.Td miw={120} maw={150} p={0} pl={"xs"}>
                                                    <NumberInput
                                                    classNames={{input:styles.studyOptionInput}}
                                                    w={"100%"}
                                                    placeholder={"Tuition Amount"}
                                                    value={value.amount}
                                                    rightSection={<></>}
                                                    onChange={(value) => onTuitionOptionValueChange(index, "amount", value)}
                                                    />

                                                </Table.Td>
                                                <Table.Td miw={120} maw={150}>
                                                <Select
                                                        classNames={{input:styles.studyOptionInput}}
                                                        w={"100%"}
                                                        withCheckIcon={false}
                                                        value={value.currency || "USD"}
                                                        onChange={(value)=>{onTuitionOptionValueChange(index, "currency", value || "")}}
                                                        radius={0}
                                                        data={[
                                                            "USD",
                                                            "GBP",
                                                            "EUR"
                                                        ]}
                                                    />

                                                </Table.Td>
                                                <Table.Td miw={110}>
                                                <Select
                                                    classNames={{input:styles.studyOptionInput}}
                                                        w={"100%"}
                                                        withCheckIcon={false}
                                                        value={value.type || "all"}
                                                        onChange={(value)=>{onTuitionOptionValueChange(index, "type", value || "all")}}
                                                        radius={0}
                                                        data={(Object.keys(TuitionTypeType) as Array<keyof typeof TuitionTypeType>).map((key) => { return {label: key, value: TuitionTypeType[key]}})}
                                                    />
                                                    
                                                </Table.Td>
                                                <Table.Td miw={110}>
                                                <Select 
                                                    classNames={{
                                                        input: styles.studyOptionInput
                                                    }}
                                                    w={"100%"}
                                                    value={value.period || "all"}
                                                    withCheckIcon={false}
                                                    onChange={(value)=>{onTuitionOptionValueChange(index, "period", value || "all")}}
                                                    radius={0}
                                                    data={(Object.keys(TuitionPeriodType) as Array<keyof typeof TuitionPeriodType>).map((key) => { return {label: key, value: TuitionPeriodType[key]}})}
                                                />
                                                </Table.Td>
                                              
                                                <Table.Td miw={42} maw={42} w={42}>
                                                    <ActionIcon onClick={() => {setTuitionOptionToDelete(index)}} size={"xs"} variant="transparent" c={"red"}><IconTrash/></ActionIcon>
                                                    <Transition
                                                        mounted={tuitionOptionToDelete == index}
                                                        transition="fade"
                                                        duration={400}
                                                        timingFunction="ease"
                                                    >
                                                    {(styles) => 
                                                        <Group pos={"absolute"} right={0} h={"100%"} top={0} bg={"gray.1"} w={"100%"} style={{...styles, zIndex:10}} px={"md"} justify="center"> 
                                                            <Text size={"xs"}>Are you sure?</Text> 
                                                            <ActionIcon onClick={() => {onTuitionOptionRemove(index)}} size={"xs"} variant="light" color={"red"}><IconCheck/></ActionIcon>
                                                            <ActionIcon onClick={() => {setTuitionOptionToDelete(null)}} size={"xs"} variant="light" color={"gray.9"}><IconX/></ActionIcon>
                                                        </Group>
                                                    }
                                                    </Transition>
                                                </Table.Td>
                                            </Table.Tr>
                                        )
                                    })}
                                </Table.Tbody>
                            </Table>
                            
                        </Paper>
                    </ScrollArea>
                
                    <Group justify="space-between" px={"sm"} mt={"xl"} mb={"xs"}><Title  c={"gray.8"} order={5}>Study Options</Title>
                        <Button onClick={onStudyOptionAdd} leftSection={<IconPlus size={14}/>} size={"xs"} color={"black"}> Add Study Option</Button>
                    </Group>
                    <ScrollArea maw={"100%"} w={"100%"} >
                    <Paper className={styles.tableWrapper} mb={"xl"}>
                        
                            <Table w={"fit-content"}  classNames={{table: styles.table}} withColumnBorders>
                                <Table.Thead bg={"gray.0"}>
                                    <Table.Tr >
                                    <Table.Th pl={"lg"} py={"md"}><Text fw={"bold"} size={"xs"}>Schedule</Text></Table.Th>
                                    <Table.Th pl={"lg"}><Text fw={"bold"} size={"xs"}>Delivery</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Campus</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Language</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Intakes</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Duration</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}>Duration Type</Text></Table.Th>
                                    <Table.Th><Text fw={"bold"} size={"xs"}></Text></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.studyOptions.map((value, index) => {
                                        return (
                                            <Table.Tr p={0} key={index} pos={"relative"}>
                                                <Table.Td miw={120} maw={150} p={0} pl={"xs"}>
                                                    <Select
                                                        classNames={{input:styles.studyOptionInput}}
                                                        w={"100%"}
                                                        comboboxProps={{ shadow: 'md', middlewares: { flip: false, shift: false }, offset: 0 }}
                                                        withCheckIcon={false}
                                                        value={value.schedule}
                                                        onChange={(value)=>{onStudyOptionValueChange(index, "schedule", value || "")}}
                                                        radius={0}
                                                        data={[
                                                            {value: "full-time", label: "Full-Time"},
                                                            {value: "part-time", label: "Part-Time"}
                                                        ]}
                                                    />
                                                </Table.Td>
                                                <Table.Td miw={120} maw={150}>
                                                <Select
                                                        classNames={{input:styles.studyOptionInput}}
                                                        w={"100%"}
                                                        withCheckIcon={false}
                                                        value={value.delivery}
                                                        onChange={(value)=>{onStudyOptionValueChange(index, "delivery", value || "")}}
                                                        radius={0}
                                                        data={[
                                                            {value: "in-person", label: "In-Person"},
                                                            {value: "online", label: "Online"},
                                                            {value: "blended", label: "Blended"}
                                                        ]}
                                                    />

                                                </Table.Td>
                                                <Table.Td miw={110}>
                                                <Select
                                                    classNames={{input:styles.studyOptionInput}}
                                                        w={"100%"}
                                                        withCheckIcon={false}
                                                        value={value.campus?.id}
                                                        onChange={(value, option)=>{onStudyOptionValueChange(index, "campus", (option as {label:string, value:string, campus:CampusType})?.campus || {})}}
                                                        radius={0}
                                                        data={campuses.map((value) => {return {label: value.name, value: value.id, campus: value} as ComboboxItem})}
                                                    />
                                                    
                                                </Table.Td>
                                                <Table.Td miw={110}>
                                                <Select 
                                                    classNames={{
                                                        input: styles.studyOptionInput
                                                    }}
                                                    w={"100%"}
                                                    value={value.language || "english"}
                                                    withCheckIcon={false}
                                                    onChange={(value)=>{onStudyOptionValueChange(index, "language", value || "english")}}
                                                    radius={0}
                                                    data={[
                                                        {value: "english", label:"English"},
                                                        {value: "spanish", label: "Spanish"},
                                                        {value: "italian", label: "Italian"}
                                                    ]}
                                                />
                                                </Table.Td>
                                                <Table.Td miw={100}>
                                                <MultiSelect
                                                    variant="unstyled"
                                                    maw={100}
                                                    value={value.intakes}
                                                    onChange={(value) => {
                                                        const _data = _.cloneDeep(data);
                                                        _data.studyOptions[index].intakes = value;
                                                        setData(_data);
                                                        setChange(true);
                                                    }}
                                                    checkIconPosition="right"
                                                    data={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                                                    />
                                            
                                                </Table.Td>
                                                
                                                <Table.Td miw={40} maw={40}>
                                                    <NumberInput
                                                        variant="unstyled"
                                                        value={value.duration}
                                                        onChange={(value) => {
                                                            onStudyOptionValueChange(index, "duration", value);
                                                        }}
                                                        />
                                                </Table.Td>
                                                <Table.Td miw={100} maw={100}>
                                                <Select 
                                                    classNames={{
                                                        input: styles.studyOptionInput
                                                    }}
                                                    w={"100%"}
                                                    value={value.durationType || "months"}
                                                    withCheckIcon={false}
                                                    onChange={(value)=>{onStudyOptionValueChange(index, "durationType", value || "months")}}
                                                    radius={0}
                                                    data={[
                                                        {value: "weeks", label:"Weeks"},
                                                        {value: "months", label: "Months"},
                                                        {value: "days", label: "Days"},
                                                        {value: "years", label: "Years"},
                                                        {value: "terms", label: "Terms"},
                                                    ]}
                                                />
                                                </Table.Td>
                                                <Table.Td miw={42} maw={42} w={42}>
                                                    <ActionIcon onClick={() => {setStudyOptionToDelete(index)}} size={"xs"} variant="transparent" c={"red"}><IconTrash/></ActionIcon>
                                                    <Transition
                                                        mounted={studyOptionToDelete == index}
                                                        transition="fade"
                                                        duration={400}
                                                        timingFunction="ease"
                                                    >
                                                    {(styles) => 
                                                        <Group pos={"absolute"} right={0} h={"100%"} top={0} bg={"gray.1"} w={"100%"} style={{...styles, zIndex:10}} px={"md"} justify="center"> 
                                                            <Text size={"xs"}>Are you sure?</Text> 
                                                            <ActionIcon onClick={() => {onStudyOptionRemove(index)}} size={"xs"} variant="light" color={"red"}><IconCheck/></ActionIcon>
                                                            <ActionIcon onClick={() => {setStudyOptionToDelete(null)}} size={"xs"} variant="light" color={"gray.9"}><IconX/></ActionIcon>
                                                        </Group>
                                                    }
                                                    </Transition>
                                                </Table.Td>
                                            </Table.Tr>
                                        )
                                    })}
                                </Table.Tbody>
                            </Table>
                            
                        </Paper>
                    </ScrollArea>
            </Box>
        </Flex>
    )
}