
import { useNavigate } from "react-router-dom"
import { addOrgKnowledge } from "../../../services/database"
import { useContext, useState } from "react"
import { ConsoleContext, ContextType } from "../../../context/ConsoleContext"

import { Button, Stack, TextInput } from "@mantine/core"
import { KnowledgeType } from "../../../models/@type.knowledge"

export function AddKnowledge() {
    const { selected } = useContext(ConsoleContext) as ContextType
    const navigate = useNavigate()
    const [name, setName] = useState<string>("")

    async function add() {
        if(!name)
            return;

        const _add:KnowledgeType = {
            name: name,
            status: "draft",
            content: ""
        }
        const _res = await addOrgKnowledge(_add, selected?.id || "")
        if(_res)
            navigate(`/data/knowledge/${_res}`);
    }

    return (
        <Stack gap={"lg"}>
             <TextInput
                label="Knowledge Name"
                description="You can think of the name as the main topic of the document."
                placeholder="Name"
                value={name}
                onChange={(evt) => {setName(evt.target.value)}}
            />
            <Button onClick={add}>Add Knowledge</Button>
        </Stack>
    )
}