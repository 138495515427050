import styles from './actions.module.scss'
import { Edge, Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar, Fieldset, SegmentedControl, MultiSelect, Divider, Loader } from '@mantine/core';
import { IconMessageCircle2Filled } from "@tabler/icons-react"
import { cloneDeep, findIndex } from "lodash";
import { useFirestoreCollection } from '../../../../hooks/useFirestoreCollection';
import { SubjectSchema } from '../../../../v2/models/Subject';
import { useEffect } from 'react';
import { programSchema, StudyOptionDeliveryOptions, StudyOptionScheduleOptions } from '../../../../v2/models/Program';
import { campusSchema } from '../../../../v2/models/Campus';

export type ShowProgramsActionType = {
    type: "manual" | "filters",
    manualPrograms: string[],
    filters: {
        degreeType: string[],
        subjects: string[],
        campuses: string[],
        schedule: string[],
        delivery: string[]
    }
}
type ShowProgramsEditProps = {
    id: string,
    data: object,
    onChange(id:string, data: object):void,
    onDelete(id:string):void,
    clientId: string
}

export const ShowProgramsActionSchema = [
    {

    }
]

export const ShowProgramsActionDefault:ShowProgramsActionType = {
    type: "manual",
    manualPrograms: [],
    filters: {
        degreeType:[],
        subjects: [],
        campuses: [],
        schedule: [],
        delivery: []
    }
}

const degrees = [
    { label: "Bachelor", value: "bachelor" },
    { label: "Master", value: "master" },
    { label: "MBA", value: "mba" },
    { label: "PhD", value: "phd" },
    { label: "Diploma", value: "diploma" },
    { label: "Associate Degree", value: "associate_degree" },
    { label: "Major", value: "major" },
    { label: "Executive Master", value: "executive_master" },
    { label: "DBA", value: "dba" },
    { label: "Executive Program", value: "executive_program" },
    { label: "Executive Course", value: "executive_course" },
    { label: "Executive MBA", value: "executive_mba" },
    { label: "Online Course", value: "online_course" }
  ];
export function ShowProgramsActionEdit({id, data, onChange, onDelete, clientId = ""}:ShowProgramsEditProps) {
    const values:ShowProgramsActionType = (data as {action:ShowProgramsActionType}).action
    const { documents:subjects, loading: loadingSubjects, fetchDocuments: fetchSubjects } = useFirestoreCollection('subjects', SubjectSchema);

    const { documents:programs, loading: loadingPrograms, fetchDocuments: fetchPrograms } = useFirestoreCollection('programs', programSchema);
    const { documents:campuses, loading: loadingCampuses, fetchDocuments: fetchCampuses } = useFirestoreCollection('campuses', campusSchema);

    useEffect(() => {
        fetchSubjects();
        fetchPrograms();
        fetchCampuses();
    }, [])

    function onFieldChange(target: keyof ShowProgramsActionType, value:any) {
        const _action = cloneDeep((data as {action:ShowProgramsActionType}).action);
        _action[target] = value;
        onChange(id, _action)
    }

    function onTypeChange(val:string) {

        const _action = cloneDeep((data as {action:ShowProgramsActionType}).action);
        if(val == "manual") {
            _action.type = "manual";
            _action.filters = {
                degreeType:[],
                subjects: [],
                campuses: [],
                schedule: [],
                delivery: []
            }
        }
        else {
            _action.type = "filters";
            _action.manualPrograms = []
        }
        onChange(id, _action)
    }

    function onFilterChange(target: "subjects" | "degreeType" | "campuses" | "schedule" | "delivery", val: string[]) {
        const _action = cloneDeep(values);
        _action.filters[target] = val;
        onChange(id, _action)
    }
    if(loadingSubjects || loadingPrograms || loadingCampuses)
        return <Loader/>

    return (
       <Fieldset key={id}>
            <div className={styles.inputField}>
                <label className={styles.label}>Type</label>
                <SegmentedControl value={values.type} data={['manual', 'filters']} onChange={(val)=> { onTypeChange(val)} } />
            </div>
            <Divider mb={"lg"}/>
            {values.type == "manual"? (
                <><MultiSelect
                value={values.manualPrograms}
                data={programs.map((p) => { return {label: (p.name || ""), value: p.id as string}})}
                onChange={(vals) => {onFieldChange('manualPrograms', vals)}}
                classNames={{label: styles.label}} 
                checkIconPosition='right' size={"md"} 
                label={"Programs"} 
                searchable/></>
            ):(
                <Stack>
                    <Text size={"xs"}>Keeping one of the select empty is equivalent as not setting any filter for that option</Text>
                    <MultiSelect
                        classNames={{label: styles.label}}
                        value={values.filters.degreeType}
                        data={degrees}
                        onChange={(vals) => {onFilterChange("degreeType", vals)}}
                        checkIconPosition='right' size={"md"} label={"Degree"} searchable
                    />
                    <MultiSelect
                        classNames={{label: styles.label}}
                        value={values.filters.subjects}
                        data={subjects.map((s) => {return {label: s.name, value: s.id as string}})}
                        onChange={(vals) => {onFilterChange("subjects", vals)}}
                        checkIconPosition='right' size={"md"} label={"Subjects"} searchable
                    />
                    <MultiSelect
                        classNames={{label: styles.label}}
                        value={values.filters.campuses}
                        data={campuses.map((s) => {return {label: s.name, value: s.id as string}})}
                        onChange={(vals) => {onFilterChange("campuses", vals)}}
                        checkIconPosition='right' size={"md"} label={"Campus"} searchable
                    />
                    <MultiSelect
                        classNames={{label: styles.label}}
                        value={values.filters.schedule}
                        data={StudyOptionScheduleOptions}
                        onChange={(vals) => {onFilterChange("schedule", vals)}}
                        checkIconPosition='right' size={"md"} label={"Schedule"} searchable
                    />
                    <MultiSelect
                        classNames={{label: styles.label}}
                        value={values.filters.delivery}
                        data={StudyOptionDeliveryOptions}
                        onChange={(vals) => {onFilterChange("delivery", vals)}}
                        checkIconPosition='right' size={"md"} label={"Delivery"} searchable
                    />
                </Stack>
            )}
       </Fieldset> 
    )
}


type ActionNodeProps = {
    id: string,
    action: Object,
    isConnectable: boolean,
    edges: Edge[]
}

export function ShowProgramsActionNode({id, action, isConnectable, edges}:ActionNodeProps) {
    const _action = (action as ShowProgramsActionType);

    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent',  borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4}>
                    <Avatar color={"gray.9"} size={18}>
                    <IconMessageCircle2Filled size={10} />
                    </Avatar>
                    <Text fw={"bold"} c={"gray.9"}>Show Programs</Text>
                </Group>
            </Stack>
            <Stack px={"lg"} mt={"lg"}>
            <Text># Programs: {_action.type}</Text>
            </Stack>
            <Handle
                type="source"
                id={`${id}-source`}
                position={Position.Right}
                className={`${findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                style={{ background: 'var(--mantine-color-gray.9)', width: "10px", height: "10px" }}
                isConnectable={isConnectable}
            />
        </>
    )

}