import { ReactNode, useEffect, useState } from "react";
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { LeadModelType, LeadSchema } from "../../models/Lead";
import PageHeader from "../../components/PageHeader";
import { Button, Container, Group, Loader, rem, TextInput, Text, Select, Avatar, Stack, Anchor, Paper, Drawer } from "@mantine/core";
import styles from './leads.module.scss'
import { IconDownload, IconSearch, IconTable } from "@tabler/icons-react";


import pageStyles from '../page.module.scss'
import { LeadPage } from "./LeadPage";
import { DataTable } from "mantine-datatable";
import { DownloadLeadsAsCSVButton } from "./DownloadLeadsAsCSVButton";



export function LeadIndexPage() {
    const [ pageSize, setPageSize ] = useState<number>(10);
    const { documents, loading, fetchDocuments, documentCount, nextPage, prevPage, page } = useFirestoreCollection('leads', LeadSchema);
    const [ selectedDocument, setSelectedDocument ] = useState<LeadModelType | null>(null)
    const [ selectedRows, setSelectedRows ] = useState<LeadModelType[]>([])

    useEffect(() => {
        fetchDocuments(pageSize, ["createdAt", "desc"]);
    },[, pageSize])

    const documentsToTable = () => {
        return documents.map((doc) => {
            return [
                <Group >
                    <Avatar radius="xl" />
                    <Stack gap={0}>
                        <Anchor maw={260} fw={"bold"} size={"sm"} onClick={() => {setSelectedDocument(doc)}}>{doc.firstName} {doc.lastName} {!(doc.firstName && doc.lastName) && "Unknown"}</Anchor>
                        <Text c={"dimmed"} size={"xs"} fw={"bold"}>{doc.sessions?.length} Session{doc.sessions?.length > 1?"s":""}</Text>
                    </Stack>
                </Group> as ReactNode,
                
                (new Date(doc.createdAt as number)).toDateString()
            ]
        })
    }

    return (
        <>
            <Drawer pos={"relative"} withCloseButton={false} opened={selectedDocument?true:false} position="right" size={"lg"} onClose={() => {setSelectedDocument(null)}}>
                {selectedDocument && 
                    <LeadPage leadId={selectedDocument.id} onClose={() => {setSelectedDocument(null)}}/>
                }
            </Drawer>
            <PageHeader title={"Leads"}>
                <DownloadLeadsAsCSVButton/>
            </PageHeader>
            <Container size={"100%"} className={pageStyles.main}>

                <Paper withBorder radius={"lg"} bg={"gray.1"} style={{overflow: "hidden"}}>
                    <Group p={"md"} gap={"xs"}>
                        <IconTable color="var(--mantine-color-brand-filled)" /><Text size={"sm"} c={"dimmed"}>This table show the list of the leads captured by Unichats.</Text>
                    </Group>
                    <Group className={styles.actionsList} justify={"space-between"} bg="white" p={"md"}> 
                        <TextInput
                            w={300}
                            leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                            variant="filled"
                            placeholder="Search by lead email"
                            disabled
                        /> 
                    <Group>
                        <Text size={"sm"}>Showing</Text> 
                        <Select
                            w={75}
                            value={pageSize.toString()}
                            checkIconPosition="right"
                            onChange={(val) => {setPageSize(parseInt(val || "0"))}}
                            variant="filled"
                            data={['10', '15', '25', '50']}
                            />
                        <Text size={"sm"}>of <strong>{!documentCount? " ":documentCount}</strong> results </Text> 
                    </Group>
                            
                </Group>

                    <DataTable
                    withTableBorder
                    borderRadius="0"
                    withColumnBorders
                    highlightOnHover
                    fetching={loading}
                    selectedRecords={selectedRows}
                    onSelectedRecordsChange={setSelectedRows}
                    borderColor={"var(--mantine-color-gray-2"}
                    styles={{
                        root: (theme) => ({
                            border: `1px solid ${theme.colors.gray[1]}`,
                        }),
                        header: (theme) => ({
                            backgroundColor: theme.colors.gray[0],
                            height: "40px",
                            fontWeight: "500",
                            textTransform: "uppercase",
                            fontSize: "small",
                            color: theme.colors.gray[7],
                            borderColor: theme.colors.gray[1]
                        })
                    }}
                    classNames={{
                        pagination: styles.pagination
                    }}
                    rowStyle={({}) => (
                        {
                            height: "50px"
                        }
                    )}
                    records={documents}
                    totalRecords={documentCount || 1}
                    recordsPerPage={pageSize}
                    page={page}
                    onPageChange={(p) => {
                        if(p > page)
                            nextPage()
                        else if (p < page) 
                            prevPage()
                    }}
                    idAccessor="id"
                    // define columns
                    columns={[
                        
                        { accessor: 'name',
                            width: 380,
                            render: (doc) => (
                                <Group >
                                    <Avatar radius="xl" />
                                    <Stack gap={0}>
                                        <Anchor maw={260} fw={"bold"} size={"sm"} onClick={() => {setSelectedDocument(doc)}}>{doc.firstName} {doc.lastName} {!(doc.firstName && doc.lastName) && "Unknown"}</Anchor>
                                        <Text c={"dimmed"} size={"xs"} fw={"bold"}>{doc.sessions?.length} Session{doc.sessions?.length > 1?"s":""}</Text>
                                    </Stack>
                                </Group>
                            )
                        },
                        {
                            accessor: 'contact info',
                            render: (doc) => (
                                <Stack gap={0}>
                                    <Text size={"sm"}>{doc.email}</Text>
                                    <Text size={"sm"}>{doc.phoneNumber}</Text>
                                </Stack>
                            )

                        },
                        {
                            accessor: 'created at',
                            render: ({createdAt}) => (
                                (new Date(createdAt as number)).toDateString()
                            )
                        }
                        ]}
                    />
                        

    
                </Paper>
            </Container>
        </>
    )
}