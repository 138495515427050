import styles from './actions.module.scss'
import { Handle, Position } from 'reactflow';
import { Stack, Group, Text, Title, Paper, Avatar, Pill } from '@mantine/core';
import { IconSelect } from "@tabler/icons-react"
import _ from 'lodash'

export type SimpleSelectActionType = {
    content: string,
    options: SimpleSelectValue[]
    field_name?: string
}

export type SimpleSelectValue = {
    label: string,
    value: string,
    id: string
}

const SimpleSelectValueDefault = {
    value: "option",
    label: "Option",
}
export const SimpleSelectActionSchema = [
    {
        target: "content",
        label: "Message",
        type: "rich_text",
    },
    {
        target: "options",
        label: "Options",
        type: "multiple",
        multiple_label: "Option",
        schema: [
            {
                target: "label",
                label: "Label",
                type: "text",
            },
            {
                target: "value",
                label: "Value",
                type: "text",
            }
        ],
        default: SimpleSelectValueDefault,
        add: "Add Option"
    },
    {
        target: "attribute",
        label: "Save response to: (optional)",
        type: "data_select",
        option: "attributes"
    }
]

export const SimpleSelectActionDefault = {
    content: "Select from the following options:",
    options: [
    ]
}

export function SimpleSelectActionNode({id, action, isConnectable, edges}) {
    return (
        <>
            <Handle
            type="target"
            id={`${id}-target`}
            position={Position.Left}
            style={{ top: 15, background: 'transparent', borderColor: "transparent" }}
            isConnectable={isConnectable}
            />
            <Stack >
                <Group bg={"gray.2"} px={"lg"} py={4} justify={"space-between"}>
                    <Group>
                    <Avatar color={"gray.9"} size={18}>
                    <IconSelect size={10} />
                    </Avatar>
                    <Text  fw={"bold"} c={"gray.9"}>Select</Text>
                    </Group>
                        <Group pos={"relative"} justify="flex-end" pr={"sm"}>
                        <Text size={"xs"} h={"auto"} bg={"transparent"} c={"gray.7"} >Default
                        
                        </Text>
                        <Handle
                            type="source"
                            position={Position.Right}
                            id={`${id}-source`}
                            className={`${_.findIndex(edges, {sourceHandle: `${id}-source`})> -1? "":styles.handleNoConnection}`}
                            style={{position:"absolute", marginRight: "-10px" , background: `var(--mantine-color-gray-6)`, width: "10px", height: "10px" }}
                            isConnectable={isConnectable}
                        > </Handle>
                </Group>
                </Group>
            </Stack>
            
            <Stack px={"lg"} mt={"lg"}>
                <Paper shadow={"xs"} p={"sm"} py={"xs"} bg={"gray.0"} withBorder>
                    <div dangerouslySetInnerHTML={{__html: action.content}}></div>
                </Paper>
               
            {action.options.map((val:{label:string, value:string, id:string}, index:number) => {
                return (
                    <Group key={`${id}-${val.id}`} pos={"relative"} justify="flex-end">
                        <Pill h={"auto"} bg={"red.1"} py={"xs"} px={"md"} key={val.id}>{val.label}</Pill>
                        <Handle
                            type="source"
                            position={Position.Right}
                            id={`${id}-${val.id}-source`}
                            
                            style={{position: "absolute", marginRight: "-10px" , background: `var(--mantine-color-blue-5)`, width: "10px", height: "10px" }}
                            isConnectable={isConnectable}
                        />
                    </Group>
                )
            })}
            </Stack>


        </>
    )

}

