import { Code, Container, Group, Text } from "@mantine/core";
import { ConsoleContext, ContextType } from "../context/ConsoleContext";
import { useContext, useEffect, useState } from "react";
import { MenuBarComponent } from "../components/MenuBar";


export function InstallationCodePage() {
    const { selected } = useContext(ConsoleContext) as ContextType;
    const [ installationCode, setInstallationCode ] = useState<string>("");
    const version = "0.72"

    useEffect(() => {
        setInstallationCode(`
            <script src="https://widget.unichats.ai/lib/unichatswidget.js?v${version}"></script>
            <script>
            uniWidget("${selected?.id}");
            </script>    
        `)
    }, [selected])

    return (
        <>
            <MenuBarComponent title={"Report"}>
                <Group></Group>
            </MenuBarComponent>
            <Container size={"xl"} p={"md"} px={"xl"}>
                <Text mb={"sm"}> Install the following code in your website at the bottom of the body tag. You can also install it via Tag Manager.</Text>
                <Code block>{installationCode}</Code>
            </Container>
        </>
    )

}