// schemas/formSchema.ts
import { z } from 'zod';

export const attributeSchema = z.object({
    id: z.string().optional(),
    label: z.string().min(3, 'Name must be at least 3 characters long'),
    locked: z.boolean().default(false),
    status: z.enum(['active', 'archived']).default('active'),
    target: z.enum(['lead', 'form']).default('lead'),
    type: z.enum(['text', 'number']).default('text')
});

// TypeScript type derived from the Zod schema
export type AttributeType = z.infer<typeof attributeSchema>;
